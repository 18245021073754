import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-internet-browers',
  templateUrl: './page-internet-browers.component.html',
  styleUrls: ['./page-internet-browers.component.scss']
})
export class PageInternetBrowersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
