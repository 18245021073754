import { Component, OnInit, TemplateRef } from '@angular/core';
import { Http, RequestOptions } from '@angular/http';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from "rxjs/operators";



@Component({
  selector: 'app-van-hire',
  templateUrl: './van-hire.component.html',
  styleUrls: ['./van-hire.component.scss']
})
export class VanHireComponent implements OnInit {

  public services = <any>'';
  public service_code = <any>'';
  public service_show = <any>'';
  public services_title = <any>'';
  public services_title_show = <any>'';
  public subcondition = <any>'';

  public select_language = <any>'';

  constructor(
    private http    : Http,
    private router  : Router,
    private route: ActivatedRoute,
  ) { 
        this.select_language = 'TH';
    }

  getServiecsTH(){
    let url = "./assets/json/other-services/vans/van_hire_th.json";

            return this.http.get(url)
                .pipe(map(res => res.json()))
                .toPromise()
                .then(
                  data => [
                      this.services = data,
                      this.subcondition = this.services[0].conditions[2].sub
                      // console.log(this.services[0].conditions[2].sub)
                  ],
                  err => [
                    console.log(err)
                  ]
                ).then(()=>{
                      this.setServiceShow();
                });
  }

  getServiecsEN(){
    let url = "./assets/json/other-services/vans/van_hire_en.json";

            return this.http.get(url)
                .pipe(map(res => res.json()))
                .toPromise()
                .then(
                  data => [
                      this.services = data,
                      this.subcondition = this.services[0].conditions[2].sub
                      // console.log(this.services.language)
                  ],
                  err => [
                    console.log(err)
                  ]
                ).then(()=>{
                      this.setServiceShow();
                });
  }

  getServiceTitle(){
    let url = "./assets/json/other-services/vans/van_hire_title.json";

            return this.http.get(url)
                .pipe(map(res => res.json()))
                .toPromise()
                .then(
                  data => [
                      this.services_title = data,
                      // console.log(this.services_title.language)
                  ],
                  err => [
                    console.log(err)
                  ]
                ).then(()=>{
                      for(let i = 0; i <= this.services_title.length-1; i++){
                            if(this.select_language ==  this.services_title[i].language){
                                  this.services_title_show = this.services_title[i];
                                  // console.log(this.services_title[i].language);
                            }
                      }
                });
  }

  setServiceShow(){
    //check & set Service
        for(let i = 0; i <= this.services.length-1; i++){
              if(this.service_code == this.services[i].service_code){
                   this.service_show = this.services[i] ;
              }
        }

        // console.log(this.service_show);
  }

  change_languge(){
    // console.log(this.select_language);
    if(this.select_language == 'TH'){
        this.getServiecsTH();
        this.getServiceTitle();
    }
    if(this.select_language == 'EN'){
        this.getServiecsEN();
        this.getServiceTitle();
    }
  }

  Servicebook(){
    sessionStorage.setItem("Service_code", this.service_code);
    this.router.navigate(['services/booking']);
  }

  ngOnInit(): void {
    this.service_code = this.route.snapshot.paramMap.get('id');
    this.getServiecsTH();
    this.getServiceTitle();
    // this.getServiecsEN();
  }

}
