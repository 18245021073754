<!-- <app-header></app-header> -->

<!-- ------- Cover Images Touring Center ---------- -->
<!-- <section [class.parallax_change]="parallax_variable" class="py-5 module parallax parallax-1 rellax-header" data-rellax-speed="2">
</section> -->
<header class="show-video">
    <video [muted]="true" autoplay playsinline loop>
    <!-- <source [src]="video_cover" type="video/mp4"> -->
    <source [src]="video_cover_" type="video/mp4">
    <!-- <source [src]="video_cover__" type="video/mp4"> -->
  </video>
    <!-- ----- Social Media ----- -->
    <div class="bottom-right">
        <div class="footer-icon">
            <a class="footer-icon-size facebook-icon" href="https://www.facebook.com/toursinchiangmai" target="_blank"></a> &nbsp;&nbsp;
            <a class="footer-icon-size youtube-icon" href="https://www.youtube.com/channel/UCqx6cadulg7se3n-991yDrA" target="_blank"></a> &nbsp;&nbsp;
            <!-- <a class="footer-icon-size twitter-icon" href="https://twitter.com/chiangmaisights" target="_blank"></a> &nbsp;&nbsp; -->
            <a class="footer-icon-size line-icon" href="https://line.me/ti/p/~touringcnx.com" target="_blank"></a> &nbsp;&nbsp;
            <a class="footer-icon-size whatsapp-icon" href="https://wa.me/66882585817" target="_blank"></a> &nbsp;&nbsp;
        </div>
    </div>
    <!-- ----- Book a Trip ----- -->
    <div class="left-fix">
        <a [routerLink]="['/tours']"><img src="./assets/images/home/png/book_a_trip.png" class="size-book-trip" alt="Booking"></a>
    </div>
</header>
<div class="show-img">
    <section [class.parallax_change]="parallax_variable" [class.browser_change]="browser_variable" class="py-5 module parallax parallax-1" data-rellax-speed="2">
        <div class="centered_" style="text-align: center;">
            <div class="blog-concept">
                <span class="join-p">Day Tour provider <br> with premium service</span>
            </div>
        </div>
    </section>
</div>
<!-- ------- Touring Center ---------- -->
<section>
    <div class="for-xl-size_">
        <div class="tc-mean">
            <div data-aos="fade-up" data-aos-duration="500" class="tc-logo-content">
                <img class="tc-logo" src="./assets/images/logo/TC-LOGO-2024.png">
            </div>
            <!-- <p data-aos="fade-up" data-aos-duration="800" class="text-justify font-1 des-title"><span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          Touring Center (since 2004) is one of Tour Operator in Chiang Mai providing a Day Tour and Package in Chiang Mai and the northern part area of Thailand (especially Chiang Rai and Mae Hong Son). As a local tour operator, we understand the variety of its context & content (life, culture, beats, rhythms and environment surrounded). Our tours are based on English Speaking Market as the main market (another language also available upon request such as French, German, Chinese, etc.)
        </span></p> -->
        </div>
        <br>
        <!-- ------- Covid Safety & Travel ---------- -->
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: center; font-size: 22px;">
                <h2>Premium Day Trip - Chiang Mai and Beyond</h2>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: center; margin-bottom: 0px;">
                <h3>EXPERIENCE a different way • EXPLORING a must-see and unique</h3>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: center;">
                <h3>UNDERSTANDING more than, just go and see • MEMORIES are always preserve</h3>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: center; margin-bottom: 0px; margin-top: 30px;">
                <img src="./assets/images/certificate/tripadvisor.png" alt="Tripadvisor - Touring Center" style="max-height: 100px; width: auto; margin: 5px;">
                <img src="./assets/images/certificate/traveller_choice.png" alt="Traveller's Choice - Touring Center" style="max-height: 100px; width: auto; margin: 5px;">
                <img src="./assets/images/certificate/amazing_thailand_sha.png" alt="Amazing Thailand SHA - Touring Center" style="max-height: 100px; width: auto; margin: 5px;">
                <img src="./assets/images/certificate/safe_travels.png" alt="Safe Travels - Touring Center" style="max-height: 100px; width: auto; margin: 5px;">
            </div>
            <!-- <div class="col-sm-12 col-md-6 col-lg-7 col-xl-7">
                <div class="title-covid" data-aos="fade-up" data-aos-duration="500">
                    <b><span class="covid-text">COVID 19 Preventive Measures</span></b>
                </div>
                <br>
                <div class="covid-list" data-aos="fade-up" data-aos-duration="800">
                    <ul>
                        <li>Disinfectant to be alcohol-based and follow WHO standards</li>
                        <li>Disinfection of car during the day, while clients are visiting or having a meal</li>
                        <li>Vehicle disinfection log to be kept by driver</li>
                        <li>Disinfectant hand sanitizer available on board for clients</li>
                        <li>Special focus on handles, safety belts, seating surfaces, head rests and windows</li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-5 col-xl-5">
                <div class="logo-cotent-covid" data-aos="fade-up" data-aos-duration="900">
                    <img class="van" src="./assets/images/home/png/commuter001-1-1.png">
                </div>
            </div> -->

        </div>
    </div>
</section>

<!-- ------- Tours Promotion ---------- -->
<!-- <section id="promotions">
  <div class="for-xl-size_ " style="text-align: center;">
      <div class="promo-content shadow pointer">
        <a href="https://touringcenter.com/promotions2020/#/promotion/getfree/inthanon-and-city" target="_blank">
          <img class="w-100 promo-content-img" src="./assets/images/home/BUT1GET1.jpg">
         </a>
      </div>
  </div>
</section>
<br> -->

<!-- ------- Promotions ---------- -->
<!-- <section class="content-promotions">
    <div class="for-xl-size">
        <div class="title-content">
            <span class="title-text" style="color:white">Promotions</span><br><br><br><br>
            <div class="row">
                <div class="col-md-6 col-lg-6 mb-5 mb-md-0">
                    <img class="img-fluid" src="../../assets/images/promotions/jpg/Promotion-1-2-3.png">
                </div>
                <div class="col-md-6 col-lg-5 ml-auto">
                    <div class="title-promotion"> -->
<!-- P1 -->
<!-- <a style="text-decoration: none; color:white" href="https://touringcenter.com/promotions2020/#/promotion/getfree/inthanon-and-city">
                            <div class="border-p" data-aos="fade-down" data-aos-duration="500">
                                <p class="title-p">Buy 1 Get 1 Free</p>
                                <p class="des-p">From THB 2,100</p>
                                <a class="btn-p" href="https://touringcenter.com/promotions2020/#/promotion/getfree/inthanon-and-city">read more</a><br>
                            </div>
                        </a>
                        <br> -->
<!-- P2 -->
<!-- <a style="text-decoration: none; color:white" href="https://touringcenter.com/promotions2020/#/promotion/freetransfer/buy3-getfree-transferairport">
                            <div class="border-p" data-aos="fade-down" data-aos-duration="1000">
                                <p class="title-p">Buy 3 Get Free Airport Transfer </p>
                                <p class="des-p">From THB 5,200</p>
                                <a class="btn-p" href="https://touringcenter.com/promotions2020/#/promotion/freetransfer/buy3-getfree-transferairport">read more</a><br>
                            </div>
                        </a>
                        <br> -->
<!-- P3 -->
<!-- <a style="text-decoration: none; color:white" href="/vanhire/TC-VH01">
                            <div class="border-p" data-aos="fade-down" data-aos-duration="1500">
                                <p class="title-p">Van Hire Include Gasoline in Chiang Mai</p>
                                <p class="des-p">From THB 2,000</p>
                                <a class="btn-p" href="/vanhire/TC-VH01">read more</a><br>
                            </div>
                        </a> -->
<!-- </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- ------- Tours Reccommend ---------- -->
<section>
    <div class="for-xl-size tours-blog-content bg-light_">
        <div class="title-content">
            <span class="title-text">Recommend Tours</span><br><br><br><br>
        </div>
        <div class="consolg-btn">
            <div class="R-btn">
                <div class="R-btn-left">
                    <i data-aos="zoom-in" data-aos-duration="1000" class="fa fa-chevron-left left-btn pointer" aria-hidden="true"></i>
                </div>
                <div class="R-btn-right">
                    <i data-aos="zoom-in" data-aos-duration="1000" class="fa fa-chevron-right right-btn pointer" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <owl-carousel-o [options]="tourROptions">
            <ng-template carouselSlide *ngFor="let tours of Rec_Tours">
                <div data-aos="fade-down" data-aos-duration="500" class="card color-3 mr-2 pointer" (click)="onClickTours(tours.tour)">
                    <div *ngIf="tours.image_cover !== null">
                        <img class="card-img-top img-tours-view" [src]="tours.image_cover" alt="Card image cap">
                    </div>
                    <div *ngIf="tours.image_cover == null">
                        <img class="card-img-top img-tours-view" src="http://api.tourinchiangmai.com/images/cover-size.jpg" alt="Card image cap">
                    </div>
                    <div class="card-body">
                        <a target="_blank">
                            <p class="card-text text-tittle-tour tours-name">{{tours.title}}<span class="tours-code"> [{{tours.tour}}]</span></p>
                            <p class="tours-concept">{{tours.concept}}</p>
                            <hr style="visibility: hidden;">
                            <div class="blog-price">
                                <div class="review">
                                    <span class="card-text-review color-2">From</span><br>
                                    <span class="card-text-review hidd__ color-2">From</span>
                                </div>
                                <div class="price">
                                    <!-- <span class="card-text-more color-2"><span class="color-1 text-price">{{tours.price | number}}</span> THB</span><br> -->
                                    <ng-container *ngIf="tours.price">
                                        <span class="card-text-more color-2"><span class="color-1 text-price">{{tours.price | number}}</span> THB</span><br>
                                    </ng-container>
                                    <ng-container *ngIf="!tours.price">
                                        <span class="card-text-more color-2"><span class="color-1 text-price">{{tours.price_private | number}}</span> THB</span><br>
                                    </ng-container>
                                    <span class="per-person color-2"> per person</span>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </ng-template>

        </owl-carousel-o>
    </div>
</section>

<!-- ------- Tours Experience ---------- -->
<section class="list-tours-phone">
    <div class="for-xl-size tours-blog-content">
        <div class="title-content">
            <span class="title-text">Experience Tours</span><br><br><br><br>
        </div>
        <div class="consolg-btn">
            <div class="E-btn">
                <div class="E-btn-left">
                    <i data-aos="zoom-in" data-aos-duration="1000" class="fa fa-chevron-left left-btn pointer" aria-hidden="true"></i>
                </div>
                <div class="E-btn-right">
                    <i data-aos="zoom-in" data-aos-duration="1000" class="fa fa-chevron-right right-btn pointer" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <owl-carousel-o [options]="tourEOptions">

            <ng-template carouselSlide *ngFor="let tours of Ex_Tours">
                <div data-aos="fade-down" data-aos-duration="500" class="card color-3 mr-2 pointer" (click)="onClickTours(tours.tour)">
                    <div *ngIf="tours.image_cover !== null">
                        <img class="card-img-top img-tours-view" [src]="tours.image_cover" alt="Card image cap">
                    </div>
                    <div *ngIf="tours.image_cover == null">
                        <img class="card-img-top img-tours-view" src="http://api.tourinchiangmai.com/images/cover-size.jpg" alt="Card image cap">
                    </div>
                    <div class="card-body">
                        <p class="card-text text-tittle-tour tours-name">{{tours.title}}<span class="tours-code"> [{{tours.tour}}]</span></p>
                        <p class="tours-concept">{{tours.concept}}</p>
                        <hr style="visibility: hidden;">
                        <div class="blog-price">
                            <div class="review">
                                <span class="card-text-review color-2">From</span><br>
                                <span class="card-text-review hidd__ color-2">From</span>
                            </div>
                            <div class="price">
                                <ng-container *ngIf="tours.price">
                                    <span class="card-text-more color-2"><span class="color-1 text-price">{{tours.price | number}}</span> THB</span><br>
                                </ng-container>
                                <ng-container *ngIf="!tours.price">
                                    <span class="card-text-more color-2"><span class="color-1 text-price">{{tours.price_private | number}}</span> THB</span><br>
                                </ng-container>
                                <!-- <span class="card-text-more color-2"><span class="color-1 text-price">{{tours.price | number}}</span> THB</span><br> -->
                                <span class="per-person color-2"> per person</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>

        </owl-carousel-o>

    </div>
</section>

<!-- ------- Activities ---------- -->
<section class="bg-light_">
    <div class="for-xl-size">
        <div class="title-content">
            <span class="title-text">Activities</span><br><br><br><br>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="1" class="activity-content image-box">
                        <div class="thumb">
                            <a [routerLink]="['/love-out-loud']">
                                <img class="img-act" src="/assets/images/home/jpg/CSRS02-photo-ceontent-CSR.jpg">
                            </a>
                        </div>
                        <div class="info">
                            <div class="activity-type">
                                <span>CSR</span>
                            </div>
                            <div class="act-title">
                                <a [routerLink]="['/love-out-loud']"><span>Love out loud</span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="500" data-aos-offset="2" class="activity-content image-box">
                        <div class="thumb">
                            <a [routerLink]="['/breath-of-nature']">
                                <img class="img-act" src="/assets/images/home/jpg/DSCF1118.jpg">
                            </a>
                        </div>
                        <div class="info">
                            <div class="activity-type">
                                <span>FAM TRIP</span>
                            </div>
                            <div class="act-title">
                                <a [routerLink]="['/breath-of-nature']"><span>Breath of Nature</span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="700" data-aos-offset="3" class="activity-content">
                        <div class="thumb image-box">
                            <a href="https://www.youtube.com/watch?v=TNA7zQOL6b8" target="_blank">
                                <img class="img-act" src="/assets/images/activities/YT-channel.jpg">
                            </a>
                        </div>
                        <div class="info">
                            <div class="activity-type">
                                <span>Youtube</span>
                            </div>
                            <div class="act-title">
                                <a href="https://www.youtube.com/watch?v=TNA7zQOL6b8" target="_blank"><span>Chiang Mai & Beyond</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- ------- Partner ---------- -->
<section>
    <div class="for-xl-size">
        <div class="title-content">
            <span class="title-text">Partners</span><br><br><br><br>
        </div>
        <ul class="ul-tag">
            <owl-carousel-o [options]="partnerOptions" style="width: 0;">
                <ng-template carouselSlide *ngFor="let partner of partner_logo">
                    <a href="{{partner.link}}" target="_blank"><img [src]="partner.img"></a>
                </ng-template>
            </owl-carousel-o>
        </ul>
    </div>
</section>

<button (click)="toggleDialog(template)" class="btn-model-p">click</button>

<!-- ------- Our Partner (Images)---------- -->

<!-- ------- Subscribe ---------- -->
<!-- <section class="py-5 module parallax parallax-3 rellax-subscribe">
     <div class="container">
      <div class="card bg-card-sub">
        <div class="card-body">
          <span class="card-text text-tittle-sub"><b>PUT YOUR EMAIL TO <span style="color:red">SUBSCRIBE</span></b></span>
      <div class="row inside-box-sub">
        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <mat-form-field appearance="outline" class="example-full-width" style="width: 100%;">
            <mat-label>First Name</mat-label>
            <input matInput [(ngModel)]="information.firstname" placeholder="First Name">
            <span class="message-subscribe">{{message_subscribe_firstname}}</span>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <mat-form-field appearance="outline" class="example-full-width" style="width: 100%;">
            <mat-label>Last Name</mat-label>
            <input matInput [(ngModel)]="information.lastname" placeholder="Last Name">
            <span class="message-subscribe">{{message_subscribe_lastname}}</span>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <mat-form-field appearance="outline" class="example-full-width" style="width: 100%;">
            <mat-label>Your Email</mat-label>
            <input matInput [(ngModel)]="information.email" placeholder="Your Email">
            <span class="message-subscribe">{{message_subscribe_email}}</span>
          </mat-form-field>
        </div>
      </div>
            <div class="box-sub">
                <a mat-raised-button class="btn-sub"(click)="sendData()">subscribe</a>
            </div>
        </div>
      </div>
    </div>
</section> -->
<!-- ------- Footter ---------- -->
<!-- <app-footer></app-footer> -->

<!--
<script>
  $(document).ready(function(){
  $('.left').on('click',function(){
console.log('test');
  });
});
</script> -->


<!-- -------------- Model Ngx ------------------ -->
<!-- ---------- Term & Condition ------------------ -->
<ng-template #template>
    <div class="modal-header tab-term-bg">
        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <a href="https://touringcenter.com/promotions2020/#/promotion/getfree/inthanon-and-city">
                        <img class="d-block w-100" src="../../assets/images/promotions/jpg/inthanon_free_city_New.jpg" alt="promotion buy 1 get 1">
                    </a>
                </div>
                <div class="carousel-item">
                    <a href="https://touringcenter.com/promotions2020/#/promotion/freetransfer/buy3-getfree-transferairport">
                        <img class="d-block w-100" src="../../assets/images/promotions/jpg/3-free-transer.jpg" alt="promotion buy 3 free airport transfer">
                    </a>
                </div>
                <div class="carousel-item">
                    <a href="/vanhire/TC-VH01">
                        <img class="d-block w-100" src="../../assets/images/promotions/jpg/vanhire-vh-01.jpg" alt="promotion vans hire">
                    </a>
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>
</ng-template>

<!-- Modal News -->
<ng-template #templateNews>
    <ng-container *ngIf="news && news.length > 0">
        <img
            class="img-modal"
            [src]="news[0]?.image_url"
            [alt]="news[0]?.image_alt"
            (click)="toggleModal($event, news[0]?.link)"
        >
    </ng-container>
</ng-template>

<div id="modal-center" class="uk-flex-top" uk-modal (click)="closeModal('modal-center')">
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <ng-container *ngIf="news && news.length > 0">
            <img
                class="img-modal uk-modal-close-default"
                [src]="news[0]?.image_url"
                [alt]="news[0]?.image_alt"
                (click)="toggleModal($event, news[0]?.link)"
            >
        </ng-container>
    </div>
</div>