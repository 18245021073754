<!-- <app-header-non-fixed></app-header-non-fixed> -->

<!-- ---------- image main Receipt / Receipt Page ----------- -->
<!-- <div class="bg-payment-page">
  <div class="centered_">
    <span class="tittle-page" >Receipt</span>
   </div>
</div> -->

<div class="container body-navbar">
  <span class="font-3r color-red-pastel"><b>RECEIPT</b></span>
  <hr class="hr-1"><br>

  <div class="checkpdf" *ngIf="is_show_pdf">
    <iframe *ngIf="pdfSrc" [src]="pdfSrc" [autoresize]="true" style="display: block;"></iframe>
  </div>

  <div class="checkm-pdf" *ngIf="is_show_pdf">
    <iframe *ngIf="pdfSrc" [src]="pdfSrc" [autoresize]="true" style="display: block;"></iframe>
  </div>
</div>

<!-- [original-size]="false" -->
<!-- <div class="checkpdf">
  <pdf-viewer [src]="pdfPath" [show-borders]="true" [autoresize]="true" style="display: block;"></pdf-viewer>
 </div> -->
  <!-- ---- for Phone ---- -->
<!-- <div class="checkm-pdf">
  <pdf-viewer src="{{ pdfPath }}" [show-borders]="true" [original-size]="false" [autoresize]="true" style="display: block;"></pdf-viewer>
</div> -->

<section>
  <div class="btn-content">
    <a mat-stroked-button color="primary" (click)="onClick('HOME')" class="border-1 hover-btn-home">Home</a>
     &nbsp;&nbsp;
    <!-- <a mat-stroked-button color="warn" (click)="onClick('UPDATE')" class="border-1 hover-btn-update">Update your information</a> -->
    <button mat-raised-button color="warn" (click)="onClick('UPDATE')" class="hover-btn-update">Update your information</button>
     <!-- &nbsp;&nbsp; -->
    <!-- <a mat-stroked-button color="primary" (click)="PDFDownload()" class="border-1 hover-btn-download">Download PDF</a> -->
    <!-- <a mat-stroked-button color="primary" [href]="download[pdfPath]" [download]="pdfPath" target="_blank" class="border-1 hover-btn-download" style="text-decoration: none;">Download PDF</a> -->
  </div>
</section>
<!-- ------- Footter ---------- -->
<!-- <app-footer></app-footer> -->
