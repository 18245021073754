<!-- <app-header-non-fixed></app-header-non-fixed> -->

<section class="body-navbar blog-partners">
    <div class="center_">
        <span class="font-3r color-red-pastel"><b>OUR PARTNERS</b></span>
    </div>
    <hr class="hr-1">
    <div class="blog-font pointer active">
        <ul class="ul-tag">
            <li (click)="searchList(font)" *ngFor="let font of font">
                <a>{{font | uppercase}}</a>
            </li>
        </ul>
    </div>
    <br><br>

    <!-- ------ list hotel -------- -->
    <div class="row flex-column-reverse flex-md-row">
        <!-- -------- hotel partner ---------- -->
        <div class="col-sm-12 col-md-12 col-lg-6 col-md-6 ">
            <div *ngFor="let partner of partners_show ; let i = index;">
                <ul style="list-style-type: none;">
                    <p class="title-character">{{partner.character}}</p>
                    <li class="li-list">
                        <p><i class="fa fa-bed" aria-hidden="true">&nbsp;&nbsp;</i>{{partner.hotel}}</p>
                    </li>
                </ul>
            </div>
        </div>
        <!-- -------- super hotel partner ---------- -->
        <div class="col-sm-12 col-md-12 col-lg-6 col-md-6">
            <div class="list-super-partner">
                <a href="https://www.aksaraheritage.com/" target="_blank"><img class="logo-partner" src="../../assets/images/partner/aksara-heritage.jpg"></a>
                <a href="https://www.amatalanna.com/" target="_blank"><img class="logo-partner" src="../../assets/images/partner/amata-lanna.jpg"></a>
                <a href="https://www.artmaigalleryhotel.com/" target="_blank"><img class="logo-partner" src="../../assets/images/partner/art-mai-black.jpg"></a>
                <a href="https://www.baanbooloovillage.com/" target="_blank"><img class="logo-partner" src="../../assets/images/partner/chu-hotel.jpg"></a>
                <a href="https://theechohotel.com/" target="_blank"><img class="logo-partner" src="../../assets/images/partner/echo-hotel.jpg"></a>
                <a href="http://www.hydeparkcm.com/" target="_blank"><img class="logo-partner" src="../../assets/images/partner/hyde-park.jpg"></a>
                <a href="https://www.kmaisonboutique.com/" target="_blank"><img class="logo-partner" src="../../assets/images/partner/k-maison-lanna.jpg"></a>
                <a href="http://www.kateandhasu.com/" target="_blank"><img class="logo-partner" src="../../assets/images/partner/kate-&-hasu.jpg"></a>
                <a href="https://marayaresort.com/" target="_blank"><img class="logo-partner" src="../../assets/images/partner/maraya-hotel.jpg"></a>
                <a href="https://mhonsahotel.com/site/" target="_blank"><img class="logo-partner" src="../../assets/images/partner/mhonsa-hotel.jpg"></a>
                <a href="https://pingdoiboutiquehotel.com/" target="_blank"><img class="logo-partner" src="../../assets/images/partner/pingdoi-hua-lin.jpg"></a>
                <a href="https://www.ruencomein.net/" target="_blank"><img class="logo-partner" src="../../assets/images/partner/ruencome-in.jpg"></a>
                <a href="http://www.singhamontra.com/" target="_blank"><img class="logo-partner" src="../../assets/images/partner/singha-montra.jpg"></a>
                <a href="https://viangluangresort.com/index.php?lang=en" target="_blank"><img class="logo-partner" src="../../assets/images/partner/viangluang.jpg"></a>
                <a href="http://www.yaangcome.com/" target="_blank"><img class="logo-partner" src="../../assets/images/partner/yaang-come-village.jpg"></a>
                <a href="http://www.ratilannachiangmai.com/" target="_blank"><img class="logo-partner" src="../../assets/images/partner/ratilanna.jpg"></a>
            </div>
        </div>
    </div>

</section>

<!-- ------- Footter ---------- -->
<!-- <app-footer></app-footer> -->