import { Component, OnInit, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { DatePipe } from "@angular/common";
import { ApiServiceService } from "../../../services/api-service.service";

@Component({
  selector: "app-booking",
  templateUrl: "./booking.component.html",
  styleUrls: ["./booking.component.scss"],
})
export class BookingComponent implements OnInit {
  public getaccountcode = <any>"";
  modalRef: BsModalRef;
  public checked: boolean = true;
  bsInlineValue = new Date();
  public bsInlineValue_ = <any>"";
  public savebooking = <any>"";
  public getTourPrice_ = <any>"";
  public reload: boolean = false;
  public pickup_time: Date = new Date();
  public labelPosition = <any>"";
  public minTime: Date = new Date();
  public maxTime: Date = new Date();
  public bsInlineMinDate: Date = new Date();
  public bsInlineMaxDate: Date = new Date("2020-10-31");

  public minvans = <any>"";
  public maxvans = <any>"";
  public minguests = <any>"";
  public maxguests = <any>"";
  public minguides = <any>"";
  public maxguides = <any>"";
  public vans = <any>"";
  public guest = <any>"";
  public vans_price = <any>"";
  public guides_price = <any>"";

  public adult_price: number = <any>"";
  public child_price: number = <any>"";

  checkJoin: boolean = true;
  checkPrivate: boolean = true;

  check_firstname: boolean = false;
  check_lastname: boolean = false;
  check_email: boolean = false;
  check_phone: boolean = false;
  check_hotel: boolean = false;
  check_time: boolean = false;
  check_privacy: boolean = false;

  aff_id = <any>"";

  public information = {
    adultpax: 0,
    childpax: 0,
    infantpax: 0,
    total_price: <any>"0.00",
    privacy: <any>"",
    tourtime: <any>"",
    date_traveling: <any>"",
    firstname: <any>"",
    lastname: <any>"",
    email: <any>"",
    phone: <any>"",
    hotel: <any>"",
    hotel_other: <any>"",
    vans: <any>"",
    guests: <any>"",
    guides: <any>"",
  };

  maxAdult = <any>"";
  maxChild = <any>"";
  minAdult = <any>"";
  minChild = <any>"";

  constructor(
    private router: Router,
    private modalService: BsModalService,
    public datepipe: DatePipe,
    private apiService: ApiServiceService
  ) {
    this.maxAdult = 20;
    this.maxChild = 7;
    this.minAdult = 2;
    this.minChild = 0;

    this.minguests = 0;
    this.maxguests = 9;
    this.minvans = 1;
    this.maxvans = 5;

    this.labelPosition = 0;
    this.minTime.setHours(6);
    this.minTime.setMinutes(0);
    this.maxTime.setHours(10);
    this.maxTime.setMinutes(0);
    this.pickup_time.setHours(6);
    this.pickup_time.setMinutes(0);

    this.vans = 1;
    this.information.vans = 1;
    this.information.guests = 9;
    this.information.guides = 0;

    this.maxguides = this.information.vans;
    this.minguides = 1;

    this.bsInlineMinDate.setDate(this.bsInlineMinDate.getDate() + 3);
  }

  // <<< Initial >>>
  ngOnInit(): void {
    this.setDateTravelDefault();
    this.callGetTourPrice();
    this.callJsonGetAccountHotelCode();
    this.getAffId();
  }

  // <<< -----------------------------------------
  // ------------------- Call API ----------------
  // ----------------------------------------- >>>
  callGetTourPrice(): void {
    const url = "Website/Booking/GetTourPriceRate";
    let tourcode = sessionStorage.getItem("Service_code");
    let setPayload = {
      TourCode: tourcode,
    };

    this.apiService.callPost(url, setPayload).subscribe(
      (result) => [(this.getTourPrice_ = result), this.checkprivacy()],
      (err) => [console.log(err)]
    );
  }
  // save booking
  callSaveBooking(payload: any): void {
    const url = "Website/Booking/SaveBooking";
    let setPayload = payload ? payload : {};

    this.apiService
      .callPost(url, setPayload)
      .toPromise()
      .then(
        (result) => [(this.savebooking = result)],
        (err) => [console.log(err)]
      )
      .then(() => {
        this.checkedSavebooking();
      });
  }

  // <<< -----------------------------------------
  // ------------------- Call JSON ---------------
  // ----------------------------------------- >>>
  // get account code
  callJsonGetAccountHotelCode(): void {
    const url = "account_code/account_Hotel.json";
    this.apiService.callGetJSON(url).subscribe(
      (result) => [(this.getaccountcode = result)],
      (err) => [console.log(err)]
    );
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({
        class: "gray modal-lg modal-dialog-centered modal-open",
        centered: true,
      })
    );
  }

  // <<< -----------------------------------------
  // ------------------ Validation ---------------
  // ----------------------------------------- >>>
  // check tern and condition
  checkTerm() {
    if (this.checked == false) {
      this.checked = true;
    } else if (this.checked == true) {
      this.checked = false;
    }
  }
  // check guide
  checkGuides(value: number) {
    if (value == 1) {
      if (this.information.guides == 0) {
        this.information.guides = 1;
      }
      if (this.information.vans > 3 && this.information.guides == 1) {
        this.information.guides = 2;
        this.minguides = 2;
        this.information.total_price =
          this.information.vans * this.vans_price +
          this.information.guides * this.guides_price;
      } else {
        this.information.total_price =
          this.information.vans * this.vans_price +
          this.information.guides * this.guides_price;
      }
    }
    if (value == 0) {
      this.information.guides = 0;
      this.information.total_price = this.information.vans * this.vans_price;
    }
  }
  // check privacy
  checkprivacy() {
    if (
      this.getTourPrice_.privacy.join.adult == "0.00" &&
      this.getTourPrice_.privacy.join.child == "0.00"
    ) {
      this.checkJoin = false;
    }
    this.information.total_price = this.getTourPrice_.privacy.join.adult;
    this.vans_price = this.getTourPrice_.privacy.join.adult;
    this.guides_price = this.getTourPrice_.privacy.join.child;
  }
  // check vans
  change_vans(value: number): void {
    this.information.vans = value;

    if (this.information.vans == 1) {
      this.maxguests = 9;
      this.information.guests = 9;
      this.information.total_price =
        this.information.vans * this.vans_price +
        this.information.guides * this.guides_price;
    }
    if (this.information.vans == 2) {
      this.maxguests = 18;
      this.information.guests = 18;
      this.information.total_price =
        this.information.vans * this.vans_price +
        this.information.guides * this.guides_price;
    }
    if (this.information.vans == 3) {
      this.maxguests = 27;
      this.information.guests = 27;
      this.information.total_price =
        this.information.vans * this.vans_price +
        this.information.guides * this.guides_price;
    }
    if (this.information.vans == 4) {
      this.maxguests = 39;
      this.information.guests = 39;
      this.information.total_price =
        this.information.vans * this.vans_price +
        this.information.guides * this.guides_price;
    }
    if (this.information.vans == 5) {
      this.maxguests = 45;
      this.information.guests = 45;
      this.information.total_price =
        this.information.vans * this.vans_price +
        this.information.guides * this.guides_price;
    }
    if (this.information.vans > 3 && this.information.guides == 1) {
      this.information.guides = 2;
      this.minguides = 2;
      this.information.total_price =
        this.information.vans * this.vans_price +
        this.information.guides * this.guides_price;
    }
    if (this.information.vans <= 3) {
      this.minguides = 1;
    }
    if (this.information.vans == 1 && this.information.guides > 1) {
      this.information.guides = 1;
      this.minguides = 1;
      this.information.total_price =
        this.information.vans * this.vans_price +
        this.information.guides * this.guides_price;
    }
    this.maxguides = this.information.vans;
  }
  // check field
  checkValue() {
    if (
      this.information.firstname !== "" &&
      this.information.email !== "" &&
      this.information.lastname !== "" &&
      this.information.phone !== "" &&
      this.information.hotel !== ""
    ) {
      this.saveData();
    }
    if (
      this.information.firstname == "" ||
      this.information.firstname == null ||
      this.information.firstname == undefined
    ) {
      this.check_firstname = true;
    } else {
      this.check_firstname = false;
    }
    if (
      this.information.lastname == "" ||
      this.information.lastname == null ||
      this.information.lastname == undefined
    ) {
      this.check_lastname = true;
    } else {
      this.check_lastname = false;
    }
    if (
      this.information.email == "" ||
      this.information.email == null ||
      this.information.email == undefined
    ) {
      this.check_email = true;
    } else {
      this.check_email = false;
    }
    if (
      this.information.phone == "" ||
      this.information.phone == null ||
      this.information.phone == undefined
    ) {
      this.check_phone = true;
    } else {
      this.check_phone = false;
    }
    if (
      this.information.hotel == "" ||
      this.information.hotel == null ||
      this.information.hotel == undefined
    ) {
      this.check_hotel = true;
    } else {
      this.check_hotel = false;
    }
  }
  // check save booking
  checkedSavebooking() {
    if (this.savebooking.status == "true") {
      this.reload = true; //icon reload
      (async () => {
        this.reload = true; //icon reload
        await this.delay(3000);
        //go to Payment
        this.router.navigate(["payment/" + this.savebooking.Transaction]);
      })();
    } else {
      //Send Data again
      // this.saveData();
    }
  }

  // <<< -----------------------------------------
  // ------------------- On event ----------------
  // ----------------------------------------- >>>
  // change guest
  change_guests(value: number): void {}
  // save data
  saveData() {
    // set for this service booking (VAN)
    this.information.privacy = "private";
    this.information.adultpax = this.information.guests;
    this.information.tourtime = this.getTourPrice_.tourtime[0].travel_time_start;

    let setData = {
      bookingInfo: {
        tour_id: this.getTourPrice_.tour_id,
        tour_code: this.getTourPrice_.tour_code,
        tour_title: this.getTourPrice_.tour_title,
        privacy: this.information.privacy,
        tour_time: this.information.tourtime,
        date_travel: this.bsInlineValue.toDateString(),
        pax:
          this.information.adultpax +
          this.information.childpax +
          this.information.infantpax,
        adult_pax: this.information.adultpax,
        child_pax: this.information.childpax,
        infant_pax: this.information.infantpax,
        adult_price: this.adult_price,
        child_price: this.child_price,
        total_adult_price: this.information.adultpax * this.adult_price,
        total_child_price: this.information.childpax * this.child_price,
        total_price: this.information.total_price,
        commission: 0,
        discount: 0,
        discount_price: 0,
        servicecharge: 0,
        otherservice: 1,
        insurance: null,
        payment_mode: "Selling price",
        payment_collect: "Guest",
        note_by: "Website",
        issued_by: "Website",
        isServicecharge: false,
        isInsurance: true,
        isCommission: false,
      },
      guestInfo: {
        fullname: this.information.firstname + " " + this.information.lastname,
        firstname: this.information.firstname,
        lastname: this.information.lastname,
        email: this.information.email,
        phone: this.information.phone,
        hotel: this.information.hotel,
        hotel_other: this.information.hotel_other,
      },
      affiliate: {
        aff_id: this.aff_id.aff_id,
      },
      otherService: {
        vans: this.information.vans,
        guides: this.information.guides,
        van_price: this.vans_price,
        guide_price: this.guides_price,
        total_vans_price: this.information.vans * this.vans_price,
        total_guides_price: this.information.guides * this.guides_price,
        pickup_time: this.pickup_time,
        // .toDateString(),
        //.toTimeString()
      },
    };

    console.log("Paylaod save data : ", setData);
    this.callSaveBooking(setData);
  }

  // <<< -----------------------------------------
  // -------------------- Share ------------------
  // ----------------------------------------- >>>
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  // <<< -----------------------------------------
  // ------------------ Affiliate ----------------
  // ----------------------------------------- >>>
  getAffId() {
    this.aff_id = JSON.parse(localStorage.getItem("aff_id"));
  }

  // <<< -----------------------------------------
  // ------------------- Set Data ----------------
  // ----------------------------------------- >>>
  setDateTravelDefault() {
    // this. bsInlineValue = new Date();
    this.bsInlineValue = new Date(
      this.bsInlineValue.getFullYear(),
      this.bsInlineValue.getMonth(),
      this.bsInlineValue.getDate() + 3
    );
    // this.bsInlineValue = formatDate(new Date(), 'dd/MM/yyyy', 'en');
    // this.bsInlineValue = new Date(this.bsInlineValue);
  }
}
