<!-- <app-header-non-fixed></app-header-non-fixed> -->

<!-- ---------- image main booking / payment ----------- -->
<!-- <div class="bg-payment-page">
  <div class="centered_">
    <span class="tittle-page" >PAYMENT</span>
   </div>
</div> -->
<!-- ----------- Payment Form ----------- -->
  <div class="container body-navbar">
    <span class="font-3r color-red-pastel"><b>PAYMENT</b></span>
    <hr class="hr-1"><br>
    <div class="row">
      <div class="col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <div class="review-order">
          <div class="shadow p-3 mb-5 bg-white rounded">
              <div class="review-order-form">
                <span class="title-review-order">REVIEW ORDER</span>
                  <hr>
                  <div class="review-tour">
                    <table>
                      <tr>
                        <td class="td-1">Tour code </td>
                        <td class="td-2"> : </td>
                        <td class="color-2"> {{dataTransaction.tour_code}}</td>
                      </tr>
                      <tr>
                        <td class="td-1">Tour name </td>
                        <td class="td-2"> : </td>
                        <td class="color-2"> {{dataTransaction.tour_title}}</td>
                      </tr>
                      <tr>
                        <td class="td-1">Travel date </td>
                        <td class="td-2"> : </td>
                        <td class="color-2" *ngIf="dataTransaction.tour_travel_time && dataTransaction.tour_travel_time !== '-'">
                          {{ dataTransaction.tour_travel_time }} @ {{dataTransaction.tour_travel_date}}
                        </td>
                        <td class="color-2" *ngIf="!dataTransaction.tour_travel_time || dataTransaction.tour_travel_time === '-'">
                          {{dataTransaction.tour_travel_date}}
                        </td>
                      </tr>
                      <tr>
                        <td class="td-1">Privacy </td>
                        <td class="td-2"> : </td>
                        <td class="color-2"> {{dataTransaction.privacy}}</td>
                      </tr>
                      <tr *ngIf="dataTransaction.other_service == 1">
                        <td class="td-1">Pickup time </td>
                        <td class="td-1"> : </td>
                        <td class="color-2"> {{dataTransaction.pickup_time}}</td>
                      </tr>
                    </table>
                  </div>
                  <hr>
                  <div class="review-guest">
                    <table>
                      <tr>
                        <td class="td-1">Name </td>
                        <td class="td-2"> : </td>
                        <td class="color-2"> {{dataTransaction.fullname}}</td>
                      </tr>
                      <tr>
                        <td class="td-1">Email </td>
                        <td class="td-2"> : </td>
                        <td class="color-2"> {{dataTransaction.email}}</td>
                      </tr>
                      <tr>
                        <td class="td-1">Phone </td>
                        <td class="td-2"> : </td>
                        <td class="color-2"> {{dataTransaction.phone}}</td>
                      </tr>
                      <tr>
                        <td class="td-1">Hotel </td>
                        <td class="td-2"> : </td>
                        <td class="color-2"> {{dataTransaction.hotel}}</td>
                      </tr>
                    </table>
                  </div>
                  <hr>
                  <div *ngIf="dataTransaction.other_service == '1'" class="review-price">
                    <table style="width:100%">
                      <tr>
                        <th style="width:50%">Services</th>
                        <th><span class="float-light">QTY</span></th>
                        <th><span class="float-light">Price(THB)</span></th>
                        <th><span class="float-light">Amount(THB)</span></th>
                      </tr>
                      <tr>
                        <td>Vans (9 seats)</td>
                        <td><span class="float-light">{{dataTransaction.vans}}</span></td>
                        <td><span class="float-light">{{dataTransaction.van_price | number:'1.2-2'}}</span></td>
                        <td><span class="float-light">{{dataTransaction.total_vans_price | number:'1.2-2'}}</span></td>
                      </tr>
                      <tr>
                        <td>Guides</td>
                        <td><span class="float-light">{{dataTransaction.guides}}</span></td>
                        <td><span class="float-light">{{dataTransaction.guide_price | number:'1.2-2'}}</span></td>
                        <td><span class="float-light">{{dataTransaction.total_guides_price | number:'1.2-2'}}</span></td>
                      </tr>
                    </table>
                    <hr>
                  </div>
                  <div class="review-price">
                    <table style="width:100%">
                      <tr>
                        <th style="width:50%">Person</th>
                        <th><span class="float-light">Pax</span></th>
                        <th><span class="float-light">Price (THB)</span></th>
                        <th><span class="float-light">Amount (THB)</span></th>
                      </tr>
                      <tr>
                        <td>Adult</td>
                        <td><span class="float-light">{{dataTransaction.adult_pax}}</span></td>
                        <td><span class="float-light">{{dataTransaction.adult_price | number:'1.2-2'}}</span></td>
                        <td><span class="float-light">{{dataTransaction.total_adult_price | number:'1.2-2'}}</span></td>
                      </tr>
                      <tr>
                        <td>Child</td>
                        <td><span class="float-light">{{dataTransaction.child_pax}}</span></td>
                        <td><span class="float-light">{{dataTransaction.child_price | number:'1.2-2'}}</span></td>
                        <td><span class="float-light">{{dataTransaction.total_child_price | number:'1.2-2'}}</span></td>
                      </tr>
                      <tr>
                        <td>Infant</td>
                        <td><span class="float-light">{{dataTransaction.infant_pax}}</span></td>
                        <td><span class="float-light">{{ 0 | number:'1.2-2'}}</span></td>
                        <td><span class="float-light">{{ 0 | number:'1.2-2'}}</span></td>
                      </tr>

                      <!-- Discount -->
                      <ng-container *ngIf="dataTransaction.discount > 0">
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            <span class="float-light">
                              <b>{{ dataTransaction.total_price + dataTransaction.discount | number:'1.2-2'}}</b>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="discount p-t-5">
                            Promotion code : 
                            <span class="f-w-500">{{ dataTransaction.discount_code }}</span>
                          </td>
                          <td class="discount p-t-5"></td>
                          <td class="discount p-t-5">
                            <span class="float-light">
                              Discount {{ dataTransaction.discount_rate }}
                            </span>
                          </td>
                          <td class="discount p-t-5">
                            <span class="float-light">
                              -{{ dataTransaction.discount | number:'1.2-2'}}
                            </span>
                          </td>
                        </tr>
                      </ng-container>

                      <tr>
                        <td class="p-t-15"></td>
                        <td class="p-t-15"></td>
                        <td class="review-text-total p-t-15"><span class="float-light"><b>TOTAL</b></span></td>
                        <td class="review-text-total p-t-15"><span class="float-light"><b>{{ dataTransaction.total_price | number:'1.2-2'}}</b></span></td>
                      </tr>
                    </table>
                  </div>
              </div>
          </div>
        </div>
     </div>
     <div class="col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <div class="remark-form">
          <span>
            *The listed price does not include a 500THB & 1,000THB surcharge for pick
            up and drop off in hotel outside the city centre (6 km. from the three king monument).
          </span><br><br>
          <span style="color:red">
            Noted : After your payment via Paypal, the system will redirect to our website.
            We will get back the confirmation to you via email.
          </span>
        </div>
        <hr>
        <ngx-paypal [config]="payPalConfig" [registerscript]="true"></ngx-paypal>
        <br>
        <div *ngIf="checked_reload == true" class="reload-icon"><img class="icon-reload-gif" src="./assets/images/payment/Reload-1s-200px.gif"></div>
        <div *ngIf="message_wait == true" class="message-wait" >Your Payment is being processed, Please do not close this page</div>
     </div>
    </div>
  </div>

<!-- ---- Footer ---- -->
<!-- <app-footer></app-footer> -->
