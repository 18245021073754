import { Injectable } from "@angular/core";
import { ApiServiceService } from "./api-service.service";
import { Observable, of } from "rxjs";
import { EnvService } from "src/app/services/env.service";

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  private path = "Website";
  private configs: any = {};

  constructor(
    private service: ApiServiceService,
    private envService: EnvService,
  ) {
    let config = this.envService.getConfig();
    this.configs = config;
  }

  getNews(payload: any = {}): Observable<any> {
    return of({ ...this.getMockData()});
    return this.service.callGet(`${this.path}/Events`, payload);
  }

  //! Mock data
  getMockData() {
    const mockNews = {
      messages: "Completed",
      status: true,
      data: [
        {
          id: 1,
          title: "",
          short_description: "",
          description: "",
          link: "/event/1",
          // image_url: `${this.configs.SERVER_URL}images/news/Heaven Lantern 2024.jpg`,
          image_url: `/assets/images/news/Heaven Lantern 2024.jpg`,
          image_alt: "Heaven Lantern 2024",
          hide_date: "2024-11-14"
        }
      ]
    }
    return mockNews;
  }
}
