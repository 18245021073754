import { Component, OnInit, HostListener, TemplateRef, ViewChild } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import * as Rellax from "rellax";
import { Router } from "@angular/router";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Meta } from "@angular/platform-browser";
import * as AOS from "aos";
import { ApiServiceService } from "./../../services/api-service.service";
import { EnvService } from "src/app/services/env.service";
import { HomeService } from "src/app/services/home.service";
import { MatDialog } from '@angular/material/dialog';
// import { encrypt } from "src/app/helpers/crypto";

// import { $ } from 'protractor';
declare var $: any;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  [x: string]: any;
  @ViewChild('templateNews') templateNews: TemplateRef<any>;

  public configs: any = {};

  parallax_variable = false;

  navbar_active_home = false;
  navbar_active_about = false;
  navbar_active_tours = false;
  navbar_active_activities = false;
  navbar_active_contact = false;

  Tours = <any>"";
  Tours_List = <any>"";
  Rec_Tours: Array<any> = [];
  Ex_Tours: Array<any> = [];
  video_cover = <any>"";
  video_cover_ = <any>"";
  video_cover__ = <any>"";

  modalRef: BsModalRef;

  config_ = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false,
    class: "model-p gray modal-lg",
  };

  public subscribe = <any>"";
  public partner_logo = <any>"";
  public browser_variable: boolean = false;
  public messages_variable: boolean = false;

  public news: Array<any> = [];

  constructor(
    private router: Router,
    private metaTagService: Meta,
    private modalService: BsModalService,
    private apiService: ApiServiceService,
    private envService: EnvService,
    private homeService: HomeService,
    public dialog: MatDialog
  ) {
    let config = this.envService.getConfig();
    this.configs = config;
  }

  // <<< Initial >>>
  ngOnInit(): void {
    this.callGetNews();
    AOS.init({
      once: false,
    });
    this.clickevent();
    this.setActivePage();
    this.detectBrowser();
    this.callGetTours();
    this.callJsonGetPartner();

    // this.getTours();
    // this.getLogopartner();
    var rellaxSubscribe = new Rellax(".rellax-subscribe", {
      speed: 2,
      center: true,
      wrapper: true,
      round: true,
      vertical: false,
      horizontal: false,
    });

    // this.video_cover =
    //   `${this.configs.SERVER_URL}` + "videos/TouringCenter-480p.mp4";
    // this.video_cover_ =
    //   `${this.configs.SERVER_URL}` + "videos/TouringCenter-cover.mp4";
    // this.video_cover__ =
    //   `${this.configs.SERVER_URL}` + "videos/Day_Trip_V1.mp4";
    this.video_cover_ = "/assets/video/home/TouringCenter-cover.mp4";

    this.metaTagUpdate();
  }

  callGetNews(): void {
    this.homeService.getNews().subscribe((res:any) => {
      if (res?.status) {
        this.news = res.data;
        this.showNewsModal();
      }
    })
  }

  showNewsModal() {
    const newsData = sessionStorage.getItem("news-lantern");
    if (newsData) return;

    if (this.news && this.news.length > 0) {
      // Fix hide popup
      if (new Date(this.news[0].hide_date) < new Date()) {
        return;
      }

      setTimeout(() => {
        // this.openModal(this.templateNews);
        const el = document.getElementById('modal-center');
        el.classList.add('uk-flex');
        el.classList.add('uk-open');
        // this.UIkit.toggle(el).toggle();
      }, 1000)
    }
  }

  closeModal(elementId: string) {
    if (elementId) {
      const el = document.getElementById(elementId);
      el.classList.remove('uk-flex');
      el.classList.remove('uk-open');

      // sessionStorage.setItem("news-lantern", encrypt({news: "lantern"}));
      sessionStorage.setItem("news-lantern", JSON.stringify("event-lantern"));
    }
  }

  // <<< -----------------------------------------
  // ------------------- Call API ----------------
  // ----------------------------------------- >>>
  // get tours
  callGetTours(): void {
    const url = "Website/Tour/GetAllTours";
    let setPayload = {};

    this.apiService
      .callPost(url, setPayload)
      .toPromise()
      .then(
        (result) => [
          (this.Tours = result.data),
          (this.Tours_List = this.Tours),
          // console.log(this.Tours),
        ],
        (err) => [console.log(err)]
      )
      .then(() => {
        this.checkTourListCategories();
      });
  }
  // check categories
  checkTourListCategories() {
    //check type
    for (let i = 0; i <= this.Tours.length - 1; i++) {
      if (this.Tours[i].categories == "Recommended Trip" || this.Tours[i].is_recommend) {
        this.Rec_Tours.push(this.Tours[i]);
      }
      if (this.Tours[i].categories == "Experience Trip") {
        this.Ex_Tours.push(this.Tours[i]);
      }
    }
  }

  // <<< -----------------------------------------
  // ------------------- Call JSON ---------------
  // ----------------------------------------- >>>
  // get account code
  callJsonGetPartner(): void {
    const url = "partner.json";
    this.apiService.callGetJSON(url).subscribe(
      (result) => [(this.partner_logo = result)],
      (err) => [console.log(err)]
    );
  }

  // <<< -----------------------------------------
  // ------------------- On Event ----------------
  // ----------------------------------------- >>>
  // tour detail
  onClickTours(event) {
    sessionStorage.setItem("active_page", "tours");
    this.router.navigate(["tour/" + event]);
  }
  // event click tour slide
  clickevent() {
    $(".E-btn-left").click(function () {
      $(".l-Eclick").click();
    });
    $(".E-btn-right").click(function () {
      $(".r-Eclick").click();
    });
    $(".R-btn-left").click(function () {
      $(".l-Rclick").click();
    });
    $(".R-btn-right").click(function () {
      $(".r-Rclick").click();
    });
  }
  // toggle dialog
  toggleDialog(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      // Object.assign({}, { class: 'gray modal-lg' }),
      this.config_
    );
  }

  // <<< -----------------------------------------
  // -------------------- Share ------------------
  // ----------------------------------------- >>>
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  // detect browser
  detectBrowser() {
    // Detect Browser
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (ua.indexOf("chrome") > -1) {
        this.browser_variable = false; // Chrome
        var rellaxHeader = new Rellax(".rellax-header");
      } else {
        this.browser_variable = true; // Safari
      }
    }
  }
  // active page
  setActivePage() {
    // sessionStorage.clear();
    sessionStorage.removeItem("active_page");
    sessionStorage.setItem("active_page", "home");
  }
  // scroll top
  @HostListener("document:scroll")
  scrollfunction() {
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      this.parallax_variable = true;
    } else {
      this.parallax_variable = false;
    }
  }

  // <<< -----------------------------------------
  // ------------------- Meta Tag ----------------
  // ----------------------------------------- >>>
  metaTagUpdate() {
    this.metaTagService.updateTag({
      name: "title",
      property: "og:title",
      content:
        "Tour in Chiang Mai and around &amp; Activities with Premium Service : Touring Center",
    });
    this.metaTagService.updateTag({
      name: "description",
      property: "og:description",
      content:
        "Explore and Experience tour in Chiang Mai and around from professional team with the unbeatable service. Touring Center provide a daily departure day tour and package for both join with group (small size) and private trip.",
    });
    this.metaTagService.updateTag({
      name: "keywords",
      content:
        "Tour in Chiang Mai, Day Tour, Chiang Mai Tour, Day Trip, Join Premium, Join Tour, Private Tour, Touring Center, Yi Peng Chiang Mai, chiang mai tour company, Chiang Mai, small group",
    });
    this.metaTagService.updateTag({
      name: "image",
      property: "og:image",
      content:
        "https://touringcenter.com/assets/images/TC/logo-tc-52549811-1-sh.png",
    });
    this.metaTagService.updateTag({
      name: "url",
      property: "og:url",
      content: "https://touringcenter.com/",
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "gray modal-lg" })
    );
  }

  toggleModal(event: boolean = false, link: string = ""): void {
    // console.log("toggle modal", event, link);
    // if (event) {
      // this.modalRef.hide()

      setTimeout(() => {
        if (link) {
          this.router.navigate([link]);
        }
      }, 500)
    // }
  }

  // <<< -----------------------------------------
  // ------------------ Fix Binding --------------
  // ----------------------------------------- >>>
  //  promotions
  customOptions: OwlOptions = {
    loop: true,
    rtl: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    smartSpeed: 500,
    navSpeed: 700,
    autoplay: true,
    autoplayTimeout: 8000,
    autoplayHoverPause: true,
    // navText: [ '<i class="fa-chevron-left"></i>', '<i class="fa-chevron-right"></i>' ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: false,
  };
  // tours R option
  tourROptions: OwlOptions = {
    loop: true,
    rtl: false,
    mouseDrag: false,
    touchDrag: true,
    freeDrag: true,
    pullDrag: true,
    dots: true,
    animateIn: true,
    animateOut: true,
    dragEndSpeed: 1,
    lazyLoad: true,
    smartSpeed: 200,
    navSpeed: 700,
    navText: [
      '<img class="l-Rclick" src="../../assets/images/home/png/5526019091530273505-128.png">',
      '<img class="r-Rclick" src="../../assets/images/home/png/17780639371530273506-128.png">',
    ],
    rewind: true,
    stagePadding: 2,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
    nav: false,
  };
  // tours E option
  tourEOptions: OwlOptions = {
    loop: true,
    rtl: false,
    mouseDrag: false,
    touchDrag: true,
    freeDrag: true,
    pullDrag: true,
    dots: true,
    animateIn: true,
    animateOut: true,
    dragEndSpeed: 1,
    lazyLoad: true,
    smartSpeed: 200,
    navSpeed: 700,
    navText: [
      '<img class="l-Eclick" src="../../assets/images/home/png/5526019091530273505-128.png">',
      '<img class="r-Eclick" src="../../assets/images/home/png/17780639371530273506-128.png">',
    ],
    rewind: true,
    stagePadding: 2,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
  };
  // partner
  partnerOptions: OwlOptions = {
    items: 12,
    loop: true,
    autoplay: true,
    smartSpeed: 1000,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    margin: 20,
    responsive: {
      0: {
        items: 3,
      },
      400: {
        items: 5,
      },
      740: {
        items: 6,
      },
      940: {
        items: 8,
      },
    },
    nav: false,
  };
}
