<!-- ------- Cover Images About us ---------- -->
<section
  [class.browser_change]="browser_variable"
  class="py-5 module parallax parallax-1"
  data-rellax-speed="2"
>
  <div class="centered_">
    <span class="tittle-page">About Us</span>
  </div>
</section>

<!-- ------------- content about us ------------------ -->
<section class="section-container">
  <!-- Logo & Who we are -->
  <div class="container">
    <div class="tc-logo-content" data-aos="fade-up" data-aos-duration="500">
      <img class="tc-logo" src="./assets/images/logo/TC-LOGO-2024.png" />
    </div>
  </div>

  <!-- Image -->
  <div class="container">
    <div class="about-images-content">
      <div class="row">
        <div class="col-4 pad-5">
          <div
            class="img-content_01"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img
              class="img-content__"
              src="./assets/images/about/jpg/about-02-567894.jpg"
            />
          </div>
        </div>

        <div class="col-4 pad-5">
          <div
            class="img-content_01"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img
              class="img-content__"
              src="./assets/images/about/jpg/about-01-884521.jpg"
            />
          </div>
        </div>
        <div class="col-4 pad-5">
          <div
            class="img-content_01"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img
              class="img-content__"
              src="./assets/images/about/jpg/about-13-251674.jpg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Logo & Who we are -->
  <div class="container">
    <div class="content-about" data-aos="fade-up" data-aos-duration="900">
      <h4><u>Who we are:</u></h4>
      <p>Touring Center, based in Chiang Mai, is your premier Tour Operator with over 15 years of experience (since 2004). We specialize in providing "Premium Service with a Small Group Concept" and strictly adhere to a "No Optional Policy," especially for our Join Tours. We believe in creating beautiful memories with every travel moment, ensuring our travelers experience the wonder of each trip with us.</p>
    </div>
  </div>

  <!-- What we do -->
  <div class="container">
    <div class="content-about" data-aos="fade-up" data-aos-duration="900">
      <h4><u>What we do:</u></h4>
      <p>At <span class="fw-500"><span class="f-logo-first">T</span>ouring <span class="f-logo-first">C</span>enter</span>, we curate Daily Operated Guided Day Tours, both "Join" with a Group and "Private," in and around Chiang Mai. We leverage our expertise, knowledge, and resources to exceed traveler expectations and deliver happiness by offering:</p>
      <ul>
        <li><span class="fw-600">VIP 9-Seated Vans:</span> Comfortable seats and extra legroom.</li>
        <li><span class="fw-600">Professional English-Speaking Guides:</span> Informative and experienced guides.</li>
        <li><span class="fw-600">Expert, Calm, and Gentle Drivers:</span> Ensuring safe travel throughout the trip.</li>
        <li><span class="fw-600">Well-Organized Programs:</span> Step-by-step itinerary with no skipping or rushing.</li>
        <li><span class="fw-600">Unbeatable Service, Prompt Response, and Dedicated Team:</span> Full support from our Back Office, Frontliners, and Suppliers.</li>
        <li><span class="fw-600">All-Inclusive Tours:</span> No hidden costs, no extra charges during travel.</li>
        <li><span class="fw-600">Guaranteed Departures:</span> With a minimum of 2 people traveling together, enjoy fully serviced tours.</li>
        <li><span class="fw-600">Personal Touch with Small Groups:</span> Maximum of 9 people per group/van, ensuring personalized attention.</li>
        <li><span class="fw-600">No Optional Policy:</span> Embrace the journey and destinations fully.</li>
        <li><span class="fw-600">Value for Money:</span> Offering exceptional value based on the above reasons.</li>
      </ul>
    </div>
  </div>

  <!-- Our Pride -->
  <div class="container">
    <div class="content-about" data-aos="fade-up" data-aos-duration="900">
      <h4><u>Our Pride:</u></h4>
      <p>We are trusted by many hotels (hoteliers who related with tour selling), Travel Agents and Travelers from our service standard and guaranteed from;</p>
      <ul>
        <li><span class="fw-600">Certificate of Excellence</span> by Tripadvisor (4 Consecutive Years from 2016 to 2019)</li>
        <li><span class="fw-600">Travelers’ Choice Awards</span> by Tripadvisor (2020)</li>
        <li><span class="fw-600">Unique Tour Operator of the Year 2017: Asia & Australia</span> by Luxury Travel Guide</li>
      </ul>
      <p>Together with the Amazing Thailand Safety & Health Administration (SHA) and SHA Plus (vaccinated staffs) for 2 categories;</p>
      <ul>
        <li><span class="fw-600">SHA Plus for Travel Agency (ID : E0705)</span></li>
        <li><span class="fw-600">SHA Plus for Transportation (ID: D5437)</span></li>
      </ul>
    </div>
  </div>

  <!-- Our Trip -->
  <div class="container">
    <div class="content-about" data-aos="fade-up" data-aos-duration="900">
      <h4><u>Our Trip:</u></h4>
      <p>There are 2 main categories which are;</p>
      <ul>
        <li>
          <b>Recommended Trip (TC-R)</b><br/>
          <span class="fw-600">Ideal for:</span> Chiang Mai's first-time visitors<br/>
          <span class="fw-600">Focus:</span> Explore famous and must-visit sites in Chiang Mai and the northern area.<br/>
          <span class="fw-600">Experience:</span> Curated tours designed to introduce travelers to the essence of the region's culture and attractions.
        </li>
        <li>
          <span class="fw-600">Ideal for:</span> Those seeking hands-on experiences<br/>
          <span class="fw-600">Focus:</span> Immerse yourself in the region through unique activities, such as village visits, with a "no rush" concept.<br/>
          <span class="fw-600">Experience:</span> A journey beyond the usual tourist path, allowing travelers to connect with local communities and absorb their way of life.<br/>
        </li>
      </ul>
    </div>
  </div>

  <!-- Image Trip -->
  <div class="container">
    <div class="about-images-content_" style="padding: 0 15%">
      <div class="row">
        <div class="col-4 pad-5">
          <div class="img-content_02">
            <img
              class="img-content___"
              src="./assets/images/about/jpg/about-06-258974.jpg"
            />
          </div>
        </div>

        <div class="col-4 pad-5">
          <div class="img-content_02">
            <img
              class="img-content___"
              src="./assets/images/about/jpg/about-09-467852.jpg"
            />
          </div>
        </div>
        <div class="col-4 pad-5">
          <div class="img-content_02">
            <img
              class="img-content___"
              src="./assets/images/about/jpg/about-11-028999.jpg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Our Vision -->
  <div class="container">
    <div class="content-about" data-aos="fade-up" data-aos-duration="900">
      <h4><u>Our Vision:</u></h4>
      <p>Journeying Together Towards Excellence</p>
    </div>
  </div>

  <!-- Why Travel with Us -->
  <div class="container">
    <div class="content-about" data-aos="fade-up" data-aos-duration="900">
      <h4><u>Why Travel with Us:</u></h4>
      <p>
        <span class="fw-600">Daily Operate</span><br/>
        Minimum 2 people, maximum 9 people per group per van for the join tour.
      </p>

      <p>
        <span class="fw-600">Professional and informative guide</span><br/>
        All trips will accompany with Licensed and Experienced Tour Guide to escort, explain, and details as well as share their aspects, experiences, and points of view to the travelers throughout the journey.
      </p>

      <p>
        <span class="fw-600">More comfortable with wider seat </span><br/>
        The comfort during the journey is serviced by a 9-seater van with expanded seats and extra-legroom.
      </p>

      <p>
        <span class="fw-600">All-Inclusive Program</span><br/>
        No extra program is added or No optional tour will make the trip more convenient, and relaxing in the "no rush" concept.
      </p>

      <p>
        <span class="fw-600">Short pick-up and drop-off time </span><br/>
        The pick-up and drop-off time will take approximately 30 minutes as each van with a maximum of 5 hotels to be pick-up or drop-off.
      </p>

      <p>
        <span class="fw-600">Local Lunch</span><br/>
        Special local restaurants or Home-Cooked with cleanliness and hygiene are considered to be the first priority to work with to support the locals and broaden the traveler's experience (no pork, no lard, or vegetarian can be requested).
      </p>

      <p>
        <span class="fw-600">Drinking water and refreshing towel provided</span><br/>
        Drinking water and refreshing towel will be provided on every trip.
      </p>

      <p>
        <span class="fw-600">Every feedback is always appreciated</span><br/>
        Aim to step up and place our name on the preferred list for visitors who plan to visit Chiang Mai and Thailand. Thus, your feedback, both offline and online, is very important in helping us to improve our products, service, quality, and team.
      </p>
    </div>
  </div>
</section>

<!-- Our goal section -->
<section class="section-container">
  <!-- Image our goal -->
  <div class="container">
    <div class="about-images-content">
      <div class="col-12 container pad-0">
        <div
          class="img-content_01 h100-vh"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img
            class="img-content_"
            src="./assets/images/about/OUR-GOAL.png"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- Our goal -->
  <div class="container">
    <div class="content-about" data-aos="fade-up" data-aos-duration="900">
      <h2>Our Goal</h2>
    </div>
  </div>

  <!-- Empowering Connections and Preservation -->
  <div class="container">
    <div class="content-about" data-aos="fade-up" data-aos-duration="900">
      <h4>Empowering Connections and Preservation:</h4>
    </div>
  </div>

  <!-- Empower Connections -->
  <div class="container">
    <div class="content-about" data-aos="fade-up" data-aos-duration="900">
      <h4>Empower Connections:</h4>
      <ul>
        <li>Understanding Team Members (Frontliners and Supporting Teams)</li>
        <li>Engaging with Suppliers (Communities, Restaurants, Sites of Visit, Activities, etc.)</li>
        <li>Building Partnerships (Agents, Hotels, Concierges, etc.)</li>
        <li>Connecting with Travelers</li>
      </ul>
    </div>
  </div>

  <!-- Preserving Identities for Sustainability -->
  <div class="container">
    <div class="content-about" data-aos="fade-up" data-aos-duration="900">
      <h4>Preserving Identities for Sustainability:</h4>
      <ul>
        <li>Upholding Self-Esteem & Dignity</li>
        <li>Safeguarding Culture & Traditions</li>
        <li>Nurturing Nature & Ecosystem</li>
        <li>Fostering Beliefs, Customs, Culture, and Traditions for Sustainable Identity</li>
      </ul>
    </div>
  </div>

  <!-- Inspire, Elevate & Take Pride in -->
  <div class="container">
    <div class="content-about" data-aos="fade-up" data-aos-duration="900">
      <h4>Inspire, Elevate & Take Pride in:</h4>
      <ul>
        <li>Fostering Knowledge & Understanding (Suppliers, Partners, and Travelers)</li>
        <li>Elevating Service Standards and Quality</li>
        <li>Nurturing Race, Religious, and Community Harmony</li>
        <li>Enhancing Quality of Living and Supporting Local Economy</li>
      </ul>
    </div>
  </div>

  <!-- Providing Exceptional Experiences -->
  <div class="container">
    <div class="content-about" data-aos="fade-up" data-aos-duration="900">
      <h4>Providing Exceptional Experiences:</h4>
      <ul>
        <li>Ensuring Satisfaction, Smiles, and Positive Impressions for Travelers, Partners, and Suppliers</li>
        <li>Delivering Exceptional Service</li>
        <li>Creating Meaningful and Enriched Community Living</li>
      </ul>
    </div>
  </div>

  <!-- Refining Experiences Together -->
  <div class="container">
    <div class="content-about" data-aos="fade-up" data-aos-duration="900">
      <h4>Refining Experiences Together:</h4>
      <ul>
        <li>Your insights guide our continuous improvement journey.</li>
      </ul>
    </div>
  </div>

  <!-- Anticipating Positive Connections -->
  <div class="container">
    <div class="content-about" data-aos="fade-up" data-aos-duration="900">
      <h4>Anticipating Positive Connections:</h4>
      <ul>
        <li>Earning Trust from all Stakeholders</li>
        <li>Cultivating Cooperative Collaboration with Strong Alliances</li>
        <li>Creating Smiles and Positive Impressions from Visitors"</li>
      </ul>
    </div>
  </div>
</section>