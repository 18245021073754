import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { IPayPalConfig, ICreateOrderRequest } from "ngx-paypal";
import Swal from "sweetalert2";
import { ApiServiceService } from "./../../services/api-service.service";
import { EnvService } from './../../services/env.service';

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"],
})
export class PaymentComponent implements OnInit {
  private configs: any = {};
  public transactionId = <any>"";
  public dataTransaction = <any>"";
  public dataPayment = <any>"";
  public setTransactionId = <any>"";
  public payPalConfig?: IPayPalConfig;
  public updatePaymentId = <any>"";
  public checked_reload: boolean = false;
  public message_wait: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiServiceService,
    private envService: EnvService
  ) {
    let config = this.envService.getConfig();
    this.configs = config;
  }

  // <<< Initial >>>
  ngOnInit(): void {
    this.transactionId = this.route.snapshot.paramMap.get("id");
    this.callGetTransaction();
  }

  // <<< -----------------------------------------
  // ------------------- Call API ----------------
  // ----------------------------------------- >>>
  // get transaction
  callGetTransaction(): void {
    const url = "Website/Transaction/GetTransactionById";
    let setPayload = {
      transaction_id: this.transactionId,
    }

    this.apiService
      .callPost(url, setPayload)
      .toPromise()
      .then(
        (result) => [
          (this.dataTransaction = result),
          this.dataTransaction.discount_code = this.dataTransaction.discount_code || '',
          // this.dataTransaction.privacy = this.dataTransaction.privacy.toUpperCase(),
        ],
        (err) => [console.log(err)]
      )
      .then(() => {
        this.callGetPayment();
      });
  }
  // get payment data
  callGetPayment(): void {
    const url = "Website/Payment/GetPaymentByTransactionId";
    let setPayload = {
      transaction_id: this.transactionId,
    }

    this.apiService
      .callPost(url, setPayload)
      .toPromise()
      .then(
        (result) => [
          (this.dataPayment = result),
          // console.log(this.dataPayment),
        ],
        (err) => [console.log(err)]
      )
      .then(() => {
        this.callCheckPaymentUpdate();
        this.configPayment();
      });
  }
  // update payment
  callUpdatePayment(): void {
    // reload icon & message
    this.checked_reload = true;
    this.message_wait = true;

    const url = "Website/Payment/UpdatePaymentById";
    let setPayload = {
      payment_id: this.dataPayment.payment_id,
    };

    this.apiService
      .callPost(url, setPayload)
      .toPromise()
      .then(
        (result) => [
          (this.updatePaymentId = result),
          // console.log(this.updatePaymentId),
        ],
        (err) => [console.log(err)]
      )
      .then(() => {
        // this.checkPaymentUpdate();
        this.callCheckPaymentUpdate();
      });
  }

  // <<< -----------------------------------------
  // ------------------- Payment -----------------
  // ----------------------------------------- >>>
  // config payment (paypal)
  private configPayment(): void {
    this.payPalConfig = {
      currency: "THB",
      clientId: `${this.configs.PAYPAL_KEY}`,
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: "CAPTURE",
          purchase_units: [
            {
              amount: {
                currency_code: "THB",
                value: this.dataPayment.amount,
                breakdown: {
                  item_total: {
                    currency_code: "THB",
                    value: this.dataPayment.amount,
                  },
                },
              },
              items: [
                {
                  name:
                    this.dataTransaction.tour_code +
                    " : " +
                    this.dataTransaction.tour_title +
                    " | receipt : " +
                    this.dataPayment.invoice,
                  description:
                    this.dataTransaction.tour_code +
                    " : " +
                    this.dataTransaction.tour_title +
                    " | receipt : " +
                    this.dataPayment.invoice,
                  quantity: "1",
                  category: "DIGITAL_GOODS",
                  unit_amount: {
                    currency_code: "THB",
                    value: this.dataPayment.amount,
                  },
                },
              ],
            },
          ],
        },
      advanced: {
        commit: "true",
      },
      style: {
        label: "paypal",
        // layout: 'vertical',
        size: "responsive",
        color: "gold",
        // shape: 'pill',
        // label: 'checkout'
        // tagline: 'true',
        layout: "horizontal",
      },
      onApprove: (data, actions) => {
        // console.log(
        //   "onApprove - transaction was approved, but not authorized",
        //   data,
        //   actions
        // );
        actions.order.get().then((details) => {
          // console.log(
          //   "onApprove - you can get full order details inside onApprove: ",
          //   details
          // );
        });
      },
      onClientAuthorization: (data) => {
        // console.log(
        //   "onClientAuthorization - you should probably inform your server about completed transaction at this point",
        //   data
        // );
        this.callUpdatePayment();
      },
      onCancel: (data, actions) => {
        // console.log("OnCancel", data, actions);
      },
      onError: (err) => {
        // console.log("OnError", err);
      },
      onClick: (data, actions) => {
        // console.log("onClick", data, actions);
      },
    };
  }
  // check payment update (to receipt)
  callCheckPaymentUpdate(): void {
    const url = "Website/Payment/CheckPaymentPaid";
    let setPayload = {
      payment_id: this.dataPayment.payment_id,
    };

    let callBack = null;

    this.apiService
      .callPost(url, setPayload)
      .toPromise()
      .then(
        (result) => [
          (callBack = result),
          // console.log(callBack),
        ],
        (err) => [console.log(err)]
      )
      .then(() => {
        this.checkPaymentUpdate(callBack);
      });
  }
  checkPaymentUpdate(params) {
    if (params.status == true) {
      (async () => {
        await this.delay(3000);
        this.checked_reload = false;
        this.message_wait = false;
        Swal.fire({
          icon: "success",
          text: "Payment Success",
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          //go to Receipt
          this.router.navigate(["receipt/" + this.transactionId]);
        });
      })();
    }
    if (this.updatePaymentId.status == "false") {
      this.checked_reload = false;
      Swal.fire({
        icon: "error",
        text: "ERROR! UPDATE NOT SUCCESS, Contact it@touringcnx.com",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }

  // <<< -----------------------------------------
  // -------------------- Share ------------------
  // ----------------------------------------- >>>
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
