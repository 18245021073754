import * as moment from 'moment';

import { isDate, isString } from '../helpers';

export const displayDate = (date: any, format = 'DD MMM YYYY') => {
  if (isDate(date)) {
    return moment(date).format(format);
  }
  return '';
};

export const displayDateTime = (date: any) => {
  if (isDate(date)) {
    return moment(date).format('DD MMM YYYY HH:mm:ss');
  }
  return '';
};

export const displayDateQuarterYear = (date: any) => {
  if (isDate(date)) {
    return 'Q' + moment(date).format('Q YYYY');
  }
  return '';
};

export const displayDateServer = (date: any, is_timezone: boolean = true) => {
  if (isDate(date)) {
    if (is_timezone) {
      return moment(date).format('YYYY-MM-DDTZ');
    } else {
      return moment(date).format('YYYY-MM-DD');
    }
  }
  return null;
};

export const displayDatePeriodServer = (date: any, name: string = '') => {
  if (isDate(date) && isString(name)) {
    if (name === 'start') {
      return moment(date).format('YYYY-MM-DDT00:00:00');
    } else if (name === 'end') {
      return moment(date).format('YYYY-MM-DDT23:59:59');
    } else {
      return moment(date).format('YYYY-MM-DDTHH:mm:ss');
    }
  }
  return null;
};

export const displayDateTimeServer = (date: any) => {
  if (isDate(date)) {
    return moment(date).format('YYYY-MM-DDTHH:mm:ssZ');
  }
  return null;
};

export const displayMonthYear = (date: any) => {
  if (isDate(date)) {
    return moment(date).format('MMM YYYY');
  }
  return '';
};

export const displayYear = (date: any) => {
  if (isDate(date)) {
    return moment(date).format('YYYY');
  }
  return '';
};

export const displayFromNow = (date: any) => {
  if (isDate(date)) {
    return moment(date).fromNow();
  }
  return '';
};

export const displayDiffDate = (date: any) => {
  if (isDate(date)) {
    const now = new Date();
    return moment(date).diff(now, 'days');
  }
  return 0;
};

export const displayString = (data: any) => {
  if (isString(data)) {
    return data.trim();
  }
  return data?.toString() || '';
}

export const displayFromUtc = (data: any) => {
  if (isDate(data)) {
    return moment(data).utc().format("DD MMM YYYY");
  }
  return null || '';
}
