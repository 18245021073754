import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaDataService {
  constructor(
    private titleService: Title,
    private metaService: Meta,
  ) {}

  setTitle(title: string): void {
    this.titleService.setTitle(title);
  }

  setMetaTag(name: string, content: string): void {
    this.metaService.updateTag({ name, content });
  }

  addMetaTag(name: string, content: string): void {
    this.metaService.addTag({ name, content });
  }

  removeMetaTag(name: string): void {
    this.metaService.removeTag(`name="${name}"`);
  }

}