import { Component, OnInit } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { Meta } from "@angular/platform-browser";
import { EnvService } from "src/app/services/env.service";

@Component({
  selector: "app-activities",
  templateUrl: "./activities.component.html",
  styleUrls: ["./activities.component.scss"],
})
export class ActivitiesComponent implements OnInit {
  public configs: any = {};
  public content_all: boolean = true;
  public content_csr: boolean = true;
  public content_famtrip: boolean = true;
  public content_you: boolean = true;

  constructor(
    private meta: Meta,
    private metaTagService: Meta,
    private envService: EnvService
  ) {
    let config = this.envService.getConfig();
    this.configs = config;
  }

  ngOnInit(): void {
    this.metaTagUpdate();
  }

  // <<< -----------------------------------------
  // ------------------- On Event ----------------
  // ----------------------------------------- >>>
  // check content
  checkContent(event) {
    //check Content
    if (event == "all") {
      this.content_csr = true;
      this.content_famtrip = true;
      this.content_you = true;
    }
    if (event == "csr") {
      this.content_csr = true;
      this.content_famtrip = false;
      this.content_you = false;
    }
    if (event == "famtrip") {
      this.content_csr = false;
      this.content_famtrip = true;
      this.content_you = false;
    }
    if (event == "youtube") {
      this.content_csr = false;
      this.content_famtrip = false;
      this.content_you = true;
    }
  }

  // <<< -----------------------------------------
  // ------------------- Meta Tag ----------------
  // ----------------------------------------- >>>
  metaTagUpdate() {
    this.metaTagService.updateTag({
      name: "title",
      property: "og:title",
      content:
        "Tour in Chiang Mai and around &amp; Activities with Premium Service : Touring Center",
    });
    this.metaTagService.updateTag({
      name: "description",
      property: "og:description",
      content:
        "Explore and Experience tour in Chiang Mai and around from professional team with the unbeatable service. Touring Center provide a daily departure day tour and package for both join with group (small size) and private trip.",
    });
    this.metaTagService.updateTag({
      name: "keywords",
      content:
        "Tour in Chiang Mai, Day Tour, Chiang Mai Tour, Day Trip, Join Premium, Join Tour, Private Tour, Touring Center, Yi Peng Chiang Mai, chiang mai tour company, Chiang Mai, small group",
    });
    this.metaTagService.updateTag({
      name: "image",
      property: "og:image",
      content:
        `${this.configs.WEBSITE_URL}` +
        "/assets/images/TC/logo-tc-52549811-1-sh.png",
    });
    this.metaTagService.updateTag({
      name: "url",
      property: "og:url",
      content: `${this.configs.WEBSITE_URL}`,
    });
  }

  // <<< -----------------------------------------
  // ------------------ Fix Binding --------------
  // ----------------------------------------- >>>
  // tours 
  CSROptions: OwlOptions = {
    loop: false,
    rtl: false,
    mouseDrag: true,
    touchDrag: true,
    freeDrag: true,
    pullDrag: true,
    dots: false,
    dragEndSpeed: 1,
    lazyLoad: true,
    smartSpeed: 200,
    navSpeed: 700,
    navText: [
      '<img class="r-Rclick" src="../../assets/images/home/png/5526019091530273505-128.png">',
      '<img class="l-Rclick" src="../../assets/images/home/png/17780639371530273506-128.png">',
    ],
    rewind: false,
    autoplay: false,
    autoplayTimeout: 5000,
    stagePadding: 2,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 3,
      },
      740: {
        items: 4,
      },
      940: {
        items: 4,
      },
    },
    nav: false,
  };

}