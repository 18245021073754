export const URL = {
  LOCAL: "http://localhost:4000",
  DEV: "https://dev.tour-in-chiangmai.com",
  PROD: "https://touringcenter.com",
}

export const ROUTE_PATH = {
  BASE_SERVER: {
    TEXT: "Base Server",
    // ROUTE: "http://localhost:8000/",
    // ROUTE: 'https://api.tourinchiangmai.com/',
    ROUTE: 'https://api.touringcenter.com/',
  },
  BASE_API_WEBSITE: {
    TEXT: "Base API Path",
    // ROUTE: "http://localhost:8000/api/",
    // ROUTE: 'https://api.tourinchiangmai.com/api/',
    ROUTE: 'https://api.touringcenter.com/api/',
  },
  BASE_JSON: {
    TEXT: "Base JSON Path",
    ROUTE: "./assets/json/",
  },
  BASE_WEBSITE: {
    TEXT: "Base JSON Path",
    // ROUTE: "http://localhost:4000/",
    // ROUTE: 'https://dev.touringcenter.com/',
    ROUTE: 'https://touringcenter.com/',
  },
};

export const PAYMENT_ACCOUNT = {
  PAYPAL_ACCOUNT: {
    TEXT: "info@touringcnx.com",
    SECRET_KEY: 'AeujZG5FQrI_MpxAttNySdA5lZMoaMTKduDYiHtn5Piuq0J8QdiHJ2cUH5s5LsSGOu_SABS4KfBTU_ck',
    // SECRET_KEY: 'AQQ1zAgpzz2cz08zvjCwFcMwTPy-v6LuZVYr7ogA26o0FslzuSBQFx5-obuOwhjOh8GWukpe7i6nOTd0', // sandbox
  },
}

export const LINE_NOTIFY = {
  CONFIRM_BOOKING: {
    // TOKEN: "cCqDJwuTB5sXaLcgN2zCBMLGrLygV5A38nT4rUARyIs",
    // TOKEN: "OOENYHPedXeZDotWyD5cVX6YuIdsQCkfwB1zMq1BWmB", // Dev
    TOKEN: "SSPWbyxIOtRdvgGOJEPpY8is2HACU6S8bEN6j5HTEyC", // TC
    LINK: "https://notify-api.line.me/api/notify",
  },

}
