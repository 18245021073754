import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { SafeResourceUrl, DomSanitizer } from "@angular/platform-browser";
import { ApiServiceService } from "./../../services/api-service.service";
import { EnvService } from "src/app/services/env.service";

@Component({
  selector: "app-receipt",
  templateUrl: "./receipt.component.html",
  styleUrls: ["./receipt.component.scss"],
})
export class ReceiptComponent implements OnInit {
  public configs: any = {};
  public transactionId = <any>"";
  public setTransactionId = <any>"";
  public ReceiptPDF = <any>"";
  public is_show_pdf: boolean = true;

  // public pdfPath = `${ROUTE_PATH.BASE_SERVER.ROUTE}` + "PDF/receipt/";
  public pdfPath: string = '';

  public pdfSrc: SafeResourceUrl;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiServiceService,
    private sanitizer: DomSanitizer,
    private envService: EnvService
  ) {
    let config = this.envService.getConfig();
    this.configs = config;
    this.pdfPath = config?.SERVER_URL + "PDF/receipt/";
  }

  // <<< Initial >>>
  ngOnInit(): void {
    this.transactionId = this.route.snapshot.paramMap.get("id");
    this.callGetReceiptPDF();
  }

  // <<< -----------------------------------------
  // ------------------- Call API ----------------
  // ----------------------------------------- >>>
  callGetReceiptPDF(): void {
    const url = "Website/Transaction/GetReceiptPDF";
    let setPayload = {
      transaction_id: this.transactionId,
    };

    this.apiService
      .callPost(url, setPayload)
      .toPromise()
      .then(
        (result) => [
          (this.ReceiptPDF = result),
          console.log(url, this.ReceiptPDF),
          this.is_show_pdf = true
        ],
        (err) => [
          console.log(err),
          this.is_show_pdf = false
        ]
      )
      .then(() => {
        this.pdfPath = this.pdfPath + this.ReceiptPDF.pdf;
        this.setUrl();
      });
  }

  PDFDownload() {
    window.open(this.pdfPath, "_blank");
    // FileSaver.saveAs(this.pdfPath, this.ReceiptPDF.pdf);
  }

  onClick(name: string) {
    if(name == "HOME"){
      if(confirm("Please confirm to home page.")){
        this.router.navigate(["/"]);
      }
    }else if(name === "UPDATE"){
      this.router.navigate(["update/booking/" + this.transactionId]);
    }
  }

  // PDF event
  setUrl() {
    this.cleanup();
    setTimeout(() => {
      this.pdfSrc = this.bypassAndSanitize(this.pdfPath);
    }, 50);
  }

  cleanup() {
    this.pdfSrc = null;
  }

  bypassAndSanitize(url): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
