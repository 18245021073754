<ng-container *ngIf="!isAcceptCookies">
  <div class="accept-cookies">
    <div class="container content shadow">
      <h3>เว็บไซต์นี้ใช้คุกกี้</h3>
      <p>
        เราใช้คุกกี้เพื่อเพิ่มประสิทธิภาพและประสบการณ์ที่ดีในการใช้งานเว็บไซต์คุณสามารถเลือกตั้งค่าความยินยอมการใช้คุกกี้ได้ โดยคลิก "การตั้งค่าคุกกี้"
        <a class="privacy-policy" [routerLink]="['/privacy-policy']" target="_blank">
          นโยบายความเป็นส่วนตัว
        </a>
      </p>
      <div class="accept-block position-absolute top-0 end-0">
        <a class="privacy-policy" [routerLink]="['#']" target="_blank">
          Cookies setting
        </a>
        <button type="submit" class="btn btn-dark m-2" (click)="onClickAccept()">Accept cookies</button>
      </div>
    </div>
  </div>
</ng-container>
