<!-- <app-header-non-fixed></app-header-non-fixed> -->

  <div class="container body-navbar">
    <div class="tc-logo-content">
      <!-- <img class="tc-logo" src="./../../../../assets/images/TC/logo-tc-52549811.png"> -->
      <img class="tc-logo" src="./../assets/images/logo/TC-LOGO-2024.png">
      <hr>
      <span class="text-title">Love Out Loud</span><br>
      <span class="text-comming">Comming Soon</span>
    </div>    
  </div>
  
<!-- <app-footer></app-footer> -->