<div class="modal-header tab-term-bg" *ngIf="isShowHeader">
  <span class="modal-title tab-term-font">Terms & Conditions</span>
  <button
    *ngIf="isClose"
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <!-- VALIDITY -->
  <div class="tab-bg">
    <span class="tab-font">VALIDITY</span>
  </div>
  <div class="term-title">
    <div class="detail-term">
      <ul>
        <li>All tour prices are valid from 01 January {{ year_now }} to 31 December {{ year_now + 1 }}</li>
        <li>*Except TC-R07 Eco Walk in Kew Mae Pan Nature Trail (Valid on November – May only).</li>
      </ul>
    </div>
  </div>
  <hr />

  <!-- TOUR PRICE -->
  <div class="tab-bg">
    <span class="tab-font">TOUR PRICE</span>
  </div>
  <div class="term-title">
    <div class="detail-term">
      <ul>
        <li>All tours are based on join or private tour (as mentioned).</li>
        <li>All tours are serviced by VIP 9-seater van with air conditioning (Toyota Commuter) and English-speaking tour guide.</li>
        <li>The tour prices are quoted in Thai Baht per person and minimum operate at 2 adults traveling together.</li>
      </ul>
    </div>
  </div>
  <hr />

  <!-- OPERATING -->
  <div class="tab-bg">
    <span class="tab-font">OPERATING</span>
  </div>
  <div class="term-title">
    <div class="detail-term">
      <ul>
        <li>Office hour: 07.00 a.m. to 08.30 p.m. local time daily.</li>
        <li>Office holiday: closed on 13-15 April and 31 December - 01 January.</li>
      </ul>
    </div>
  </div>
  <hr />

  <!--content Booking & Reservations-->
  <div class="tab-bg">
    <span class="tab-font">BOOKING & RESERVATIONS</span>
  </div>
  <div class="term-title">
    <div  iv class="detail-term">
      <ul>
        <li>All tours are daily operated with a minimum of 2 adults traveling together.</li>
        <li>In case of 1 adult travels with 1 child, the price will be considered as 2 adults.</li>
        <li>
          Reservation time notice
          <ul>
            - Afternoon tours reservation is available until 10.00 a.m. on the departure day.<br/>
            - Evening tours reservation is available until 02.00 p.m. on the departure day.<br/>
            - Apart from the time mentioned is must be checking the availability.<br/>
          </ul>
        </li>
        <li>
          Information requirements for the booking
          <ul>
            - Full name on passport of all travelers.<br/>
            - Hotel name for pick-up & drop-off service.<br/>
            - Food allergy (if any)<br/>
          </ul>
        </li>
        <li>
          Reservation contact
          <ul>
            - Tel: +66(0)53 289644<br/>
            - Mobile: +66(0)88 2585817<br/>
            - E-mail: reservations@touringcenter.com<br/>
            - Website: www.touringcenter.com<br/>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <hr />

  <!--Van hire-->
  <ng-container *ngIf="false">
    <div class="tab-bg">
      <span class="tab-font">VAN HIRE INCLUDE GASOLINE IN CHIANG MAI</span>
    </div>
    <div class="term-title">
      <div class="detail-term">
        <span>
          - Full day Service for 10 hours per day (pick-up time starts between
          06.00 - 10.00 a.m. and drop-off time is within 08.00 p.m.)</span
        ><br />
        <span>
          - Overtime will be charged 200 THB per hour (no later than 11.00
          p.m.)</span
        ><br />
        <span>
          - Routing is in the Chiang Mai city area that isn’t over the outer
          (3rd) ring road. In case the direction is out of the mention, there
          will be an extra charge;</span
        ><br />
        <div class="detail-term-inside">
          <span
            ><i class="fa fa-angle-right" aria-hidden="true"></i> Mae Rim (Queen
            Sirikit Botanic Garden, Mae Sa Elephant Camp, Flower Farm at Mae
            Rim, Mon Jam) will be charged 500 THB / Day / Van</span
          ><br />
          <span
            ><i class="fa fa-angle-right" aria-hidden="true"></i> San Kam Phaeng
            (San Kamphaeng Hot Spring, Mae Kampong Village) will be charged 500
            THB / Day / Van</span
          ><br />
          <span
            ><i class="fa fa-angle-right" aria-hidden="true"></i> Inthanon
            National Park will be charged 1,000 THB / Day / Van</span
          >
        </div>
        <span>
          - Extra charge for Guide Service (English speaking) is 2,000 THB per
          day (for 10 hours and maximum at 3 vans)</span
        ><br />
        <span>
          - Overtime service will be charged 300 THB per hour (no later than
          11.00 p.m.)</span
        ><br />
      </div>
    </div>
    <hr />
  </ng-container>

  <!--EXTRA CHARGE-->
  <div class="tab-bg">
    <span class="tab-font">EXTRA CHARGE</span>
  </div>
  <div class="term-title">
    <div class="detail-term">
      <span><b><u>Transfer to/from the hotel outside the city</u></b> (Three Kings Monument is considered as a city center)</span><br/>
      <ul>
        <li>Hotel is situated 06 – 15 km from the city center will be charged for THB 500 per group per way.</li>
        <li>Hotel is situated 16 – 20 km from the city center will be charged for THB 1,000 per group per way.</li>
        <li>Hotel is situated 21 – 30 km from the city center will be charged for THB 1,500 per group per way.</li>
      </ul>
      <br/>
      <span><b><u>Tour Guide in Other Language </u></b></span><br/>
      <ul>
        <li>For private tour only, the extra charge is THB 2,500 per group.</li>
      </ul>
    </div>
  </div>
  <hr />

  <!--content OPTIONAL POLICY -->
  <div class="tab-bg">
    <span class="tab-font">OPTIONAL POLICY</span>
  </div>
  <div class="term-title">
    <div class="detail-term">
      <ul>
        <li>Optional tours or side trips are not allowed for all Touring Center’s Join Trips.</li>
      </ul>
    </div>
  </div>

  <!--content CHILDREN POLICY -->
  <div class="tab-bg">
    <span class="tab-font">CHILDREN POLICY</span>
  </div>
  <div class="term-title">
    <div class="detail-term">
      <ul>
        <li>Infants and children under 2 years old are free of charge (No seat providing and share seat with parents).</li>
        <li>Children from 2 to 10 years old and no higher than 120 cm, children price will be applied. </li>
        <li>Children higher than 120 cm, adult price will be applied.</li>
        <li>Children from 10 years old and above, adult price will be applied. </li>
      </ul>
    </div>
  </div>
  <hr />

  <!--content CANCELLATION & NO SHOW -->
  <div class="tab-bg">
    <span class="tab-font">CANCELLATION & NO SHOW</span>
  </div>
  <div class="term-title">
    <div class="detail-term">
      <ul>
        <li>Cancel the trip up to 24 hours prior to the departure time with no cancellation charge.</li>
        <li>Cancel the trip after 24 hours prior to the departure time, non-refundable.</li>
        <li>Last-Minute Cancellation and No Shows will be charged 100% of the total price.</li>
        <li>Unused features are non-refundable.</li>
        <li>Itinerary is subject to change due to traffic, weather conditions, number of passengers and any other unforeseen reasons.</li>
        <li>Any change less than 12 hours before the departure time will be charged THB500 per person per change.</li>
      </ul>
    </div>
  </div>
  <hr />

  <!--content REMARK-->
  <div class="tab-bg">
    <span class="tab-font">REMARK</span>
  </div>
  <div class="term-title">
    <div class="detail-term">
      <ul>
        <li>To maintain our quality and service standard, we may not accept the booking while we are short of tour guide and/or transport.</li>
        <li>Touring Center reserves the right to implement alternate activities/locations or make other significant changes to the itinerary, including cancellation, due to force majeurs, weather conditions, operational considerations, or imposed security limitations.</li>
        <li>The trip cancelled by Touring Center, the full payment will be refunded.</li>
        <li>All rights reserved by Touring Center, Chiang Mai.</li>
      </ul>
    </div>
  </div>
</div>