import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as Rellax from 'rellax';

@Component({
  selector: 'app-kiew-mae-pan-natural-trail',
  templateUrl: './kiew-mae-pan-natural-trail.component.html',
  styleUrls: ['./kiew-mae-pan-natural-trail.component.scss']
})
export class KiewMaePanNaturalTrailComponent implements OnInit {

  constructor() { }

  setTourcode(){
    sessionStorage.setItem("Tour_code", "TC-S01");
  }

  ngOnInit(): void {
    var rellaxHeader = new Rellax('.rellax-header');
  }

}
