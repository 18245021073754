<!-- Sidebar -->
<section class="bg-content desktop">
    <div class="container">
        <div class="footer-content">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 br-footer-content">
                    <div class="reward-icon">
                        <div class="reward-content">
                            <a href="https://www.tripadvisor.com/Attraction_Review-g293917-d5213271-Reviews-Touring_Center-Chiang_Mai.html" target="blank"><img class="logo-tripadviser" src="./assets/images/footer/png-icon/tripadvisor.png"></a>
                            <img class="logo-ltg" src="./assets/images/footer/png-icon/luxury-travel-guide1.png">
                        </div>
                    </div>
                </div>
                <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-3 br-footer-content">
                    <p class="cursor-menu title-menu"><b>About us</b></p>
                    <span class="pointer" [routerLink]="['/about']" (click)="setActiveMenu('about')"><i class="fa fa-caret-right" aria-hidden="true"></i> <span class="footer-menu"> Who we are</span></span><br>
                    <span class="pointer" [routerLink]="['/activities']" (click)="setActiveMenu('activities')"><i class="fa fa-caret-right" aria-hidden="true"></i> <span class="footer-menu"> Activities</span></span><br>
                    <span class="pointer" [routerLink]="['/partners']" (click)="setActiveMenu('partners')"><i class="fa fa-caret-right" aria-hidden="true"></i> <span class="footer-menu"> Partners</span></span><br>
                    <br/>
                    <p class="cursor-menu title-menu"><b>Other</b></p>
                    <span class="pointer" [routerLink]="['/privacy-policy']"><i class="fa fa-caret-right" aria-hidden="true"></i> <span class="footer-menu"> Privacy Policy</span></span><br>
                    <span class="pointer" [routerLink]="['/terms-and-conditions']"><i class="fa fa-caret-right" aria-hidden="true"></i> <span class="footer-menu"> Terms and Conditions</span></span><br>
                </div>
                <div class="col-6 col-sm-6 col-md-3 col-lg-4 col-xl-4 br-footer-content some-class">
                    <p class="cursor-menu"><b>Contact</b></p>
                    <span class="cursor-menu font-contact">License No. 24/00765</span><br>
                    <span class="cursor-menu font-contact"> 14, Soi 5, Ratchadamnoen Road, Sriphum, Muang, Chiang Mai 50200 Thailand</span><br>
                    <!-- <span class="cursor-menu font-contact"> 13-14 (2nd Floor), Ratchadamnoen Plaza, Ratchadamnoen Road, Sriphum, Muang, Chiang Mai 50200 Thailand</span><br> -->
                    <span style="color:white;"><span class="cursor-menu font-contact pointer"> Mobile : <a href="tel:+66882585817" style="color:white;">+66 (0) 88 258 5817</a></span></span><br>
                    <span style="color:white;"><span class="cursor-menu font-contact pointer"> Tel : <a href="tel:+6653289644" style="color:white;">+66 (0) 53 289 644</a></span></span><br>
                    <span style="color:white"><span class="cursor-menu font-contact pointer"> Email : <a href="mailto:reservations@touringcenter.com" style="color:white;">reservations@touringcenter.com</a></span></span>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Sign UP -->
<section class="bg-content">
    <div class="container">
        <div class="tc-subscribe">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 signupText">
                    <a><span class="text-sign">Sign up</span><span class="text-sign-content"> for our newsletter and discover travel experiences you’ll really want to try.</span></a>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="form form-subscribe">
                        <div class="input-group">
                            <input id="email" type="text" [(ngModel)]="information.email" class="form-control subscribe" name="email" placeholder="@YOUR EMAIL">
                            <div class="btn-subsribe pointer" (click)="callSubscribe()">
                                <span class="btn-text-subsribe pointer" style="height: 5vh;">Subscribe</span>
                            </div>
                        </div>
                        <span *ngIf="message_subscribe_email == true" style="color: white; float: left;"> *Please enter a valid email</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Logo & Social -->
<section class="border-line bg-content">
    <div class="container">
        <div class="credit-content">
            <div class="row">
                <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 tc-font">
                    <!-- <a [routerLink]="['/']"><img class="tc-logo" src="../../assets/images/TC/LOGO-MADAME-WHITE.png"></a> -->
                    <a [routerLink]="['/']"><img class="tc-logo" src="../../assets/images/logo/LOGO-MADAME-WHITE.png"></a>
                    <!-- <span class="tc-text font_gothic"><b><span class="tc-main-text">T</span>ouring <span class="tc-main-text">C</span>enter</b></span> -->
                </div>
                <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <span style="color: white; visibility: hidden;">Link | Privacy Policy | Cookie Policy | Terms of Use</span>
                </div>
                <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <div class="footer-icon">
                        <a class="footer-icon-size facebook-icon" href="https://www.facebook.com/toursinchiangmai" target="_blank"></a>
                        <a class="footer-icon-size youtube-icon" href="https://www.youtube.com/channel/UCqx6cadulg7se3n-991yDrA" target="_blank"></a>
                        <!-- <a class="footer-icon-size twitter-icon" href="https://twitter.com/chiangmaisights" target="_blank"></a> -->
                        <a class="footer-icon-size line-icon" href="https://line.me/ti/p/~touringcnx.com" target="_blank"></a>
                        <a class="footer-icon-size whatsapp-icon" href="https://wa.me/66882585817" target="_blank"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Copy right -->
<section class="bg-copyright">
    <span>Copyright © {{ year_now }} Touring Center . All Rights Reserved.</span>
</section>
