import { Component, OnInit, HostListener } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  navbar_variable = false;
  tc_variable = false;
  menu_variable = false;

  activeMenu: string = "home";
  navbar_active_home = false;
  navbar_active_about = false;
  navbar_active_tours = false;
  navbar_active_activities = false;
  navbar_active_contact = false;
  navbar_active_promotions = false;

  facebookLive: boolean = false;

  href = <any>"";
  tourcode = <any>"";

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
    router.events.subscribe((val) => {
      this.setActivePage();
      this.checkBackgroupMenu();
   });
  }

  ngOnInit(): void {
    this.tourcode = this.route.snapshot.paramMap.get("id");
  }

  @HostListener("document:scroll")
  scrollfunction() {
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      this.navbar_variable = true;
      this.tc_variable = true;
      this.menu_variable = true;
    } else {
      this.checkBackgroupMenu()
    }
  }

  checkBackgroupMenu() {
    const url = this.router.url;
    if (
      url == '/' ||
      url.includes('home') ||
      url.includes('about') ||
      url.includes('tours') ||
      url.includes('promotions') ||
      url.includes('events') ||
      url.includes('activities')
    ) {
      this.navbar_variable = false;
      this.tc_variable = false;
      this.menu_variable = false;
    } else {
      this.navbar_variable = true;
      this.tc_variable = true;
      this.menu_variable = true;
    }
  }

  setActivePage(menu: string = ''): void {
    if (menu) {
      sessionStorage.setItem("active_page", menu);
    }

    const url = this.router.url;
    if (
      url.includes('tour') ||
      url.includes('tours') ||
      url === '/booking' ||
      url.includes('/payment') ||
      url.includes('/receipt')
    ) {
      sessionStorage.setItem("active_page", 'tours');
    } else {
      const url = this.router.url;
      const splitUrl = url.split('/');
      let activeMenu = splitUrl[1];
      sessionStorage.setItem("active_page", activeMenu);
    }

    if (url.includes('event')) {
      sessionStorage.setItem("active_page", 'events');
    }

    this.checkBackgroupMenu()
  }

  getActiveMenu() {
    const activeMenu = sessionStorage.getItem("active_page")
    return activeMenu
  }
}
