import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { SafeResourceUrl, DomSanitizer } from "@angular/platform-browser";
// import { ApiServiceService } from "./../../services/api-service.service";
import { EnvService } from "src/app/services/env.service";
import { ReceiptService } from "src/app/services/receipt.service";

@Component({
  selector: 'app-receipt-event',
  templateUrl: './receipt-event.component.html',
  styleUrls: ['./receipt-event.component.scss']
})
export class ReceiptEventComponent implements OnInit {
  public configs: any = {};
  
  public id: any = "";
  public setTransactionId = <any>"";
  public ReceiptPDF = <any>"";
  public is_show_pdf: boolean = true;

  public pdfPath: string = '';
  public pdfSrc: SafeResourceUrl;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    // private apiService: ApiServiceService,
    private sanitizer: DomSanitizer,
    private envService: EnvService,
    private receiptService: ReceiptService,
  ) {
    let config = this.envService.getConfig();
    this.configs = config;
    this.pdfPath = config?.SERVER_URL + "PDF/receipt_event/";
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get("id");
    if (this.id) {
      this.callGetReceipt();  
    }
  }

  callGetReceipt(): void {
    const payload = {
      transaction_id: this.id,
      is_event: true
    }

    this.receiptService.getReceipt(payload).subscribe(
      (res: any) => {
        if (res) {
          // console.log("get Receipt : ", res);
          this.ReceiptPDF = res;
          
          this.pdfPath = this.pdfPath + this.ReceiptPDF.pdf;
          this.setUrl();

          this.is_show_pdf = true;
        }
      }
    )
  }
  
  PDFDownload() {
    window.open(this.pdfPath, "_blank");
  }

  onClick(name: string) {
    if (name == "HOME") {
      if(confirm("Please confirm to home page.")){
        this.router.navigate(["/"]);
      }
    }
  }

  setUrl() {
    this.cleanup();
    setTimeout(() => {
      this.pdfSrc = this.bypassAndSanitize(this.pdfPath);
      // console.log("pdfSrc : ", this.pdfSrc);
    }, 50);
  }

  cleanup() {
    this.pdfSrc = null;
  }

  bypassAndSanitize(url): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
