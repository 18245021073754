<div class="container pt-5 mt-5">
  <!-- EN -->
  <ng-container *ngIf="language === 'en'">
    <div class="shadow p-3 mb-5 bg-white rounded">
      <div class="modal-header tab-term-bg">
        <span class="modal-title tab-term-font">Privacy Policy for Customer</span>
      </div>
      <div class="modal-body">
        <app-privacy-policy-en></app-privacy-policy-en>
      </div>
    </div>
  </ng-container>

  <!-- TH -->
  <ng-container *ngIf="language === 'th'">
    <div class="shadow p-3 mb-5 bg-white rounded">
      <div class="modal-header tab-term-bg">
        <span class="modal-title tab-term-font">นโยบายความเป็นส่วนตัวสำหรับลูกค้า</span>
      </div>
      <div class="modal-body">
        <app-privacy-policy-th></app-privacy-policy-th>
      </div>
    </div>
  </ng-container>
</div>