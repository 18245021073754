import { Component, OnInit, TemplateRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { OwlOptions } from "ngx-owl-carousel-o";
import Swal from "sweetalert2";
import { Meta, Title } from "@angular/platform-browser";
import { ApiServiceService } from "./../../../services/api-service.service";
import { EnvService } from "src/app/services/env.service";
declare var $: any;

@Component({
  selector: "app-tour",
  templateUrl: "./tour.component.html",
  styleUrls: ["./tour.component.scss"],
})
export class TourComponent implements OnInit {
  public configs: any = {};
  public tourcode = <any>"";
  public DataTour = <any>"";

  public date_now = new Date();
  public year_now = this.date_now.getFullYear();

  Tour_ = <any>"";
  Tours = <any>"";
  Tour: any = {
    itinerary: [],
    includes: [],
    excludes: [],
  };
  Tours_List = <any>"";
  Tours_List_ = <any>"";
  iitinerary: Array<any> = [];
  IninearyDetail: Array<any> = [];
  ImagesTour = <any>"";
  showgetCode: boolean = false;
  checkCode_: boolean = false;
  textvaildateCode = <any>"";
  modalRef: BsModalRef;
  public imgCoverPath = <any>"";

  public fullname = <any>"";
  public email = <any>"";
  public message = <any>"";
  public checksendEmail = <any>"";
  public check_email: boolean = <any>"";
  public check_message: boolean = <any>"";
  public check_fullname: boolean = <any>"";

  reload: boolean = false;
  imageUrl = <any>"";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private titleService: Title,
    private metaTagService: Meta,
    private apiService: ApiServiceService,
    private envService: EnvService
  ) {
    let config = this.envService.getConfig();
    this.configs = config;
  }

  // <<< Initial >>>
  ngOnInit(): void {
    this.tourcode = this.route.snapshot.paramMap.get("id");
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.callGetTour();
    this.callGetAllTours();
    this.clickEvent();

    this.imgCoverPath =
      `${this.configs.SERVER_URL}` + "images/cover-size.jpg";
  }

  // <<< -----------------------------------------
  // ------------------- Call API ----------------
  // ----------------------------------------- >>>
  // get tour
  callGetTour(): void {
    const url = "Website/Tour/GetTours";
    let setPayload = {
      TourCode: this.tourcode,
    };

    this.apiService
      .callPost(url, setPayload)
      .toPromise()
      .then(
        (result) => [(this.Tour_ = result)],
        (err) => [console.log(err)]
      )
      .then(() => {
        this.setTourShow();
      });
  }
  // get all tours
  callGetAllTours(): void {
    const url = "Website/Tour/GetAllTours";
    let setPayload = {};

    this.apiService
      .callPost(url, setPayload)
      .toPromise()
      .then(
        (result) => [
          (this.Tours = result.data),
          (this.Tours_List = this.shuffle(this.Tours)),
        ],
        (err) => [console.log(err)]
      );
  }
  // send email message
  callSendEmailMessage() {
    this.reload = true;

    const url = "Website/Email/SendEmailContact";
    let setPayload = {
      Fullname: this.fullname,
      Email: this.email,
      Message: "Tour : " + this.Tour.title + " | " + this.message,
    };

    this.apiService
      .callPost(url, setPayload)
      .toPromise()
      .then(
        (result) => [
          (this.checksendEmail = result),
        ],
        (err) => [console.log(err)]
      ).then(() => {
        Swal.fire({
          icon: "success",
          text: "Thanks you, We will contact you soon.",
          showConfirmButton: false,
          timer: 1500,
        });
        this.reload = false;
      });
  }

  // <<< -----------------------------------------
  // ------------------- On event ----------------
  // ----------------------------------------- >>>
  // click slide
  clickEvent() {
    $(".btn-left").click(function () {
      $(".l-click").click();
    });
    $(".btn-right").click(function () {
      $(".r-click").click();
    });
  }

  // <<< -----------------------------------------
  // ------------------- Set data ----------------
  // ----------------------------------------- >>>
  customOptions: OwlOptions = {
    loop: true,
    rtl: false,
    mouseDrag: false,
    touchDrag: true,
    freeDrag: true,
    pullDrag: true,
    dots: true,
    animateIn: true,
    animateOut: true,
    dragEndSpeed: 1,
    lazyLoad: true,
    smartSpeed: 200,
    navSpeed: 700,
    navText: [
      '<img class="l-click" src="../../assets/images/home/png/5526019091530273505-128.png">',
      '<img class="r-click" src="../../assets/images/home/png/17780639371530273506-128.png">',
    ],
    rewind: true,
    stagePadding: 2,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 3,
      },
      740: {
        items: 4,
      },
      940: {
        items: 4,
      },
    },
    nav: false,
  };

  // <<< -----------------------------------------
  // ------------------- Set link ----------------
  // ----------------------------------------- >>>
  routeTour(event) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    this.router
      .navigate(["tour/" + event])
      .then(() => {
        this.tourcode = this.route.snapshot.paramMap.get("id");
        this.setTourShow();
      })
      .then(() => {
        window.scrollTo(0, 0);
      });
    this.callGetAllTours();
  }
  routeBooking() {
    sessionStorage.setItem("Tour_code", this.tourcode);
    this.router.navigate(["booking"]);
  }
  routeTours() {
    sessionStorage.setItem("categories_tours", this.Tour.categories);
  }

  // <<< -----------------------------------------
  // -------------------- Share ------------------
  // ----------------------------------------- >>>
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  showGetCode() {
    //check
    if (this.showgetCode == false) {
      this.showgetCode = true;
    } else {
      this.showgetCode = false;
    }
  }
  checkCode() {
    //not Have Promotion Code
    this.checkCode_ = true;
    this.textvaildateCode = "The gift code you've entered isn't valid.";
  }
  onPrint() {
    window.print();
  }
  OpenPDF() {
    window.open(this.Tour.document, "_blank");
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "gray modal-lg" })
    );
  }

  // <<< -----------------------------------------
  // ------------------- Shuffle -----------------
  // ----------------------------------------- >>>
  shuffle(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }
  setTourShow() {
    this.Tour = this.Tour_.data;

    // this.ImagesTour = this.Tour.images;
    // this.imageUrl = `${ROUTE_PATH.BASE_WEBSITE.ROUTE}` + this.Tour.image;
    this.metaTagUpdate(); // set TagMeta
  }

  // <<< -----------------------------------------
  // ------------------ Validation ---------------
  // ----------------------------------------- >>>
  checkValueInfo() {
    if (this.fullname !== "" && this.email !== "" && this.message !== "") {
      this.callSendEmailMessage();
    }
    if (
      this.fullname == "" ||
      this.fullname == null ||
      this.fullname == undefined
    ) {
      this.check_fullname = true;
    } else {
      this.check_fullname = false;
    }
    if (this.email == "" || this.email == null || this.email == undefined) {
      this.check_email = true;
    } else {
      this.check_email = false;
    }
    if (
      this.message == "" ||
      this.message == null ||
      this.message == undefined
    ) {
      this.check_message = true;
    } else {
      this.check_message = false;
    }
  }

  // <<< -----------------------------------------
  // ------------------- Meta Tag ----------------
  // ----------------------------------------- >>>
  metaTagUpdate() {
    this.titleService.setTitle(this.Tour.tourname);
    this.metaTagService.updateTag({
      name: "title",
      property: "og:title",
      content: this.Tour.tour + " : " + this.Tour.concept,
    });
    this.metaTagService.updateTag({
      name: "description",
      property: "og:description",
      content: this.Tour.description,
    });
    this.metaTagService.updateTag({
      name: "keywords",
      content: this.Tour.keywords,
    });
    this.metaTagService.updateTag({
      name: "image",
      property: "og:image",
      content: this.Tour.image_cover,
    });
    this.metaTagService.updateTag({
      name: "url",
      property: "og:url",
      content: `${this.configs.WEBSITE_URL}` + "tour/" + this.Tour.title,
    });
  }

  toggleTermDialog(event: boolean = false): void {
    if (event) {
      this.modalRef.hide()
    }
  }
}
