<!-- <app-header></app-header> -->
<!-- ------- Cover Images kiew mae pan natural trail ---------- -->
<section class="py-5 module parallax parallax-1 rellax-header" data-rellax-speed="2">
  <div class="centered_">
    <span class="tittle-page" >Kew Mae Pan Natural Trail</span><br><br>
    <div class="day-trip-content">
      <span class="day-trip"><b>Day Trip</b></span>
    </div>  
   </div>
   <div class="centered__">
     <span class="tittle-privacy"><b>Full Day</b></span>
   </div>
</section>
<div class="theme-card-social">
  <ul class="social">
    <li class="list-social"><a class="social-list" href="https://www.facebook.com/sharer.php" target="blank"><span class="icon-facebook icon-size-social"><i class="fa fa-facebook-square" aria-hidden="true"></i></span></a></li>
    <li class="list-social"><a class="social-list" href="https://plus.google.com/share" target="blank"><span class="icon-google icon-size-social"><i class="fa fa-google-plus-square" aria-hidden="true"></i></span></a></li>
    <li class="list-social"><a class="social-list" href="https://twitter.com/share" target="blank"><span class="icon-twitter icon-size-social"><i class="fa fa-twitter-square" aria-hidden="true"></i></span></a></li>
  </ul>
</div>
<!-- ------- Touring Center Booking ---------- -->
<section>
  <div class="container-fluid">
      <div class="blog-content-booking">
          <div class="blog-booking box-shadow">
              <!-- <label class="btn-book-content" ><sapn class="tittle-book"><b><i class="fa fa-calendar" aria-hidden="true"></i> BOOK</b></sapn></label> -->
                <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <label><span>Join Price </span>(per person)</label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <label><span>Adult<br>Child</span></label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <label><span>5,000<br>6,000</span></label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -->
                <table style="width: 100%;">
                    <td style="width: 25%; padding-top: 20px;" ><a><span class="btn-book-content"><sapn class="tittel-book"><b><i class="fa fa-calendar" aria-hidden="true"></i> BOOK</b></sapn></span></a></td>
                    <td style="line-height: 13px; width: 13%; font-size: 2rem;"><a><span>Join Price <br><span style="font-size: 0.5rem;">(per person)</span></span></a></td>
                    <td style="line-height: 25px; width: 5%; font-size: 1.3rem;"><a><span>Adult <br> Child</span></a></td>
                    <td style="width: 20%; font-size: 1.3rem;"><a><span>3,000 THB <br> 1,500 THB</span></a></td>
                    <td style="line-height: 13px; width: 13%; font-size: 2rem;"><a><span>Private Price <br><span style="font-size: 0.5rem;">(per person)</span></span></a></td>
                    <td style="width: 5%; font-size: 1.3rem;"><a><span>Adult <br> Child</span></a></td>
                    <td style="width: 8%; font-size: 1.3rem;"><a><span>5,000 THB<br> 2,500 THB</span> </a></td>
                    <td style="font-size: 0.8rem;"><a class="round" popover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." popoverTitle="Popover on bottom" placement="bottom" [outsideClick]="true"><i class="fa fa-chevron-down" aria-hidden="true"></i></a></td>
                </table>
          </div>
      </div>
  </div>
</section>
<!-- ------- Touring Center Description ---------- -->
<section>
  <div class="container-fluid" style="padding: 0;">
    <div class="img-tours">
      <div class="box-text">
          <span class="detail"><b>Description</b></span><br><br>
          <div class="detail-align">
          <span class="sub-detail">
            Depart to Chiang Rai in the early morning and make a short break at 
            Mae Khachan Hot Spring, located in the scenic surrounding of the sp 
            ectacular hills and in the shelter of the large shady tree. Then,vi 
            sit Wat Rong Khun (known as White Temple; built from the imagination 
            and believed) and continue the trip to Golden Triangle Area where the 
            Ancient City of Chiang Saen located on (visit Wat Chedi Luang and su 
            rrounded area). Enjoy the panoramic view of Thailand,Laos and Myanmar 
            border from the view point and board on the Long Tailed Boat to explo 
            re MeKhong River. Visit the Private Opium Museum with story and display  
            about the opium trade in the past.
          </span>
          </div>
     </div>
    </div>
   </div> 
</section>
<!-- ------- Touring Center Description (TIME TRAVEL) ---------- -->
<section>
  <div class="container-fluid" style="padding: 0;">
    <div class="img-tours-2">
      <div class="box-text-2">
        <span class="detail-2"><b>Itinerary</b></span><br><br>
         <div class="boxes-2">
          <div class="detail-align">
          <span class="sub-detail-2">
            <table>
              <tr>
                <td>08.00 a.m. / 01.00 p.m.	</td>
                <td>&nbsp;&nbsp;Start to pick up from hotels</td>
              </tr>
              <tr>
                <td></td>
                <td>&nbsp;&nbsp;Depart for visiting Doi Suthep Temple</td>
              </tr>
              <tr>
                <td></td>
                <td>&nbsp;&nbsp;Sightseeing the Hmong Hill Tribe Village</td>
              </tr>
              <tr>
                <td>Noon / 05.00 p.m.</td>
                <td>&nbsp;&nbsp;Transfer back and drop at hotels</td>
              </tr>
            </table><br><br>
            <span class="detail-2"><b>Tour Time</b></span><br><br>
              <table>
                <tr>
                  <td>Morning: 08.00 a.m. – Noon</td>
                  <td>&nbsp;&nbsp;Standby for pick up at 08.00 a.m. at hotel lobby</td>
                </tr>
                <tr>
                  <td>Afternoon: 01.00 p.m. - 05.00 p.m.</td>
                  <td>&nbsp;&nbsp;Standby for pick up at 01.00 p.m. at hotel lobby</td>
                </tr>
              </table>
          </span>
        </div>
       </div>
     </div>
    </div>
   </div> 
</section>
<!-- ------- Touring Center Carousel (Images)---------- -->
<section>
  <div class="container-fluid carousel-slide">
    <span class="detail-2"><b>Tour Picture</b></span><br><br><br>
    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="https://mdbootstrap.com/img/Photos/Slides/img%20(68).jpg" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
          <img src="https://mdbootstrap.com/img/Photos/Slides/img%20(6).jpg" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
          <img src="https://mdbootstrap.com/img/Photos/Slides/img%20(9).jpg" class="d-block w-100" alt="...">
        </div>
      </div>
    </div>
    <div class="btn-prv">
      <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
        <i class="fa fa-chevron-left" style="font-size: 2.5rem; padding-left: 2.5rem;"  aria-hidden="true"></i>
        <span class="sr-only">Previous</span>
      </a>
     </div>
     <div class="btn-next">
      <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
        <i class="fa fa-chevron-right" style="font-size: 2.5rem; padding-right: 2.5rem;" aria-hidden="true"></i>

        <span class="sr-only">Next</span>
      </a>
     </div>
 </div>
</section>
<!-- ---------------------------------------------------------- -->
<section>
  <div class="container">
      <div class=""></div>
  </div>
</section>
<!-- ---------------------------------------------------------- -->
<section>
  <div class="container">
    <div class="tc-mean">
        <h2>Touring Center</h2>
        <br>
        <p class="text-justify"><span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          Touring Center (since 2004) is one of Tour Operator in Chiang Mai 
          providing a Day Tour and Package in Chiang Mai and the northern part area of Thailand 
          (especially Chiang Rai and Mae Hong Son). As a local tour operator, we are understands 
          the variety of its context & content (life, culture, beats & rhythms and environment surrounded). 
          Our tours are based on English Speaking Market as a main market (other language also available upon 
          requested such as French, German, Chinese, etc.)
        </span></p>
        <a mat-raised-button color="primary" (click)="setTourcode()" [routerLink]="['/booking']" type="button">BOOK</a>
    </div>
  </div>
</section>
<!-- ------- Footter ---------- -->
<!-- <app-footer></app-footer> -->


<!-- <div class="tours-detail">
  <span class="detail"><b>Description</b></span><br>
</div>
<div class="tour-sub-detail">
<span class="sub-detail">
  Depart to Chiang Rai in the early morning and make a short break at <br>
  Mae Khachan Hot Spring, located in the scenic surrounding of the sp- <br>
  ectacular hills and in the shelter of the large shady tree. Then,vi- <br>
  sit Wat Rong Khun (known as White Temple; built from the imagination <br>
  and believed) and continue the trip to Golden Triangle Area where the <br>
  Ancient City of Chiang Saen located on (visit Wat Chedi Luang and su- <br>
  rrounded area). Enjoy the panoramic view of Thailand,Laos and Myanmar <br>
  border from the view point and board on the Long Tailed Boat to explo- <br>
  re MeKhong River. Visit the Private Opium Museum with story and display  <br>
  about the opium trade in the past.
</span>
</div> -->