// import { encrypt, decrypt } from './crypto';

export const setLocalStorage = (name: any, value: any) => {
  // localStorage.setItem(name, encrypt(value));
  localStorage.setItem(name, value);
};

export const getLocalStorage = (name: any) => {
  return localStorage.getItem(name);
};

export const removeLocalStorage = (name: any) => {
  localStorage.removeItem(name);
};
