import { Injectable } from "@angular/core";
import { ApiServiceService } from "./api-service.service";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MasterService {
  private path = "master";
  private pathHotelJSON = "account_code/account_Hotel.json";

  constructor(private service: ApiServiceService) { }

  getNationalities(payload: any = {}): Observable<any> {
    return this.service.callGet(`${this.path}/nationalities`, payload);
  }

  getHotelJSON(url: string = this.pathHotelJSON): Observable<any> {
    return this.service.callGetJSON(`${url}`);
  }

}