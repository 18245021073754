<div class="container">
  <p>Touring Center (Day Trip Co., Ltd.) recognizes the importance of the protection of your personal data. This Privacy Policy explains our practices regarding the collection, use or disclosure of personal data including other rights of the Data Subjects in accordance with the Personal Data Protection Laws.</p>
  <br/>

  <div class="content">
    <label><b>Collection of Personal Data</b></label>
    <p>We will collect your personal data that receive directly from you as following:</p>
    <ul>
      <li>your account registration</li>
      <li>telephone</li>
      <li>email address</li>
      <li>Facebook Login</li>
      <li>Google Login</li>
      <li>LINE Login</li>
      <li>Twitter Login</li>
      <li>LinkedIn Login</li>
    </ul>
    <p>We may collect your personal data that we can access from the other source not directly from you such as search engines, social media, government authorities, third parties, etc.</p>
  </div>
  <br/>

  <div class="content">
    <label><b>Types of Data Collected</b></label>
    <p>
      <b>Personal data </b>
      such as name, surname, age, date of birth, nationality, identification card, passport, etc.
    </p>
    <p>
      <b>Contact information </b>
      such as address, telephone number, e-mail address, etc.
    </p>
    <p>
      <b>Account details </b>
      such as username, password, transactions history, etc.
    </p>
    <p>
      <b>Proof of identity </b>
      such as copy of identification card, copy of passport, etc.
    </p>
    <!-- <p>
      <b>Transaction and Financial information </b>
      such as purchase history, credit card details, bank account, etc.
    </p> -->
    <p>
      <b>Technical data </b>
      such as IP address, Cookie ID, Activity Log, etc.
    </p>
    <p>
      <b>Other </b>
      such as photo, video, and other information that is considered personal data under the Personal Data Protection Laws. We may collect, use or disclose your sensitive data that is specially categorized by law when we have obtained explicit consent from you or where necessary for us as permissible under law. We may collect, use or disclose your sensitive personal data as following:
      <ul>
        <li>racial</li>
        <li>disability</li>
      </ul>
      Any data which may affect the data subject in the same manner, as prescribed by the Personal Data Protection Committee.
  </div>
  <br/>

  <div class="content">
    <label><b>Children</b></label>
    <p>If you are under the age of 20 or having legal restrictions, we may collect use or disclose your personal data. We require your parents or guardian to be aware and provide consent to us or allowed by applicable laws. If we become aware that we have collected personal data from children without verification of parental consent, we take steps to remove that information from our servers.</p>
  </div>
  <br/>

  <div class="content">
    <label><b>Storage of Data</b></label>
    <p>We store your personal data as hard copy and soft copy.</p>
    <p>We store your personal data by using the following systems:</p>
    <ul><li>Our server outside of Thailand</li></ul>
  </div>
  <br/>

  <div class="content">
    <label><b>Use of Data</b></label>
    <p>We use the collected data for various purposes: </p>
    <ul>
      <li>To create and manage accounts</li>
      <li>To provide products or services</li>
      <li>To improve products, services, or user experiences</li>
      <li>To share and manage information within organization</li>
      <li>To conduct marketing activities and promotions</li>
      <li>To provide after-sales services</li>
      <li>To gather user’s feedback</li>
      <li>To process payments of products or services</li>
      <li>To comply with our Terms and Conditions</li>
      <li>To comply with laws, rules, and regulatory authorities</li>
    </ul>
  </div>
  <br/>

  <div class="content">
    <label><b>Disclosure of Personal Data</b></label>
    <p>We may disclose your personal data to the following parties in certain circumstances:</p>
  </div>
  <br/>

  <div class="content">
    <label><b>Organization</b></label>
    <p>We may disclose your personal data within our organization to provide and develop our products or services. We may combine information internally across the different products or services covered by this Privacy Policy to help us be more relevant and useful to you and others.</p>
  </div>
  <br/>

  <div class="content">
    <label><b>Business Partners</b></label>
    <p>In relation with our business partners, we may disclose certain personal data to them in order to coordinate and provide our products or services to you and provide necessary information about the availability of our products or services.</p>
  </div>
  <br/>

  <div class="content">
    <label><b>Law Enforcement</b></label>
    <p>Under certain circumstances, we may be required to disclose your personal data if required to do so by law or in response to valid requests by government authority such as courts, government authorities.</p>
    <p>With respect to transfers originating from the European Economic Area (EEA) to the United States and other non-EEA jurisdictions, we will have appropriate solutions to address cross-border transfers as required or permitted by the General Data Protection Regulation.</p>
  </div>
  <br/>

  <div class="content">
    <label><b>Data Retention</b></label>
    <p>We will retain your personal data for as long as necessary during the period you are a customer or under relationship with us, or for as long as necessary in connection with the purposes set out in this Privacy Policy, unless law requires or permits a longer retention period. We will erase, destroy or anonymize your personal data when it is no longer necessary or when the period lapses.</p>
  </div>
  <br/>

  <div class="content">
    <label><b>Data Subject Rights</b></label>
    <p>Subject to the Personal Data Protection Laws thereof, you may exercise any of these rights in the following:</p>
  </div>
  <br/>

  <div class="content">
    <label><b>Withdrawal of consent: </b></label>
    If you have given consent to us to collect, use or disclose your personal data whether before or after the effective date of the Personal Data Protection Laws, you have the right to withdraw such consent at any time throughout the period your personal data available to us, unless it is restricted by laws or you are still under beneficial contract.
  </div>
  <br/>

  <div class="content">
    <label><b>Data access: </b></label>
    You have the right to access your personal data that is under our responsibility; to request us to make a copy of such data for you; and to request us to reveal as to how we obtain your personal data.
  </div>
  <br/>

  <div class="content">
    <label><b>Data portability: </b></label>
    You have the right to obtain your personal data if we organize such personal data in automatic machine-readable or usable format and can be processed or disclosed by automatic means; to request us to send or transfer the personal data in such format directly to other data controllers if doable by automatic means; and to request to obtain the personal data in such format sent or transferred by us directly to other data controller unless not technically feasible.
  </div>
  <br/>

  <div class="content">
    <label><b>Objection: </b></label>
    You have the right to object to collection, use or disclosure of your personal data at any time if such doing is conducted for legitimate interests of us, corporation or individual which is within your reasonable expectation; or for carrying out public tasks.
  </div>
  <br/>

  <div class="content">
    <label><b>Data erasure or destruction: </b></label>
    You have the right to request us to erase, destroy or anonymize your personal data if you believe that the collection, use or disclosure of your personal data is against relevant laws; or retention of the data by us is no longer necessary in connection with related purposes under this Privacy Policy; or when you request to withdraw your consent or to object to the processing as earlier described.
  </div>
  <br/>

  <div class="content">
    <label><b>Suspension: </b></label>
    You have the right to request us to suspend processing your personal data during the period where we examine your rectification or objection request; or when it is no longer necessary and we must erase or destroy your personal data pursuant to relevant laws but you instead request us to suspend the processing.
  </div>
  <br/>

  <div class="content">
    <label><b>Rectification: </b></label>
    You have the right to rectify your personal data to be updated, complete and not misleading.
  </div>
  <br/>

  <div class="content">
    <label><b>Complaint lodging: </b></label>
    You have the right to complain to competent authorities pursuant to relevant laws if you believe that the collection, use or disclosure of your personal data is violating or not in compliance with relevant laws.
    <p>If you are in the European Economic Area (EEA) you have the rights as mentioned above and also you have the right to lodge a complaint with the local data protection authority if you believe that we have not complied with applicable Personal Data Protection laws.</p>
    <p>You can exercise these rights as the Data Subject by contacting our Data Protection Officer as mentioned below. We will notify the result of your request within 30 days upon receipt of such request. If we deny the request, we will inform you of the reason via SMS, email address, telephone, registered mail (if applicable).</p>
  </div>
  <br/>

  <div class="content">
    <label><b>California Consumer Privacy Act (CCPA)</b></label>
    <p>For information about our data processing practices as required by the California Consumer Privacy Act 2018, please view our CCPA Privacy Policy.</p>
  </div>
  <br/>

  <div class="content">
    <label><b>Advertising and Marketing</b></label>
    <p>To enable you to receive benefits from using our products or services, we use your personal data to analyze, personalize and enhance our products or services, and marketing efforts through Google, Facebook, pixel tracking code and others. We use such information to provide you with customized and personalized recommendations for products or services we think will be of interest to you.</p>
    <p>Our website may display advertisements from third parties to facilitate our services such as Google AdSense, BuySellAds. These third parties may access your personal data only to perform these tasks and are obligated not to disclose or use it for any other purpose.</p>
    <p>We do not use automated decision-making without human intervention, including profiling, in a way that produces legal effects concerning you or otherwise significantly affects you.</p>
    <p>We may send certain information or newsletter for the purpose of utilizing your preference via your email. If you no longer want to receive the communications from us, you can click the "unsubscribe" link in the email or contact us through our email.</p>
  </div>
  <br/>

  <div class="content">
    <label><b>Cookies</b></label>
    <p>To enrich and perfect your experience, we use cookies or similar technologies to display personalized content, appropriate advertising and store your preferences on your computer. We use cookies to identify and track visitors, their usage of our website and their website access preferences. If you do not wish to have cookies placed on your computer you should set their browsers to refuse cookies before using our website.</p>
  </div>
  <br/>

  <div class="content">
    <label><b>Data Security</b></label>
    <p>We endeavor to protect your personal data by establishing security measures in accordance with the principles of confidentiality, integrity, and availability to prevent loss, unauthorized or unlawful access, destruction, use, alteration, or disclosure including administrative safeguard, technical safeguard, physical safeguard and access controls.</p>
  </div>
  <br/>

  <div class="content">
    <label><b>Data Breach Notification</b></label>
    <p>We will notify the Office of the Personal Data Protection Committee without delay and, where feasible, within 72 hours after having become aware of it, unless such personal data breach is unlikely to result in a risk to the rights and freedoms of you. If the personal data breach is likely to result in a high risk to the rights and freedoms of you, we will also notify the personal data breach and the remedial measures to you without delay through our website, SMS, email address, telephone or registered mail (if applicable).</p>
  </div>
  <br/>

  <div class="content">
    <label><b>Changes to this Privacy Policy</b></label>
    <p>We may change this Privacy Policy from time to time. Any changes of this Privacy Policy, we encourage you to frequently check on our website. This Privacy Policy was last updated and effective on 24th August 2023</p>
  </div>
  <br/>

  <div class="content">
    <label><b>Links to Other Sites</b></label>
    <p>The purpose of this Privacy Policy is to offer products or services and use of our website. Any websites from other domains found on our site is subject to their privacy policy which is not related to us.</p>
  </div>
  <br/>

  <div class="content">
    <label><b>Contact Information</b></label>
    <p>If you have any questions about this Privacy Policy or would like to exercise your rights, you can contact us by using the following details:</p>
  </div>
  <br/>

  <div class="content">
    <label><b>Data Controller</b></label>
    <p>Touring Center (Day Trip Co., Ltd.)</p>
    <p>31/1 Moonmuang Road, Soi 5, Si Phum, Mueang Chiang Mai, Chiang Mai, 50200</p>
    <p>reservations@touringcnx.com</p>
    <p>www.touringcenter.com</p>
    <p>0882585817</p>
  </div>
  <br/>

  <div class="content">
    <label><b>Data Protection Officer</b></label>
    <p>Touring Center Team</p>
    <p>31/1 Moonmuang Road, Soi 5, Si Phum, Mueang Chiang Mai, Chiang Mai, 50200</p>
    <p>tour@touringcnx.com</p>
    <p>0882585817</p>
  </div>
</div>
<hr>

<div class="container">
  <h2>California Consumer Privacy Act Policy</h2>
  <p>Touring Center (Day Trip Co., Ltd.) takes the privacy and security of your personal information. This Privacy Policy describes our practice on how we collect, use, or disclose personal information relating to California residents under the California Consumer Privacy Act of 2018 (CCPA).</p>

  <div class="content">
    <label><b>Your Rights Under the CCPA</b></label>
    <p>In addition to the rights as explained in the Privacy Policy, California residents has the rights with the following rights:</p>
  </div>
  <br/>

  <div class="content">
    <label><b>Right to Notification</b></label>
    <p>We collect the following categories of personal Information about California residents and disclose to third parties or service providers for the business purposes listed. In the past 12 months, we may have, depending on your interactions with us, collected the following categories of personal information.</p>
  </div>
  <br/>

  <div class="content">
    <label><b>CCPA Personal Information Categories</b></label>
    <p>Identifiers such as name, surname, gender, nationality, identification card, passport, contact information, etc.</p>
    <p>Personal information, as defined in the California safeguards law, such as financial information, credit card details, bank account, etc.</p>
    <p>Protected classifications under California or federal law, such as disability status or other attributes, etc.</p>
    <p>Commercial information such as information regarding products or services purchased, etc.</p>
    <p>Internet or other electronic network activities such as browsing history, search history, etc.</p>
    <p>Geolocation data such as location, etc.</p>
    <p>Audio, electronic, visual, or similar information, such as call and video recordings, etc.</p>
    <p>Professional or employment-related information such as work history, etc.</p>
    <p>Education such as degrees, certificates, etc.</p>
    <p>Other information such as your profile, your preferences, and characteristics, etc.</p>
    <br/>
    <p>Sources of Information such as information you provide directly to us, your interactions with us and information from our business partners or service providers, etc.</p>
    <br/>
    <p>We use personal information for the following business purposes:</p>
    <ul>
      <li>To create and manage accounts</li>
      <li>To provide products or services</li>
      <li>To improve products, services, or user experiences</li>
      <li>To share and manage information within organization</li>
      <li>To conduct marketing activities and promotions</li>
      <li>To provide after-sales services</li>
      <li>To gather user's feedback</li>
      <li>To process payments of products or services</li>
      <li>To comply with our Terms and Conditions</li>
      <li>To comply with laws, rules, and regulatory authorities</li>
    </ul>
  </div>
  <br/>

  <div class="content">
    <label><b>Right to Request for Personal Information</b></label>
    <p>You have the right to request that how we have collected, used, or disclosed your personal information over the past 12 months, including the categories of personal information we collected and our purposes for doing so; the categories of sources for that information; the categories of third parties with whom we disclosed it for a business purpose and our purposes for doing so.</p>
  </div>
  <br/>

  <div class="content">
    <label><b>Right to Request Deletion of Personal Information</b></label>
    <p>You have the right to request the deletion of personal information that we collect or maintain about you. To submit a request to delete personal information, please submit a request to our email address as below. Please specify in your request the personal information about you that you would like to have deleted, which can be all your personal information as required by the CCPA.</p>
  </div>
  <br/>

  <div class="content">
    <label><b>Right to Non-Discrimination</b></label>
    <p>The CCPA prohibits discrimination against California residents for exercising their rights under the CCPA. In this regard, you may not be discriminated against because you exercise any of your rights under the CCPA.</p>
  </div>
  <br/>

  <div class="content">
    <label><b>Right to Opt-Out of the Sale of Personal Information</b></label>
    <p>You have the right to opt-out of the sale of your personal information. We do not sell your personal information including California residents’ personal information.</p>
  </div>
  <br/>

  <div class="content">
    <label><b>Contact Information</b></label>
    <p>If you have any questions about this Privacy Policy or would like to exercise your rights, you can contact us by using the following details:</p>
    <p>Touring Center (Day Trip Co., Ltd.)</p>
    <p>31/1 Moonmuang Road, Soi 5, Si Phum, Mueang Chiang Mai, Chiang Mai, 50200</p>
    <p>reservations@touringcnx.com</p>
    <p>www.touringcenter.com</p>
    <p>0882585817</p>
  </div>
  <br/>
</div>
<hr>