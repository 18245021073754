import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import Swal from "sweetalert2";
import { ApiServiceService } from "./../../services/api-service.service";

@Component({
  selector: "app-update",
  templateUrl: "./update.component.html",
  styleUrls: ["./update.component.scss"],
})
export class UpdateComponent implements OnInit {
  public guest_name: any[] = [];
  public getaccountcode = <any>"";
  public setTransactionId = <any>"";
  public transactionId = <any>"";
  public dataTransaction = <any>"";
  public updateGuest = <any>"";
  public primary_informaiton = {
    fullname: <any>"",
    firstname: <any>"",
    lastname: <any>"",
    email: <any>"",
    phone: <any>"",
    hotel: <any>"",
    hotel_other: <any>"",
  };

  reload: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiServiceService
  ) {}

  // <<< Initial >>>
  ngOnInit(): void {
    this.transactionId = this.route.snapshot.paramMap.get("id");
    this.callGetTransaction();
    this.callJsonGetAccountCode();
  }

  // <<< -----------------------------------------
  // ----------------- Call API Get --------------
  // ----------------------------------------- >>>
  // get transaction
  callGetTransaction(): void {
    const url = "Website/Transaction/GetTransactionById";
    let setPayload = {
      transaction_id: this.transactionId,
    };

    this.apiService
      .callPost(url, setPayload)
      .toPromise()
      .then(
        (result) => [
          (this.dataTransaction = result),
          // console.log(this.dataTransaction),
        ],
        (err) => [console.log(err)]
      )
      .then(() => {
        this.setGuestInformation(this.dataTransaction);
      });
  }
  // <<< -----------------------------------------
  // ------------------- Binding -----------------
  // ----------------------------------------- >>>
  // set guest information
  setGuestInformation(params: any): void {
    params = params ? params : this.dataTransaction;

    //set primary guest
    this.primary_informaiton.fullname = params.fullname;
    this.primary_informaiton.firstname = params.firstname;
    this.primary_informaiton.lastname = params.lastname;
    this.primary_informaiton.email = params.email;
    this.primary_informaiton.phone = params.phone;
    this.primary_informaiton.hotel = params.hotel;

    //set sub pax
    if (params.sub_guest && params.sub_guest.length > 0) {
      for (let i = 0; i <= params.sub_guest.length - 1; i++) {
        if (params.sub_guest) {
          this.guest_name.push({
            id: params.sub_guest[i].id,
            fullname: params.sub_guest[i].fullname,
            email: "",
            phone: "",
          });
        } else {
          this.guest_name.push({
            id: null,
            fullname: "",
            email: "",
            phone: "",
          });
        }
      }

      // check pax and sub_guest
      let sub_guest_valid = params.pax - 1 - params.sub_guest.length;
      if (sub_guest_valid > 0) {
        for (let i = 0; i < sub_guest_valid; i++) {
          this.guest_name.push({
            id: null,
            fullname: "",
            email: "",
            phone: "",
          });
        }
      }
    } else {
      for (let i = 1; i <= params.pax - 1; i++) {
        this.guest_name.push({
          id: null,
          fullname: "",
          email: "",
          phone: "",
        });
      }
    }
  }

  // <<< -----------------------------------------
  // -------------- Call API Update --------------
  // ----------------------------------------- >>>
  // update transaction
  callUpdateTransaction(): void {
    this.reload = true;
    // const url = "Website/update/information/updateinformationGuest";
    const url = "Website/Transaction/UpdateInformationGuest";
    let setPayload = {
      transaction_id: this.transactionId,
      primary_guest: this.primary_informaiton,
      information: this.guest_name,
    };

    this.apiService
      .callPost(url, setPayload)
      .toPromise()
      .then(
        (result) => [
          (this.updateGuest = result),
          // console.log(this.updateGuest),
        ],
        (err) => [console.log(err)]
      )
      .then(() => {
        this.sendLineNotification(this.updateGuest);
        this.checkStatusUpdate(this.updateGuest);
      });
  }
  // check update status
  checkStatusUpdate(params: any): void {
    params = params ? params : this.updateGuest;

    //check status update
    if (params.status == true) {
      Swal.fire({
        icon: "success",
        text: "Update Success.",
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        this.reload = false;
        location.reload();
        // this.back();
      });
    } else {
      this.reload = false;
      Swal.fire({
        icon: "error",
        text: "Please, Contact reservation team. reservations@touringcnx.com",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }

  // <<< -----------------------------------------
  // ------------------- Call JSON ---------------
  // ----------------------------------------- >>>
  // get account code
  callJsonGetAccountCode(): void {
    const url = "account_code/account_Hotel.json";
    this.apiService.callGetJSON(url).subscribe(
      (result) => [(this.getaccountcode = result)],
      (err) => [console.log(err)]
    );
  }

  // <<< -----------------------------------------
  // ------------------- On event ----------------
  // ----------------------------------------- >>>
  // back page
  back() {
    this.router.navigate(["receipt/" + this.transactionId]);
  }

  // <<< -----------------------------------------
  // ---------------- Line notify ----------------
  // ----------------------------------------- >>>
  sendLineNotification(params: any): void {
    // console.log("sendLineNotification", params);

    if (params && params.data) {
      const url = "LineNotify/NotifyUpdateBooking";
      let setPayload = this.updateGuest.data;

      // console.log(url, setPayload);

      this.apiService
        .callPost(url, setPayload)
        .toPromise()
        .then(
          (result) => [
            (this.updateGuest = result),
            // console.log(this.updateGuest),
          ],
          (err) => [console.log(err)]
        );
    }
  }
}
