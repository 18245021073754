<link rel="stylesheet" type="text/css" href="/assets/templates/Login_v18/vendor/select2/select2.min.css" />
<link rel="stylesheet" type="text/css" href="/assets/templates/Login_v18/css/util.css" />
<link rel="stylesheet" type="text/css" href="/assets/templates/Login_v18/css/main.css" />

<!-- Template -->
<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <form class="login100-form validate-form">
        <span class="login100-form-title p-b-43"> LOGIN </span>

        <div
          class="wrap-input100 validate-input"
          data-validate="Valid email is required: ex@abc.xyz"
        >
          <input
            class="input100"
            type="text"
            name="email"
            [(ngModel)]="input.username"
            (keypress)="onKeyPress($event, 'LOGIN')"
          />
          <!-- <span class="focus-input100"></span> -->
          <span class="label-input100" *ngIf="!input.username">Email</span>
        </div>

        <div
          class="wrap-input100 validate-input"
          data-validate="Password is required"
        >
          <input
            class="input100"
            type="password"
            name="pass"
            [(ngModel)]="input.password"
            (keypress)="onKeyPress($event, 'LOGIN')"
          />
          <!-- <span class="focus-input100"></span> -->
          <span class="label-input100" *ngIf="!input.password">Password</span>
        </div>

        <div class="flex-sb-m w-full p-t-3 p-b-32">
          <div class="contact100-form-checkbox">
            <!-- <input
              class="input-checkbox100"
              id="ckb1"
              type="checkbox"
              name="remember-me"
            />
            <label class="label-checkbox100" for="ckb1"> Remember me </label> -->
          </div>

          <div>
            <!-- <a href="/login" class="txt1"> Forgot Password? </a> -->
          </div>
        </div>

        <div class="container-login100-form-btn">
          <button class="login100-form-btn" (click)="onClick('LOGIN')">Login</button>
        </div>

        <div class="text-center p-t-46 p-b-20">
          <!-- <span class="txt2"> or sign up using </span> -->
        </div>

        <div class="login100-form-social flex-c-m">
          <a href="https://www.facebook.com/toursinchiangmai" target="_blank" class="login100-form-social-item flex-c-m bg1 m-r-5">
            <i class="fa fa-facebook-f" aria-hidden="true"></i>
          </a>

          <a href="https://twitter.com/chiangmaisights" target="_blank" class="login100-form-social-item flex-c-m bg2 m-r-5">
            <i class="fa fa-twitter" aria-hidden="true"></i>
          </a>

          <a href="https://www.youtube.com/channel/UCqx6cadulg7se3n-991yDrA" target="_blank" class="login100-form-social-item flex-c-m bg3 m-r-5">
            <i class="fa fa-youtube" aria-hidden="true"></i>
          </a>
        </div>
      </form>

      <div
        class="login100-more"
        style="background-image: url('http://api.tourinchiangmai.com/images/TC-R07/5f98ffd4a8c76-Head---Kew2.jpg');"
      ></div>
    </div>
  </div>
</div>
