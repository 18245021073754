<!-- <app-header-non-fixed></app-header-non-fixed> -->

<section class="body-navbar">
      <div class="container__">

        <div class="form-group langunge">
          <select class="form-control" [(ngModel)]="select_language" (change)="change_languge()"> 
            <option value="TH">TH</option>
            <option value="EN">EN</option>
          </select>
        </div>

          <div class="tag">
              <ul class="ul-tag">
                <li><a [routerLink]="['/']">Home</a></li>
                <li> / </li>
                <li><a>{{service_show.service_type}}</a></li>
                <li> / </li>
                <li><a class="tag-services">{{service_show.service_name}}</a></li>
              </ul>
          </div>
          <div class="title-service">
            <span><b class="font-title-service">{{service_show.service_name}}</b></span><span class="font-code-title"> [{{service_show.service_code}}]</span>
          </div>
      </div>

      <!-- <div class="dropdown langunge">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{service_show.language}}
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a *ngIf="service_show.language == 'TH'" class="dropdown-item" href="#" >EN</a>
          <a *ngIf="service_show.language == 'EN'" class="dropdown-item" href="#" >TH</a>
        </div>
      </div> -->

  

      <!-- --------------- Image Tour ------------------- -->
    <div class="container____ "> 
        <div class="main-img-tour">
            <img class="img-service" [src]="service_show.image">
        </div>
    </div>

     <!-- --------------- service conditions -------------------- -->
  <div class="container___">
    <div class="row flex-column-reverse flex-md-row">
     <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8">
     <div class="conditions mar-t">
      <div class="conditions-title">
        <span  class="content-title">{{services_title_show.conditions}}</span>
      </div>
      <br>
      <div class="content-includes font-1r">
        <div *ngFor="let conditions of service_show.conditions; let i = index">
          <span><i class="fa fa-dot-circle-o" aria-hidden="true"></i>  &nbsp; {{conditions.main}}</span><br>
            <div *ngIf="i == 2"><span *ngFor="let sub of subcondition;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -  &nbsp; {{sub}} <br></span> </div>
        </div>
      </div>
    </div>
    <hr>

    <!-- --------------- service_includes -------------------- -->
      <div class="includes mar-t">
       <div class="includes-title">
         <span  class="content-title">{{services_title_show.service_includes}}</span>
       </div>
       <br>
       <div class="content-includes font-1r">
         <div *ngFor="let includes of service_show.service_includes">
           <span><i class="fa fa-check" aria-hidden="true"></i> {{includes}}</span><br>
         </div>
       </div>
     </div>
     <hr>

     <!-- --------------- service_excludes -------------------- -->
     <div class="excludes mar-t">
      <div class="excludes-title">
        <span  class="content-title">{{services_title_show.service_excludes}}</span>
      </div>
      <br>
      <div class="content-excludes font-1r">
        <div *ngFor="let excludes of service_show.service_excludes">
          <span><i class="fa fa-times" aria-hidden="true"></i> {{excludes}}</span><br>
        </div>
      </div>
    </div>
    <hr>

    <!-- --------------- service_remark -------------------- -->
    <div class="remark mar-t">
      <div class="remark-title">
        <span  class="content-title">{{services_title_show.remark}}</span>
      </div>
      <br>
      <div class="content-remark font-1r">
        <div *ngFor="let remark of service_show.remark">
          <span>-  &nbsp; {{remark}}</span><br>
        </div>
      </div>
    </div>
    <hr>

    </div>
    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <div class="form-book">
                        <div class="blog-book">
                            <div class="blog-price">
                              <span class="card-text-review color-2 font-blog-form">{{services_title_show.from}}</span><br>
                              <div class="col-12 blog-price_">
                                <!-- <span class="card-text-more_ color-2 font-blog-currency"></span> &nbsp;&nbsp;  -->
                                <span class="card-text-more_ color-4 font-blog-price">{{service_show.price | number}}</span> &nbsp; 
                                <span class="per-person_ color-2">{{services_title_show.currency}}</span>
                              </div>
                            </div>
                            <div class="btn-book">
                               <button mat-raised-button class="book-btn" (click)="Servicebook()">{{services_title_show.book}}</button>
                            </div>
                            <br>
                           <hr>
                           <div class="list-guides-book font-1r">
                              <span><i class="fa fa-clock-o" aria-hidden="true"></i> {{services_title_show.booking_period}}</span><br>
                              <span> &nbsp;&nbsp;&nbsp; {{service_show.booking_period}}</span><br>
                              <span><i class="fa fa-clock-o" aria-hidden="true"></i> {{services_title_show.travel_period}}</span><br>
                              <span> &nbsp;&nbsp;&nbsp; {{service_show.travel_period}}</span><br>
                           </div>
                        </div>
            </div>
    </div>
    
  </div>
 </div>

</section>


<!-- ------- Footter ---------- -->
<!-- <app-footer></app-footer> -->