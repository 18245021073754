<!-- <app-header-non-fixed></app-header-non-fixed> -->

<!-- -------- Form Booking -------- -->
<div class="container body-navbar">
  <div class="form-booking">
    <span class="font-3r color-red-pastel"><b>BOOKING</b></span>
    <hr class="hr-1"><br>
    <div class="row">
      <!-- ------------ Booking --------------- -->
        <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
            <div class="shadow p-3 mb-5 bg-white rounded">
                <div class="form-booking-content">
                  <div class="bg-tiitle-form">
                    <div class="tittle-booking">
                  <!-- ------ Tour Code & Tour Tittle ------ -->
                    <div class="tour-code">
                        <table>
                          <tr>
                            <td class="td-1"><span class="text-tittle">SERVICE</span></td>
                            <td class="td-1">:</td>
                            <td><span class="text-tittle_"><b style="color:#ff6961">{{getTourPrice_.tour_title}}</b></span></td>
                          </tr>
                        </table>
                    </div>
                  </div>
                  </div>
                  <br>
                  <br>
                  <!-- --------- Select Count of Vans ---------- -->
                  <!-- -------- Participants ------- -->
              <div class="border-form">
                <div class="participants-content">
                  <div class="blog-price">
                    <div class="review col-6">
                      <span class="participants-ages  text-participants-size"><b>Vans</b></span>
                    </div>
                    <div class="price col-6">
                      <ngx-number-spinner class="participants-pax" [(value)]="information.vans" (change)="change_vans($event)" [step]="1" [min]="minvans" [max]="maxvans"></ngx-number-spinner>
                    </div>
                  </div><hr>
                  <div class="blog-price">
                    <div class="review col-6">
                      <span class="participants-ages  text-participants-size"><b>Guests</b> &nbsp;  </span>   
                      <!-- --------- <i class="fa fa-info-circle" aria-hidden="true" style="color:#ff6961"></i> ---------- -->
                    </div>
                    <div class="price col-6">
                      <ngx-number-spinner class="participants-pax" [(value)]="information.guests" (change)="change_guests($event)" [step]="1" [min]="minguests" [max]="maxguests"></ngx-number-spinner>
                    </div>
                  </div>
                </div>
              </div>
              <br>

              <div class="border-form">
                  <!-- -------- Travel Date & Pick up Time ------- -->
                    <div class="date-travel col-12">
                        <span class="tittle-content"><b>Travel Date : </b></span>
                        <input type="text" [(bsValue)]="bsInlineValue" [bsConfig]="{showWeekNumbers:false,containerClass:'theme-red',dateInputFormat: 'DD/MM/YYYY',isAnimated: true}" placeholder="Datepicker" [minDate]="bsInlineMinDate" [maxDate]="bsInlineMaxDate" class="form-control" bsDatepicker>
                        <hr>
                        <span class="tittle-content pk-title"><b>Pick up Time : </b></span>
                        <timepicker [(ngModel)]="pickup_time" [min]="minTime" [max]="maxTime"></timepicker>
                    </div>
                </div>
                <br>
                
                <!-- ----------- select guide or no guide ----------- -->
                <div class="example-section">
                  <label class="example-margin title-guides"><b>Guides : </b></label>
                  <mat-radio-group [(ngModel)]="labelPosition" (change)="checkGuides(labelPosition)">
                    <mat-radio-button [checked]="true" class="example-margin haveguides" value="0">No Guide</mat-radio-button>
                    <mat-radio-button class="example-margin haveguides"value="1">Guide <span *ngIf="labelPosition == 1" class="guidespax" >x &nbsp;&nbsp; <input type="number" class="input-guides" [(ngModel)]="information.guides" (change)="checkGuides(labelPosition)" [min]="minguides" [max]="maxguides"></span></mat-radio-button> 
                  </mat-radio-group>
                </div>

                <!-- ---------------- get guides ------------------- -->
                <!-- <div>
                  <div>
                    <span>Guides</span><span>0</span>
                  </div>
                </div> -->

                  <!-- ----------- total price ----------- -->
                   <div class="blog-total-price">
                      <div class="text-total">
                        <span class="text-total-size float-left">TOTAL</span>
                        <span class="text-total-size float-right"><b>{{information.total_price | number:'1.2-2'}}</b><span> THB</span></span>
                       </div> 
                   </div>


                  
                </div>
            </div>
        </div>
        <!-- ------------ Information Guest --------------- -->
        <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
            <div class="shadow p-3 mb-5 bg-white rounded">
                <div class="form-information">
                  <div class="tittle-information">
                    <span class="text-tittle">Information Guest</span>
                   </div><br>
                   <!-- ------ First Name ------ -->
                  <mat-form-field appearance="outline" class="example-full-width" style="width: 100%;">
                    <mat-label>First Name *</mat-label>
                    <input matInput [(ngModel)]="information.firstname" placeholder="First Name">
                    <a *ngIf="check_firstname == true" style="color:red; font-size: 0.6rem;">Please enter a valid firstname</a> 
                  </mat-form-field>
                  <!-- ------ Last Name ------ -->
                  <mat-form-field appearance="outline" class="example-full-width" style="width: 100%;">
                    <mat-label>Last Name *</mat-label>
                    <input matInput [(ngModel)]="information.lastname" placeholder="Last Name">
                    <a *ngIf="check_lastname == true" style="color:red; font-size: 0.6rem;">Please enter a valid lastname</a> 
                  </mat-form-field>
                  <!-- ------ Email ------ -->
                  <mat-form-field appearance="outline" class="example-full-width" style="width: 100%;">
                    <mat-label>Email *</mat-label>
                    <input matInput [(ngModel)]="information.email" placeholder="Email">
                    <a *ngIf="check_email == true" style="color:red; font-size: 0.6rem;">Please enter a valid email</a> 
                  </mat-form-field>
                  <!-- ------ Phone ------ -->
                  <mat-form-field appearance="outline" class="example-full-width" style="width: 100%;">
                    <mat-label>Phone *</mat-label>
                    <input matInput [(ngModel)]="information.phone" placeholder="Phone">
                    <a *ngIf="check_phone == true" style="color:red; font-size: 0.6rem;">Please enter a valid phone</a> 
                  </mat-form-field>
                  <!-- ------ Hotel ------ -->
                  <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Hotel *</mat-label>
                        <mat-select [(value)]="information.hotel">
                            <mat-option value="none">None</mat-option>
                            <mat-option *ngFor="let hotel of getaccountcode" [value]="hotel.hotel">{{hotel.hotel}}</mat-option>
                            <mat-option value="other">Other</mat-option>
                    </mat-select>
                    <a *ngIf="check_hotel == true" style="color:red; font-size: 0.6rem;">Please enter a valid hotel</a> 
                  </mat-form-field>
                  <!-- Other Hotel -->
                  <mat-form-field *ngIf="information.hotel == 'other'" class="example-full-width" style="width: 100%;" appearance="outline">
                    <mat-label>Your Hotel Name *</mat-label>
                    <input matInput [(ngModel)] ="information.hotel_other">
                  </mat-form-field>
                  <!-- check term & condition -->
                  <div style="text-align: center;">
                      <label><input type="checkbox" (click)="checkTerm()" ></label><span> I agree to the</span><span style="padding-left:10px; color:blue" class="pointer" (click)="openModal(template)"><u>Terms and conditions</u> </span>
                  </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 btn-continue">
              <!-- <button class="btn btn-to-payment" [disabled]="checked" type="button">Continue to payment<i *ngIf="reload == true" class="fa fa-refresh fa-spin" style="font-size:18px; margin-left:10px;"></i></button> -->
              <button class="btn btn-success btn-to-payment pointer" [disabled]="checked" (click)="checkValue()" type="button">Continue to payment <i *ngIf="reload == true" class="fa fa-refresh fa-spin" style="font-size:18px; margin-left:10px;"></i></button>
            </div>
        </div>
    </div>
  </div>
</div>

<!-- ---- Footer ---- -->
<!-- <app-footer></app-footer> -->

<ng-template #template>
  <div class="modal-header tab-term-bg">
    <span class="modal-title tab-term-font">Terms & Conditions</span>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body ">
    <!-- VALIDITY -->
    <div class="tab-bg">
      <span class="tab-font">VALIDITY</span>
     </div>
     <div class="term-tittle">
      <div class="detail-term">
          <span> 	- All tour prices are valid from 01 November 2020 to 31 December 2021</span><br>
          <span> 	-	*Except TC-R07 Eco Walk in Kiew Mae Pan Nature Trail (Valid only November – May)</span><br>
      </div>
  </div>
  <hr>
  <!-- TOUR PRICE -->
  <div class="tab-bg">
    <span class="tab-font">TOUR PRICE</span>
   </div>
   <div class="term-tittle">
    <div class="detail-term">
      <span>	-	All tours are based on SIC or Private Basis (as indicated above) and serviced by VIP 9 seats air-condition van (Toyota Commuter) with English speaking tour guide</span><br>
      <span>	-	All tour prices are quoted in Thai Baht per person and minimum operate at 2 adults traveling together</span><br>
    </div>
</div>
<hr>
  <!-- OPERATING -->
  <div class="tab-bg">
    <span class="tab-font">OPERATING</span>
   </div>
   <div class="term-tittle">
    <div class="detail-term">
      <span>	-	Office hour from 08.00 a.m. to 08.00 p.m. local time daily</span><br>
      <span>	-	Office holiday will be closed on 13-15 April and 31 December - 01 January</span><br>
    </div>
</div>
<hr>
   <!--content Booking & Reservations-->
    <div class="tab-bg">
      <span class="tab-font">BOOKING & RESERVATIONS</span>
     </div>
        <div class="term-tittle">
              <div class="detail-term">
                  <span> 	-	All tours are daily operate with a minimum of 2 adults traveling together</span><br>
                  <span> 	- In case of 1 adult travel with 1 child, the price will be considered as 2 adults</span><br>
                  <span> 	-	Reservation time notice</span><br>
                      <div class="detail-term-inside">
                          <span><i class="fa fa-angle-right" aria-hidden="true"></i>	 Afternoon Tours reservation is available until 11.00 a.m. on the departure day</span><br>
                          <span><i class="fa fa-angle-right" aria-hidden="true"></i>	 Evening Tours reservation is available until 04.00 p.m. on the departure day</span><br>
                          <span><i class="fa fa-angle-right" aria-hidden="true"></i>	 After the mentioned period above is subject to availability</span>
                      </div>
                  <span> 	 -	Information request when the booking is made</span><br>
                      <div class="detail-term-inside">
                          <span><i class="fa fa-angle-right" aria-hidden="true"></i>	Full name on passport of all traveler</span><br>
                          <span><i class="fa fa-angle-right" aria-hidden="true"></i>	Hotel name to stay</span><br>
                          <span><i class="fa fa-angle-right" aria-hidden="true"></i>	Food allergy (if any)</span>
                      </div>
                  <span> 	 -	Reservation contact</span><br>
                  <div class="detail-term-inside">
                          <span><i class="fa fa-angle-right" aria-hidden="true"></i>	Tel : +66 53 289 644-5</span><br>
                          <span><i class="fa fa-angle-right" aria-hidden="true"></i>	Mobile : +66 88 258 5817</span><br>
                          <span><i class="fa fa-angle-right" aria-hidden="true"></i>	E-mail : reservations@touringcnx.com</span><br>
                          <span><i class="fa fa-angle-right" aria-hidden="true"></i>	Website : www.touringcenter.com </span>
                      </div>
              </div>
          </div>
          <hr>        
 <!--Van hire-->
    <div class="tab-bg">
      <span class="tab-font">VAN HIRE INCLUDE GASOLINE IN CHIANG MAI</span>
     </div>
        <div class="term-tittle">
              <div class="detail-term">
                  <span> 	-	Full day Service for 10 hours per day (pick-up time starts between 06.00 - 10.00 a.m. and drop-off time is within 08.00 p.m.)</span><br>
                  <span> 	- Overtime will be charged 200 THB per hour (no later than 11.00 p.m.)</span><br>
                  <span> 	- Routing is in the Chiang Mai city area that isn’t over the outer (3rd) ring road. In case the direction is out of the mention, there will be an extra charge;</span><br>
                      <div class="detail-term-inside">
                          <span><i class="fa fa-angle-right" aria-hidden="true"></i>	 Mae Rim (Queen Sirikit Botanic Garden, Mae Sa Elephant Camp, Flower Farm at Mae Rim, Mon Jam) will be charged 500 THB / Day / Van</span><br>
                          <span><i class="fa fa-angle-right" aria-hidden="true"></i>	 San Kam Phaeng (San Kamphaeng Hot Spring, Mae Kampong Village) will be charged 500 THB / Day / Van</span><br>
                          <span><i class="fa fa-angle-right" aria-hidden="true"></i>	 Inthanon National Park will be charged 1,000 THB / Day / Van</span>
                      </div>
                  <span> - Extra charge for Guide Service (English speaking) is 2,000 THB per day (for 10 hours and maximum at 3 vans)</span><br>
                  <span> - Overtime service will be charged 300 THB per hour (no later than 11.00 p.m.)</span><br>
              </div>
          </div>
          <hr>     
  <!--EXTRA CHARGE-->
    <div class="tab-bg">
      <span class="tab-font">EXTRA CHARGE</span>
     </div>
        <div class="term-tittle">
              <div class="detail-term">
                  <span>	-	Transfer to/from the hotel outside the city</span><br>
                   <div class="detail-term-inside">
                          <span><i class="fa fa-angle-right" aria-hidden="true"></i>	Hotel is situated 06 – 15 km from city center will be charged THB500 per group per way</span><br>
                          <span><i class="fa fa-angle-right" aria-hidden="true"></i>	Hotel is situated 16 – 30 km from city center will be charged THB1,000 per group per way</span><br>
                      </div>
                  <span>	-	Tour guide in other language for or private tour only, the extra charge is THB2,500 per group per tour </span>
              </div>
          </div>
          <hr>
  <!--content CHILDREN POLICY -->
    <div class="tab-bg">
      <span class="tab-font">CHILDREN POLICY</span>
     </div>
        <div class="term-tittle">
              <div class="detail-term">
                  <span> 	-	Infants and children under 2 years old are free of charge (No seat providing and share seat with parents)	</span><br>
                  <span> 	-	Children from 2 to 10 years old and no higher than 120 cm, children price will be applied </span><br>
                  <span> 	-	Children 10 years old, but higher than 120 cm, adult price will be applied  </span><br>
                  <span> 	-	Children from 10 years and above, adult price will be applied </span><br>
                  <span> 	-	For elephant and trekking tours, children from 2 years and above are considered as adult </span>
              </div>
          </div>
          <hr>
 <!--content CANCELLATION & NO SHOW -->
    <div class="tab-bg">
      <span class="tab-font">CANCELLATION & NO SHOW</span>
     </div>
        <div class="term-tittle">
              <div class="detail-term">
                  <span> 	-	Full Day Trip and Morning Trip</span><br>
                  <div class="detail-term-inside">
                          <span><i class="fa fa-angle-right" aria-hidden="true"></i>	Cancel the trip before 06.00 p.m. prior the departure day, no cancellation charge</span><br>
                          <span><i class="fa fa-angle-right" aria-hidden="true"></i>	Cancel the trip less than 12 hours prior the trip start, 100% cancellation charge</span><br>
                      </div>
                  <span> 	-	Afternoon Trip and Evening Trip</span><br>
                  <div class="detail-term-inside">
                          <span><i class="fa fa-angle-right" aria-hidden="true"></i>	Cancel the trip over 3 hours prior the trip start, no cancellation charge</span><br>
                          <span><i class="fa fa-angle-right" aria-hidden="true"></i>	Cancel the trip less than 1.5 hours prior the trip start, 100% cancellation charge</span><br>
                      </div>
                  <span> 	-	Last Minute Cancelled and No Shows will be charged 100% of total price</span><br>
                  <span> 	-	Unused features are non-refundable</span><br>
                  <span> 	-	Any change less than 12 hours before the departure time will be charged THB500 per person per change</span>
              </div>
          </div>
          <hr>
  <!--content REMARK-->
    <div class="tab-bg">
      <span class="tab-font">REMARK</span>
     </div>
        <div class="term-tittle">
              <div class="detail-term">
                  <span>	- To maintain our quality and service standard, we may not accept the booking while we are short of guide and/or van.</span><br>
                  <span>	-	Touring Center reserves the right to implement alternate activities/locations or make other significant changes to the itinerary, including cancellation, due to force majeure, weather conditions, operational considerations, or imposed security limitations.</span><br>
                  <span>	-	Cancel the trip by Touring Center, the full payment will be refunded.</span><br>
                  <span>	-	All rights reserved by Touring Center Chiang Mai, Thailand..</span>
              </div>
          </div>
  </div>
</ng-template>