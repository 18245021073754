import { Component, OnInit } from "@angular/core";
import Swal from "sweetalert2";
import { ApiServiceService } from "./../../services/api-service.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  public subscribe = <any>"";
  public message_subscribe_firstname = <any>"";
  public message_subscribe_lastname = <any>"";
  public message_subscribe_email: boolean = false;
  public information = {
    email: <any>"",
  };

  public date_now = new Date();
  public year_now = this.date_now.getFullYear();

  constructor(private apiService: ApiServiceService) {}

  // <<< Initial >>>
  ngOnInit(): void {}

  // <<< -----------------------------------------
  // ------------------- Call API ----------------
  // ----------------------------------------- >>>
  // subscribe
  callSubscribe(): void {
    if (this.information.email !== "") {
      this.message_subscribe_email = false;

      const url = "Email/SendEmailSubscribe";
      let setPayload = {
        guest_info: {
          email: this.information.email,
        },
      };

      this.apiService.callPost(url, setPayload).subscribe(
        (result) => [(this.subscribe = result), this.checkSubscribeStatus()],
        (err) => [console.log(err)]
      );
    }

    if (
      this.information.email == "" ||
      this.information.email == null ||
      this.information.email == undefined
    ) {
      this.message_subscribe_email = true;
    }
  }
  // check subscribe status
  checkSubscribeStatus(): void {
    if (this.subscribe.status == true) {
      Swal.fire({
        icon: "success",
        text: this.subscribe.message,
        showConfirmButton: false,
        timer: 2000,
      });

      // reset data
      this.information.email = "";
    } else {
      Swal.fire({
        icon: "error",
        text: this.subscribe.message,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }

  // <<< -----------------------------------------
  // ------------------- On event ----------------
  // ----------------------------------------- >>>
  setActiveMenu(event: string) {
    sessionStorage.setItem("active_page", event);
  }
}
