<!-- -------- Form Booking -------- -->
<div class="container body-navbar">
  <div class="form-booking">
    <span class="font-3r color-red-pastel"><b>EVENT BOOKING</b></span>
    <hr class="hr-1" />
    <br />
    <div class="row">
      <!-- ------------ Booking --------------- -->
      <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
        <div class="shadow p-3 mb-5 bg-white rounded">
          <div class="form-booking-content">
            <div class="bg-tiitle-form">
              <div class="tittle-booking">
                <!-- ------ Event Code & Event Tittle ------ -->
                <div class="tour-code">
                  <table>
                    <!-- <tr>
                      <td class="td-1">
                        <span class="text-tittle">EVENT CODE</span>
                      </td>
                      <td class="td-1">:</td>
                      <td>
                        <span class="text-tittle_ pl-2">
                          <b style="color: #ff6961">
                            {{event?.code}}
                          </b>
                        </span>
                      </td>
                    </tr> -->
                    <tr>
                      <td class="td-1">
                        <span class="text-tittle">EVENT </span>
                      </td>
                      <td class="td-1">:</td>
                      <td>
                        <span class="text-tittle pl-2">
                          <b style="color: #ff6961">
                            {{event?.title}}
                          </b>
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <br />
            <br />

            <!-- --------- Types ---------- -->
            <div class="date-travel">
              <div class="form-group">
                <span class="tittle-content">{{ event?.type_name }} : </span>
                <span class="tittle-content-r text-red">
                  <!-- <small>* <b>Standard</b>: Red Cab Taxi, &nbsp;<b>VIP</b>: Air-Conditionaed Van *</small> -->
                </span>
                <mat-form-field appearance="outline" style="width: 100%">
                  <mat-label>Select {{ event?.type_name }}</mat-label>
                  <mat-select
                    [disabled]="isNotAvailable"
                    required
                    (selectionChange)="onSelectedType($event)"
                  >
                    <ng-container *ngFor="let item of event?.types">
                      <mat-option [value]="item.type">
                        {{item.type}}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <!-- -------- Travel Date ------- -->
            <div class="date-travel">
              <div class="form-group">
                <span class="tittle-content">Date : </span>
                <input
                  [disabled]="isNotAvailable"
                  type="text"
                  [(bsValue)]="bsInlineValue"
                  [bsConfig]="{
                    showWeekNumbers: false,
                    containerClass: 'theme-red',
                    dateInputFormat: 'DD/MM/YYYY',
                    isAnimated: true,
                    returnFocusToInput: true
                  }"
                  placeholder="Select date"
                  [minDate]="minDate"
                  [maxDate]="maxDate"
                  class="form-control input-select-date"
                  bsDatepicker
                  readonly
                />
              </div>
            </div>

            <!-- -------- Participants ------- -->
            <div class="border-form" *ngIf="selectedPrice && selectedPrice.adult_price">
              <div class="participants-content">
                <!-- ---- Adult ---- -->
                <div class="row">
                  <div
                    class="col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center_"
                  >
                    <span class="participants-ages text-participants-size">
                      Adult :
                      <ng-container *ngIf="!selectedPrice || selectedPrice?.adult_price == 0">
                        <b>-</b>
                      </ng-container>
                      <ng-container *ngIf="selectedPrice && selectedPrice?.adult_price > 0">
                        <b>{{ selectedPrice.adult_price | number }}</b>
                      </ng-container>
                      <span class="per"> /
                        <small>Person</small>
                      </span>
                    </span>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <ngx-number-spinner
                      class="participants-pax"
                      [(value)]="formBooking.bookingInfo.adult_pax"
                      (change)="onChangePax($event, 'adult_pax')"
                      [step]="1"
                      [min]="1"
                      [max]="maxPax - (formBooking.bookingInfo.child_pax + formBooking.bookingInfo.infant_pax)"
                    ></ngx-number-spinner>
                  </div>
                </div>

                <!-- ---- Child ---- -->
                <hr />
                <div class="row">
                  <div
                    class="col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center_"
                  >
                    <span class="participants-ages text-participants-size">
                      Child :
                      <ng-container *ngIf="!selectedPrice || selectedPrice?.child_price == 0">
                        <b>-</b>
                      </ng-container>
                      <ng-container *ngIf="selectedPrice && selectedPrice?.child_price > 0">
                        <b>{{ selectedPrice.child_price | number }}</b>
                      </ng-container>
                      <span class="per"> /
                        <small>Person</small>
                      </span>
                    </span>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <ngx-number-spinner
                      class="participants-pax"
                      [(value)]="formBooking.bookingInfo.child_pax"
                      (change)="onChangePax($event, 'child_pax')"
                      [step]="1"
                      [min]="0"
                      [max]="maxPax - (formBooking.bookingInfo.adult_pax + formBooking.bookingInfo.infant_pax)"
                    ></ngx-number-spinner>
                  </div>
                  <br />
                  <div class="col-12 remark">
                    <span>
                      Children aged 8 and above will be charged the same rate as adult tickets
                    </span>
                  </div>
                </div>

                <!-- ---- Infant ---- -->
                <ng-container *ngIf="event?.is_active_infant">
                  <hr />
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div class="text-center_">
                        <span class="participants-ages text-participants-size">
                          Child <small>(under 8 years old) </small>
                          <i
                            class="fa fa-info-circle"
                            aria-hidden="true"
                            style="color: #ff6961"
                            tooltip="Infant or children under 8 years old can join free of charge."
                          ></i>
                          :
                        </span>
                        <!-- <span class="text-participants-size" >*Free</span> -->
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <ngx-number-spinner
                        class="participants-pax"
                        [(value)]="formBooking.bookingInfo.infant_pax"
                        (change)="onChangePax($event, 'infant_pax')"
                        [step]="1"
                        [min]="0"
                        [max]="maxPax - (formBooking.bookingInfo.adult_pax + formBooking.bookingInfo.child_pax)"
                      ></ngx-number-spinner>
                    </div>
                    <div class="col-12 remark">
                      <span>
                        Infant or children under 8 years old can join free of charge
                      </span>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <!-- Discount code -->
            <div class="discount-code mt-3" *ngIf="selectedPrice && selectedPrice.adult_price">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter promotion code"
                  [(ngModel)]="formBooking.bookingInfo.promotion_code"
                  (input)="onInputPromotionCode()"
                />
                <div class="col-12 remark discount" *ngIf="formBooking.bookingInfo.is_active_discount">
                  <span>
                    Promotion code <i>{{ formBooking.bookingInfo.promotion_code }}</i> : Discount {{ inputPromotionCode.discount | number }}{{ inputPromotionCode.is_percent == 1 ? '%' : 'THB' }}
                  </span>
                </div>
                <div class="col-12 remark discount" *ngIf="!formBooking.bookingInfo.is_active_discount && formBooking.bookingInfo.promotion_code">
                  <span [innerHTML]="promotionCodeMessage"></span>
                </div>
              </div>
            </div>

            <!-- --------- Total ------------ -->
            <div class="blog-total-price">
              <div class="text-total">
                <span class="text-total-size float-left">TOTAL</span>
                <ng-container *ngIf="!isNotAvailable">
                  <ng-container *ngIf="!formBooking.bookingInfo?.is_active_discount">
                    <span class="text-total-size float-right">
                      <b>{{ formBooking?.bookingInfo?.total_price | number: "1.2-2" }}</b>
                    </span>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="formBooking.bookingInfo?.is_active_discount">
                  <span class="text-total-size discount float-right">
                    <strike>{{ formBooking?.bookingInfo.total_price | number: "1.2-2" }}</strike>
                  </span>
                  <span class="text-total-size discount active float-right">
                    <b>{{ formBooking?.bookingInfo?.amount | number: "1.2-2" }}</b>
                  </span>
                </ng-container>
                <ng-container *ngIf="isNotAvailable">
                  <span class="text-total-size float-right">
                    <b>-</b>
                  </span>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ------------ Information Guest --------------- -->
      <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5" *ngIf="selectedPrice && selectedPrice.adult_price">
        <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="!isNotAvailable">
          <div class="form-information">
            <div class="tittle-information mb-3">
              <span class="text-tittle">Information Guests</span>
            </div>
            <br />
            <ng-container *ngFor="let guest of formBooking.guestInfo; index as i">
              <div class="guest-block pt-3" [ngClass]="{'mt-3': i > 0}">
                <ng-container *ngIf="i === 0">
                  <h4><b>Leader Guest</b></h4>
                </ng-container>
                <ng-container *ngIf="i > 0">
                  <h4><b>Guest {{i+1}}</b></h4>
                </ng-container>

                <!-- ------ First Name ------ -->
                <mat-form-field
                  appearance="outline"
                  class="example-full-width"
                  style="width: 100%"
                >
                  <mat-label>First Name</mat-label>
                  <input
                    required
                    matInput
                    [(ngModel)]="guest.firstname"
                    placeholder="First Name"
                    (input)="validFormGuest()"
                  />
                  <a
                    *ngIf="isSave && !guest.firstname"
                    style="color: red; font-size: 0.6rem"
                  >
                    Please enter a valid firstname
                  </a>
                </mat-form-field>

                <!-- ------ Last Name ------ -->
                <mat-form-field
                  required
                  appearance="outline"
                  class="example-full-width"
                  style="width: 100%"
                >
                  <mat-label>Last Name</mat-label>
                  <input
                    required
                    matInput
                    [(ngModel)]="guest.lastname"
                    placeholder="Last Name"
                    (input)="validFormGuest()"
                  />
                  <a
                    *ngIf="isSave && !guest.lastname"
                    style="color: red; font-size: 0.6rem"
                  >
                    Please enter a valid lastname
                  </a>
                </mat-form-field>

                <!-- ------ Gender ------ -->
                <div class="gender-block">
                  <mat-radio-group class="mb-3" aria-label="Select an option" [(ngModel)]="guest.gender" (input)="validFormGuest()">
                    <mat-radio-button value="Male" class="pr-3 pt-2">Male</mat-radio-button>
                    <mat-radio-button value="Female" class="pr-3 pt-2">Female</mat-radio-button>
                    <mat-radio-button value="Other" class="pr-3 pt-2">Other</mat-radio-button>
                  </mat-radio-group>
                </div>

                <!-- ------ Passport number ------ -->
                <mat-form-field
                  appearance="outline"
                  class="example-full-width mt-3"
                  style="width: 100%"
                >
                  <mat-label>Passport Number</mat-label>
                  <input
                    required
                    matInput
                    [(ngModel)]="guest.passport_number"
                    placeholder="Last Name"
                    (input)="validFormGuest()"
                  />
                  <a
                    *ngIf="isSave && !guest.passport_number"
                    style="color: red; font-size: 0.6rem"
                  >
                    Please enter a valid passport number
                  </a>
                </mat-form-field>

                <!-- ------ Nationality ------ -->
                <mat-form-field appearance="outline" style="width: 100%">
                  <mat-label>Select Nationality</mat-label>
                  <mat-select
                    required
                    [(value)]="guest.nationality"
                    (input)="validFormGuest()"
                  >
                    <ng-container *ngFor="let item of nationalities">
                      <mat-option [value]="item.nationality">
                        {{ item.nationality }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                  <a
                    *ngIf="isSave && !guest.nationality"
                    style="color: red; font-size: 0.6rem"
                  >
                    Please enter a valid nationality
                  </a>
                </mat-form-field>

                <!-- ------ Email ------ -->
                <mat-form-field
                  *ngIf="i === 0"
                  appearance="outline"
                  class="example-full-width"
                  style="width: 100%"
                >
                  <mat-label>Email</mat-label>
                  <input
                    required
                    matInput
                    [(ngModel)]="guest.email"
                    placeholder="Email"
                    (input)="validFormGuest()"
                  />
                  <a
                    *ngIf="isSave && !guest.email"
                    style="color: red; font-size: 0.6rem"
                  >
                    Please enter a valid email
                  </a>
                </mat-form-field>

                <!-- ------ Phone ------ -->
                <mat-form-field
                  *ngIf="i === 0"
                  appearance="outline"
                  class="example-full-width"
                  style="width: 100%"
                >
                  <mat-label>Phone <small>(Optional)</small></mat-label>
                  <input
                    matInput
                    [(ngModel)]="guest.phone"
                    placeholder="Phone"
                  />
                </mat-form-field>
              </div>
            </ng-container>

            <!-- Terms & Conditions -->
            <div style="text-align: center" class="mt-3">
              <label>
                <input
                  type="checkbox"
                  [(value)]="isAccept"
                  (click)="onAccept()"
                />
              </label>
              <span> I agree to the</span>
              <span
                style="padding-left: 10px; color: blue"
                class="pointer"
                (click)="toggleDialog(termDialog)"
                ><u>Terms and conditions</u>
              </span>
            </div>
          </div>
        </div>

        <!-- Save booking -->
        <div
          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 btn-continue"
        >
          <ng-container *ngIf="!isNotAvailable">
            <button
              class="btn btn-success btn-to-payment pointer"
              [disabled]="!isAccept"
              (click)="saveBooking()"
              type="button"
            >
              Continue to payment
              <i
                *ngIf="isReload"
                class="fa fa-refresh fa-spin"
                style="font-size: 18px; margin-left: 10px"
              ></i>
            </button>
          </ng-container>
        </div>
      </div>

      <!-- ---------------- Not Available -------------------  -->
      <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5 text-center" *ngIf="isNotAvailable">
        <div class="mt-3">
          <span class="font-2r color-red-pastel">** This event is not available **</span>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- ---------- Term & Condition ------------------ -->
<ng-template #termDialog>
  <app-term-and-condition-event
    (onClose)="toggleTermDialog($event)"
    [isClose]="true"
    [liabilities]="event?.liabilities"
  ></app-term-and-condition-event>
</ng-template>