import { Injectable } from "@angular/core";
import { ApiServiceService } from "./api-service.service";
import { Observable, of } from "rxjs";
import { EnvService } from "src/app/services/env.service";

@Injectable({
  providedIn: "root",
})
export class EventsService {
  private path = "Website";
  private configs: any = {};

  constructor(
    private service: ApiServiceService,
    private envService: EnvService
  ) {
    let config = this.envService.getConfig();
    this.configs = config;
  }

  getEvents(payload: any): Observable<any> {
    const mockEvent = {
      messages: "Complete",
      status: true,
      data: this.getMockEventList()
    };
    return of(mockEvent);
    return this.service.callGet(`${this.path}/Events`, payload);
  }

  getEvent(payload: any): Observable<any> {
    const mockEvent = {
      messages: "Complete",
      status: true,
      data: this.getMockEventList()?.find(item => item.id === payload.id)
    };
    return of(mockEvent);
    return this.service.callGet(`${this.path}/Event/${payload.id}`, payload);
  }

  getMockEventList() {
    return [
      {
        id: 1,
        code: "TC-EV-01",
        title: "The Heaven Lantern Festival 2024",
        short_description:
          "Come join us for an unforgettable experience that honors timeless traditions. Let the lanterns' enchantment, the music's rhythm, and the flavors of local cuisine whisk you away to a world ...",
        description: `
        <label><b>Experience The Heaven Lantern Festival: A Magical Journey Awaits!</b></label><br/>
        <p>Have you ever dreamed of participating in an enchanting tradition that has captivated hearts for centuries? Step into a world of wonder at the Chiang Mai Lantern Festival, an awe-inspiring event set amidst the serene landscapes of the Mae Tang area, just 1.5 hours away from Chiang Mai City. This extraordinary festival illuminates the night sky with thousands of mesmerizing floating lanterns, creating a breathtaking spectacle of beauty and magic.</p><br/>

        <label><b>Why Attend The Heaven Lantern Festival?</b></label><br/>
        <p>Unforgettable Moments: Join thousands of fellow festival-goers in releasing your lantern into the night sky, creating memories that will last a lifetime.</p>
        <p>Cultural Immersion: Immerse yourself in the rich heritage of Chiang Mai as you participate in this cherished tradition. Our performances, led by local artists and performers, beautifully express the northern Thai culture, offering a deeper connection to the region's traditions.</p>
        <p>Spectacular Views: Watch in awe as the night sky is transformed into a sea of flickering lanterns, a sight that will leave you breathless.</p>
        <p>Delicious Cuisine: Feast on authentic Northern Thai cuisine with an eco-friendly twist, especially in the use of eco-conscious containers, ranging from savory street food to delectable desserts, all available at our festival grounds.</p>
      `,
        booking_start: "2024-04-16 00:00:00",
        booking_end: "2024-11-15 23:59:59",
        start_date: "2024-11-15",
        end_date: "2024-11-16",
        video: {
          is_iframe: true,
          link: `<iframe src="https://www.youtube.com/embed/oIoWymU3PvQ?si=ORXQg17d3q2bRguo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          // link: `${this.configs.SERVER_URL}videos/events/TC-EV-01/Heaven Lantern Final.mp4`,
        },
        document:
          this.configs.SERVER_URL +
          "PDF/events/TC-EV-01/Heaven-Lantern-2024.pdf",
        // image: this.configs.SERVER_URL + "images/events/TC-EV-01/tc-ev-01-map.jpg",
        // image_cover: this.configs.SERVER_URL + "images/events/TC-EV-01/tc-ev-01-cover.jpg",
        // image_header: this.configs.SERVER_URL + "images/events/TC-EV-01/tc-ev-01-header.jpg",
        image: "/assets/images/events/TC-EV-01/tc-ev-01-map.jpg",
        image_cover: "/assets/images/events/TC-EV-01/tc-ev-01-cover.jpg",
        image_header: "/assets/images/events/TC-EV-01/tc-ev-01-header.jpg",
        images: [
          {
            id: 1,
            // url: this.configs.SERVER_URL + "images/events/TC-EV-01/tc-ev-01-001.jpg",
            url: "/assets/images/events/TC-EV-01/tc-ev-01-001.jpg",
          },
          {
            id: 2,
            // url: this.configs.SERVER_URL + "images/events/TC-EV-01/tc-ev-01-002.jpg",
            url: "/assets/images/events/TC-EV-01/tc-ev-01-002.jpg",
          },
          {
            id: 3,
            // url: this.configs.SERVER_URL + "images/events/TC-EV-01/tc-ev-01-003.jpg",
            url: "/assets/images/events/TC-EV-01/tc-ev-01-003.jpg",
          },
          {
            id: 4,
            // url: this.configs.SERVER_URL + "images/events/TC-EV-01/tc-ev-01-004.jpg",
            url: "/assets/images/events/TC-EV-01/tc-ev-01-004.jpg",
          },
          {
            id: 5,
            // url: this.configs.SERVER_URL + "images/events/TC-EV-01/tc-ev-01-005.jpg",
            url: "/assets/images/events/TC-EV-01/tc-ev-01-005.jpg",
          },
          {
            id: 6,
            // url: this.configs.SERVER_URL + "images/events/TC-EV-01/tc-ev-01-006.jpg",
            url: "/assets/images/events/TC-EV-01/tc-ev-01-006.jpg",
          },
          {
            id: 7,
            // url: this.configs.SERVER_URL + "images/events/TC-EV-01/tc-ev-01-007.jpg",
            url: "/assets/images/events/TC-EV-01/tc-ev-01-007.jpg",
          },
        ],
        type_name: "Seat",
        types: [
          {
            id: 1,
            type: "Standard",
            // detail:
            //   "1820 seats <br/>Entrance Ticket <br/>1 Krathong <br/>2 Sky Lanterns <br/>Khan Toke Dinner (Standard Area) <br/>Round Trip Transfer (Red Cab Taxi)",
            adult_price: 6500,
            child_price: 6500,
            infant_price: 0,
          },
          // {
          //   id: 2,
          //   type: "VIP",
          //   detail:
          //     "750 seats <br/>Entrance Ticket <br/>1 Krathong <br/>2 Sky Lanterns <br/>Khan Toke Dinner (VIP Area) <br/>Round Trip Transfer (Air-Conditioned Van)",
          //   adult_price: 5700,
          //   child_price: 5700,
          //   infant_price: 0,
          // },
        ],
        type_noted: {
          title: "How to get your e-ticket",
          noted: `
          After full payment is made, we will send an E-Ticket to your email within 24 hours. <br/>
          The E-Ticket will be used to register at the pick-up point and the registration counter at the event area.
        `,
          is_active: true,
        },
        schedules: [
          {
            id: 1,
            time: "14:00 - 15:00",
            detail:
              // "Guests arrive at the designated pick-up points: <br/>1. Central Chiang Mai Airport or <br/>2. Central Chiang Mai <br/>*The shuttle service is shared transportation <br/>*The last shuttle time is 15.00 p.m. <br/>*The transfer from pick up point to the venue is approximately 1.30 hours",
              "Guests arrive at the designated pick-up point : Central Chiang Mai <br/>*The shuttle service is shared transportation <br/>*The last shuttle time is 15.00 p.m. <br/>*The transfer from pick up point to the venue is approximately 1.5 hours",
          },
          {
            id: 2,
            time: "15:30 - 16:30",
            detail: "Arrival at the venue",
          },
          {
            id: 3,
            time: "16:00 - 18:00",
            detail: `Register for event entry at the Registration area. Please show QR code that you have received in separately email.<br />
            After registration, participants can enjoy various activities, including: <br/>
            <ul>
              <li>Visiting the Yi Peng Lantern Pavilion</li>
              <li>Engaging in cultural activities and Thai Lanna art performances</li>
              <li>Delighting in local food and beverages at Kad Mua Style, a retro Lanna-style market</li>
              <li>Participating in Loy Krathong activities</li>
              <li>Trying workshop activities such as crafting Lanna Lanterns and engaging in Prayer activities</li>
            </ul>`,
          },
          {
            id: 4,
            time: "18:00 - 19:00",
            detail:
              "Attend the Khan Toke Dinner (Buffet) accompanied by a show on a small stage",
          },
          {
            id: 5,
            time: "19:00 - 20:30",
            detail:
              "Gather in the courtyard area to witness a stage show set in the middle of the lake, featuring: <br/><ul><li>A mesmerizing Light and Sound Show</li><li>The captivating Spirit of Faith Show</li><li>The heartwarming Sky Lantern Release</li></ul>",
          },
          {
            id: 6,
            time: "20:30",
            detail:
              "Guests return to the city for drop at the same pick-up point",
          },
        ],
        schedules_noted: {
          title: "DRESS CODE",
          noted: "Please wear polite attire or a Lanna Thai Costume",
          is_active: true,
        },
        includes: [
          {
            id: 1,
            detail: "Entrance ticket to The Heaven Lantern Festival 2024",
          },
          {
            id: 2,
            detail: "1 Krathong",
          },
          {
            id: 3,
            detail: "2 Sky Lanterns",
          },
          {
            id: 4,
            detail: "Snacks and drinks (non-alcohol)",
          },
          {
            id: 5,
            detail: "Khan Toke Dinner",
          },
          {
            id: 6,
            detail: "All activities and performances",
          },
          {
            id: 7,
            detail:
              "Round Trip Transfer by air-conditioned van from the designed pick-up point to the venue and from the venue to pick-up point",
          },
        ],
        excludes: [
          {
            id: 1,
            detail:
              "Hotel pick-up and drop-off to designed pick-up/drop-off point",
          },
        ],
        documents: {
          id: 1,
          file_url: "",
          is_active: true,
        },
        liabilities: {
          title:
            "Touring Center is an authorized ticket reseller for The Heaven Lantern 2024. We want to ensure that you have a clear understanding of our responsibilities and limitations. Please review the following information:",
          details: [
            {
              title: "Our Responsibilities:",
              detail: `
                <b>Ticket Issuance:</b> <br/>
                We are reponsible for issuing the event tickets provided by the organizer, ensuring that you have a valid entry to The Heaven Lantern 2024. <br/><br/>
                <b>Communication:</b> <br/>
                We will communicate with you regarding the event's details, answering any questions you may have and providing relevant information to ensure a smooth experience. <br/><br/>
                <b>Payment Support:</b> <br/>
                Out team is here to assist you with payments in accordance with the policy set forth by the organizer. We'll help facilitate a seamless payment process.
              `,
            },
            {
              title: "Please Note, We Are Not Responsible For:",
              detail: `
                <b>Event Issues:</b> <br/>
                While we facilitate ticket sales, we are not responsible for any issues that may arise at the event itself. This includes any services provided by the organizer, such as registration, transportation, and event-related services. <br/><br/>
                <b>Losses, Injuries, or Damages:</b> <br/>
                We Cannot be held accountable for any losses, injuries, or damages you might incur during The Heaven Lantern 2024, whether they occur within the event or outside of the scheduled tour programs. <br/><br/>
                <b>Additional Expenses:</b> <br/>
                Any additional expenses resulting from unforeseen circumstances, such as delays, accidents, natural disasters, political actions, or unrest, are the responsibility of clients. We are unable to cover these expenses.
              `,
            },
          ],
          is_active: true,
        },
        details: {
          seat: {
            title: "Seat",
            // image: this.configs.SERVER_URL + "images/events/TC-EV-01/details/seat-map.jpg",
            image: "/assets/images/events/TC-EV-01/details/seat-map.jpg",
            images: [],
            detail: `
            <label><b>HOW to get your E-Ticket</b></label>
            <p>After full payment is made, we will send an E-Ticket to your email before the event start.</p>
            <p>The E-Ticket will be used to register at the pick-up point and the registration counter at the event area.</p>
          `,
            is_active: true,
          },
          map: {
            title: "Map to the event",
            // image: this.configs.SERVER_URL + "images/events/TC-EV-01/details/map.png",
            image: "/assets/images/events/TC-EV-01/details/map.png",
            images: [],
            detail: "",
            is_active: true,
          },
          ticket: {
            title: "E-Ticket",
            image: "",
            images: [
              {
                id: 1,
                // url: this.configs.SERVER_URL + "images/events/TC-EV-01/details/ticket-standard.png",
                url:
                  "/assets/images/events/TC-EV-01/details/ticket-standard.png",
                alt: "Standard Ticket",
              },
              {
                id: 2,
                // url: this.configs.SERVER_URL + "images/events/TC-EV-01/details/ticket-vip.png",
                url: "/assets/images/events/TC-EV-01/details/ticket-vip.png",
                alt: "VIP Ticket",
              },
            ],
            detail: "",
            is_active: false,
          },
        },
        is_active: true,
        is_active_infant: true,
      },
      {
        id: 2,
        code: "TC-EV-02",
        title: "The Iconic Yee Peng Festival Workshop",
        short_description:
          "Experience the Iconic Yee Peng Festival with Local Lanna People.",
        description: `
          <p>Experience the Iconic Yee Peng Festival with Local Lanna People.</p>
          <p>Immerse yourself in the heart of Chiang Mai's cultural celebration at the Eiiga Yee Peng Pop Market.</p>
          <br/>

          <p>Dates: November 14-17, 2024</p>
          <p>Time: 9:00 AM - 7:00 PM</p>
          <p>Location: <a href="https://g.co/kgs/qLqLhmK" target="__blank">The Treasury Museum Chiang Mai</a></p>
          <br/>

          <p>Join us for a unique Yee Peng experience that combines tradition with sustainability.</p>
          <br/>

          <p><b>Choose from these exciting activities:</b></p>
          <br/>

          <div>
            <p>
              <img src="/assets/images/events/TC-EV-02/workshop-1.png" alt="Paint & Display Lanna Lantern">
            </p>
            <p><b>Workshop 1: Paint & Display Lanna Lantern (120 THB)</b></p>
            <p>Create a beautiful, eco-friendly Lanna lantern using sustainable materials crafted by local artisans.</p>
          </div>
          <br/>

          <div>
            <p>
              <img src="/assets/images/events/TC-EV-02/workshop-2.png" alt="Paint & Display Lanna Lantern (Exclusive)">
            </p>
            <p><b>Workshop 2: Paint & Display Lanna Lantern (Exclusive, 200 THB)</b></p>
            <p>Personalize your lantern with your name written in the ancient Lanna alphabet by a local master. Help preserve this fading cultural heritage.</p>
          </div>
          <br/>

          <div>
            <p>
              <img src="/assets/images/events/TC-EV-02/workshop-3.png" alt="Lanna Lantern Crafting Workshop">
            </p>
            <p><b>Workshop 3: Lanna Lantern Crafting Workshop (400 THB)</b></p>
            <p>Learn the art of Lanna lantern making from skilled local artisans and create your own unique lantern.</p>
          </div>
          <br/>

          <div>
            <p>
              <img src="/assets/images/events/TC-EV-02/workshop-4.png" alt="Traditional Lanna Dress Rental">
            </p>
            <p><b>Workshop 4: Traditional Lanna Dress Rental (800 THB)</b></p>
            <p>Step back in time and immerse yourself in the Yee Peng Festival by dressing in traditional Lanna attire.</p>
            <p>Explore the historic Old Town and capture unforgettable memories.</p>
          </div>
          <br/>

          <p>Don't miss this opportunity to celebrate Yee Peng with the locals and experience the magic of this ancient festival.</p>
          <br/>

          <div class="prices">
            <label><b>Workshop Price</b></label>
            <p>Workshop 1: Paint & Display Lanna Lantern = THB120 per person</p>
            <p>Workshop 2: Paint & Display Lanna Lantern (Exclusive) = THB200 per person</p>
            <p>Workshop 3: Lanna Lantern Crafting Workshop = THB400 per person</p>
            <p>Workshop 4: Traditional Lanna Dress Rental = THB800 per person</p>
          </div>
        `,
        booking_start: "2024-11-14 00:00:00",
        booking_end: "2024-11-17 17:00:00",
        start_date: "2024-11-15",
        end_date: "2024-11-17",
        video: null,
        document: null,
        image: null,
        image_cover: "/assets/images/events/TC-EV-02/tc-ev-02-cover.jpg",
        image_header: "/assets/images/events/TC-EV-02/tc-ev-02-header.jpg",
        images: [
          {
            id: 1,
            url: "/assets/images/events/TC-EV-02/tc-ev-02-001.jpg",
          },
          {
            id: 2,
            url: "/assets/images/events/TC-EV-02/tc-ev-02-002.jpg",
          },
          {
            id: 3,
            url: "/assets/images/events/TC-EV-02/tc-ev-02-003.jpg",
          },
          {
            id: 4,
            url: "/assets/images/events/TC-EV-02/tc-ev-02-004.jpg",
          },
          {
            id: 5,
            url: "/assets/images/events/TC-EV-02/tc-ev-02-005.jpg",
          },
          {
            id: 6,
            url: "/assets/images/events/TC-EV-02/tc-ev-02-006.jpg",
          },
          {
            id: 7,
            url: "/assets/images/events/TC-EV-02/tc-ev-02-007.jpg",
          },
          {
            id: 8,
            url: "/assets/images/events/TC-EV-02/tc-ev-02-008.jpg",
          },
        ],
        type_name: "Workshop",
        types: [
          {
            id: 1,
            type: "Workshop 1 : Paint & Display Lanna Lantern",
            detail: "Paint & Display Lanna Lantern",
            adult_price: 120,
            child_price: 120,
            infant_price: 0,
          },
          {
            id: 2,
            type: "Workshop 2 : Paint & Display Lanna Lantern (Exclusive)",
            detail: "Paint & Display Lanna Lantern (Exclusive)",
            adult_price: 200,
            child_price: 200,
            infant_price: 0,
          },
          {
            id: 3,
            type: "Workshop 3 : Lanna Lantern Crafting Workshop",
            detail: "Lanna Lantern Crafting Workshop",
            adult_price: 400,
            child_price: 400,
            infant_price: 0,
          },
          {
            id: 4,
            type: "Workshop 4 : Traditional Lanna Dress Rental",
            detail: "Traditional Lanna Dress Rental",
            adult_price: 800,
            child_price: 800,
            infant_price: 0,
          },
        ],
        type_noted: null,
        schedules: [],
        schedules_noted: null,
        includes: [],
        excludes: [],
        documents: null,
        liabilities: null,
        details: {
          seat: null,
          map: null,
          ticket: null,
        },
        is_active: true,
        is_active_infant: true,
      },
    ];
  }
}
