<div class="container body-navbar">
  <span class="font-3r color-red-pastel"><b>RECEIPT</b></span>
  <hr class="hr-1"><br>

  <div class="checkpdf" *ngIf="is_show_pdf">
    <iframe *ngIf="pdfSrc" [src]="pdfSrc" [autoresize]="true" style="display: block;"></iframe>
  </div>

  <div class="checkm-pdf" *ngIf="is_show_pdf">
    <iframe *ngIf="pdfSrc" [src]="pdfSrc" [autoresize]="true" style="display: block;"></iframe>
  </div>
</div>

<section>
  <div class="btn-content">
    <a mat-stroked-button color="primary" (click)="onClick('HOME')" class="border-1 hover-btn-home">Home</a>
     &nbsp;&nbsp;
  </div>
</section>
