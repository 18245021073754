import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  language: string = 'en'

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    const type = this.route.snapshot.paramMap.get("type");
    if (type) {
      type === 'th' ? this.language = type : this.router.navigate(["/privacy-policy"]);
    }
  }

}
