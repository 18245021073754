import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import Swal from "sweetalert2";
import { ApiServiceService } from "../../../services/api-service.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public token: any = null;
  public input: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiServiceService
  ) { }

  ngOnInit(): void {
    this.token = JSON.parse(localStorage.getItem("TOKEN_LOGIN"));
    if (this.token && this.token != undefined) {
      this.callCheckLoginExpired(this.token);
    } else {
      this.checkLoginExpired({ status: false });
    }

    // set default
    this.input = {};
  }

  //! <<< --------- Check login expired ---------
  callCheckLoginExpired(token): void {
    const url = "User/CheckLoginExpired";
    let setPayload = {
      token: "Bearer " + token
    };

    this.apiService
      .callPost(url, setPayload)
      .toPromise()
      .then(
        (result) => [this.checkLoginExpired(result)],
        (err) => [console.log(err)]
      )
      .then(() => {});
  }

  // check login expired
  checkLoginExpired(params): void {
    if (params.status === false) {
      localStorage.removeItem("TOKEN_LOGIN");
      this.router.navigate(["login"]);
    }
  }

}
