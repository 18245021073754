import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import Swal from "sweetalert2";
import { ApiServiceService } from "./../../services/api-service.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public token: any = null;
  public input: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiServiceService
  ) {}

  ngOnInit(): void {
    this.token = JSON.parse(localStorage.getItem("TOKEN_LOGIN"));
    if (this.token && this.token != undefined) {
      this.callCheckLoginExpired(this.token);
    } else {
      this.checkLoginExpired({ status: false });
    }

    // set default
    this.input = {
      username: "",
      password: "",
    };
  }

  //! <<< --------- Check login expired ---------
  callCheckLoginExpired(token): void {
    const url = "User/CheckLoginExpired";
    let setPayload = {
      token: "Bearer " + token
    };

    this.apiService
      .callPost(url, setPayload)
      .toPromise()
      .then(
        (result) => [this.checkLoginExpired(result)],
        (err) => [console.log(err)]
      )
      .then(() => {});
  }

  // check login expired
  checkLoginExpired(params): void {
    if (params.status === false) {
      localStorage.removeItem("TOKEN_LOGIN");
    } else {
      // this.router.navigate(["dashboard"]);
      this.router.navigate(["close-booking-tour"]);
    }
  }

  // Event keypress
  onKeyPress(event: any, name: string): void {
    if (event.key === "Enter") {
      // this.onClick(name);
    }
  }

  // Login
  onClick(name: string): void {
    if (name === "LOGIN") {
      if (this.input.username && this.input.password) {
        this.callLogin();
      } else {
        Swal.fire({
          icon: "error",
          text: "Username or password invalid.",
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {});
      }
    } else if (name === "FORGOT_PASSWORD") {
    } else if (name === "SIGN_UP") {
    }
  }

  //! <<< --------- Login --------- >>>
  // Call login
  callLogin(): void {
    const url = "User/Login";
    let setPayload = {
      username: this.input.username,
      password: this.input.password,
    };

    this.apiService
      .callPost(url, setPayload)
      .toPromise()
      .then(
        (result) => [
          this.checkLogin(result),
        ],
        (err) => [console.log(err)]
      )
      .then(() => {});
  }

  // Check login
  checkLogin(params): void {
    if (params.status === true) {
      Swal.fire({
        icon: "success",
        text: params.message,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        let session_token = localStorage.setItem(
          "TOKEN_LOGIN",
          JSON.stringify(params.data.token)
        );
        // this.router.navigate(["dashboard"]);
        this.router.navigate(["close-booking-tour"]);
      });
    } else {
      Swal.fire({
        icon: "warning",
        text: params.message,
        showConfirmButton: true,
        // timer: 2000,
        confirmButtonText: "Force logout",
      }).then(() => {
        this.callForceLogout();
      });
    }
  }

  //! <<< --------- Force logout --------- >>>
  // Call force logout
  callForceLogout(): void {
    const url = "User/ForceLogout";
    let setPayload = {
      username: this.input.username,
      password: this.input.password,
    };

    this.apiService
      .callPost(url, setPayload)
      .toPromise()
      .then(
        (result) => [this.checkForceLogout(result)],
        (err) => [console.log(err)]
      )
      .then(() => {});
  }

  // Alert
  checkForceLogout(params): void {
    if (params.status === true) {
      Swal.fire({
        icon: "success",
        text: params.message,
        showConfirmButton: true,
        // timer: 2000,
        confirmButtonText: "Login",
      }).then(() => {
        this.ngOnInit();
      });
    } else {
      Swal.fire({
        icon: "error",
        text: params.message,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {});
    }
  }
}
