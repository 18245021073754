import { Injectable } from '@angular/core';
import { ApiServiceService } from "./api-service.service";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  private path = "Website/Booking"

  constructor(private service: ApiServiceService) { }

  saveBooking(payload: any = {}): Observable<any> {
    return this.service.callPost(`${this.path}/SaveBooking`, payload);
  }
}
