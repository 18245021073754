import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { IPayPalConfig, ICreateOrderRequest } from "ngx-paypal";
import Swal from "sweetalert2";
import { PaymentService } from "./../../services/payment.service";
import { EnvService } from "./../../services/env.service";
import { ApiServiceService } from 'src/app/services/api-service.service';

@Component({
  selector: 'app-payment-event',
  templateUrl: './payment-event.component.html',
  styleUrls: ['./payment-event.component.scss']
})
export class PaymentEventComponent implements OnInit {
  private configs: any = {};
  public payPalConfig?: IPayPalConfig;

  public id: any = "";
  public transaction: any = {};
  public payment: any = {};
  public paymentId: any = "";

  public isReload: boolean = false;
  public isWaiting: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private envService: EnvService,
    private paymentService: PaymentService,
    private apiService: ApiServiceService,
  ) {
    let config = this.envService.getConfig();
    this.configs = config;
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get("id");
    if (this.id) {
      this.callGetTransaction();
    }
  }

  callGetTransaction(): void {
    const payload = {
      transaction_id: this.id,
      is_event: true
    }
    this.paymentService.getTransactionById(payload).subscribe(
      (res: any) => {
        if (res) {
          this.transaction = res;
          this.callGetPayment();
        }
      }
    )
  }

  // get payment data
  callGetPayment(): void {
    let payload = {
      transaction_id: this.id,
      is_event: true
    }
    this.paymentService.getPaymentByTransactionId(payload).subscribe(
      (res: any) => {
        if (res) {
          this.payment = res;
          this.configPayment();
          this.callCheckPaymentPaid();
        }
      }
    )
  }

  private configPayment(): void {
    this.payPalConfig = {
      currency: "THB",
      clientId: `${this.configs.PAYPAL_KEY}`,
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: "CAPTURE",
          purchase_units: [
            {
              amount: {
                currency_code: "THB",
                value: this.payment.amount,
                breakdown: {
                  item_total: {
                    currency_code: "THB",
                    value: this.payment.amount,
                  },
                },
              },
              items: [
                {
                  name:
                    this.transaction?.event_code +
                    " : " +
                    this.transaction?.event_title +
                    " | receipt : " +
                    this.payment.invoice,
                  description:
                    this.transaction?.event_code +
                    " : " +
                    this.transaction?.event_title +
                    " | receipt : " +
                    this.payment.invoice,
                  quantity: "1",
                  category: "DIGITAL_GOODS",
                  unit_amount: {
                    currency_code: "THB",
                    value: this.payment.amount,
                  },
                },
              ],
            },
          ],
        },
      advanced: {
        commit: "true",
      },
      style: {
        label: "paypal",
        // layout: 'vertical',
        size: "responsive",
        color: "gold",
        // shape: 'pill',
        // label: 'checkout'
        // tagline: 'true',
        layout: "horizontal",
      },
      onApprove: (data, actions) => {
        actions.order.get().then((details) => {
        });
      },
      onClientAuthorization: (data) => {
        this.callUpdatePayment();
      },
      onCancel: (data, actions) => {
      },
      onError: (err) => {
      },
      onClick: (data, actions) => {
      },
    };
  }

  callCheckPaymentPaid(): void {
    let payload = {
      payment_id: this.payment.payment_id,
      is_event: true,
    };

    this.paymentService.checkPaymentPaid(payload).subscribe(
      (res: any) => {
        if (res && res.status) {
          this.checkPaymentUpdate(res);
        }
      }
    )
  }

  checkPaymentUpdate(params) {
    if (params.status == true) {
      (async () => {
        await this.delay(2000);
        this.isReload = false;
        this.isWaiting = false;
        Swal.fire({
          icon: "success",
          text: "Payment Success",
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          //go to Receipt
          this.router.navigate(["event/receipt/" + this.id]);
        });
      })();
    }

    if (this.paymentId.status == false) {
      this.isReload = false;
      Swal.fire({
        icon: "error",
        text: "ERROR! UPDATE NOT SUCCESS, Contact it@touringcnx.com",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }

  //! Event onClick to Paypal
  callUpdatePayment(): void {
    this.isReload = true;
    this.isWaiting = true;

    const url = "Website/Payment/UpdatePaymentById";
    let payload = {
      payment_id: this.payment.payment_id,
      is_event: true
    };

    this.apiService
      .callPost(url, payload)
      .toPromise()
      .then(
        (result) => [
          (this.paymentId = result),
        ],
        (err) => [console.log(err)]
      )
      .then(() => {
        this.callCheckPaymentPaid();
      });
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

}
