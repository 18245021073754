<!-- <app-header-non-fixed></app-header-non-fixed> -->

<!-- ---------- image main booking ----------- -->
<!-- <div class="bg-booking-page">
  <div class="centered_">
    <span class="tittle-page" >BOOKING</span>
   </div>
</div> -->
<!-- -------- Form Booking -------- -->
<div class="container body-navbar">
  <div class="form-booking">
    <span class="font-3r color-red-pastel"><b>BOOKING</b></span>
    <hr class="hr-1" />
    <br />
    <div class="row">
      <!-- ------------ Booking --------------- -->
      <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
        <div class="shadow p-3 mb-5 bg-white rounded">
          <div class="form-booking-content">
            <div class="bg-tiitle-form">
              <div class="tittle-booking">
                <!-- ------ Tour Code & Tour Tittle ------ -->
                <div class="tour-code">
                  <table>
                    <tr>
                      <td class="td-1">
                        <span class="text-tittle">TOUR CODE</span>
                      </td>
                      <td class="td-1">:</td>
                      <td>
                        <span class="text-tittle_"
                          ><b style="color: #ff6961">{{
                            getTourPrice_.tour
                          }}</b></span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="td-1">
                        <span class="text-tittle">TOUR NAME</span>
                      </td>
                      <td class="td-1">:</td>
                      <td>
                        <span class="text-tittle_"
                          ><b style="color: #ff6961">{{
                            getTourPrice_.title
                          }}</b></span
                        >
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <br />
            <br />
            <!-- --------- Select Time ---------- -->
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Select Time</mat-label>
              <mat-select
                [(value)]="information.tourtime"
              >
                <mat-option
                  *ngFor="let time of getTourPrice_.tourtime"
                  (blur)="onDisabledCalendar()"
                  [value]="time.meridiem"
                >
                  {{ getTourPrice_.tour !== "TC-E09" ? time.meridiem + " : " : "" }}{{ time.travel_time_start }} - {{ time.travel_time_end }}
                </mat-option>
              </mat-select>
              <a
                *ngIf="check_time == true"
                style="color: red; font-size: 0.6rem"
                >Please enter a valid time</a
              >
            </mat-form-field>
            <!-- --------- Privacy ---------- -->
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Select Privacy</mat-label>
              <mat-select [(value)]="information.privacy">
                <mat-option
                  *ngIf="checkJoin == true"
                  (click)="onSelectPrivacy(); onDisabledCalendar('Join')"
                  value="Join"
                  >Join</mat-option
                >
                <mat-option
                  *ngIf="checkPrivate == true"
                  (click)="onSelectPrivacy(); onDisabledCalendar('Private')"
                  value="Private"
                  >Private</mat-option
                >
              </mat-select>
              <a
                *ngIf="check_privacy == true"
                style="color: red; font-size: 0.6rem"
                >Please enter a valid privacy</a
              >
            </mat-form-field>
            <!-- -------- Travel Date ------- -->
            <div class="date-travel">
              <div class="form-group">
                <span class="tittle-content">Date : </span>
                <input
                  [disabled]="information.privacy===null || information.privacy==='' || information.privacy===undefined"
                  type="text"
                  [(bsValue)]="bsInlineValue"
                  [bsConfig]="{
                    showWeekNumbers: false,
                    containerClass: 'theme-red',
                    dateInputFormat: 'DD/MM/YYYY',
                    isAnimated: true,
                    returnFocusToInput: true
                  }"
                  [ngClass]="{
                    'valid-input': !information.is_active_date
                  }"
                  placeholder="Select date"
                  [minDate]="minDate"
                  [maxDate]="maxDate"
                  class="form-control input-select-date"
                  bsDatepicker
                  [datesDisabled]="sortDisabledDates"
                  (bsValueChange)="callGetTour()"
                  readonly
                />
                <span class="valid-text" *ngIf="!information.is_active_date"> ** This tour is not operated on the selected ** </span>
              </div>
            </div>
            <!-- -------- Participants ------- -->
            <div class="border-form">
              <div class="participants-content">
                <!-- ---- Adult ---- -->
                <div class="row">
                  <div
                    class="col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center_"
                  >
                    <span class="participants-ages text-participants-size">
                      Adult :
                      <ng-container *ngIf="adult_price == 0">
                        <b>-</b>
                      </ng-container>
                      <ng-container *ngIf="adult_price > 0">
                        <b>{{ adult_price | number }}</b>
                      </ng-container>
                      <span class="per"> /
                        <small>Person</small>
                      </span>
                    </span>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <ngx-number-spinner
                      class="participants-pax"
                      [(value)]="information.adultpax"
                      (change)="onChangePax($event, 'Adult')"
                      [step]="1"
                      [min]="minAdult"
                      [max]="maxAdult"
                    ></ngx-number-spinner>
                  </div>
                </div>
                <hr />
                <!-- ---- Child ---- -->
                <div class="row">
                  <div
                    class="col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center_"
                  >
                    <span class="participants-ages text-participants-size">
                      Child :
                      <ng-container *ngIf="child_price == 0">
                        <b>-</b>
                      </ng-container>
                      <ng-container *ngIf="child_price > 0">
                        <b>{{ child_price | number }}</b>
                      </ng-container>
                      <span class="per"> /
                        <small>Person</small>
                      </span>
                    </span>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <ngx-number-spinner
                      class="participants-pax"
                      [(value)]="information.childpax"
                      (change)="onChangePax($event, 'Child')"
                      [step]="1"
                      [min]="minChild"
                      [max]="maxChild"
                    ></ngx-number-spinner>
                  </div>
                  <br />
                  <div class="col-12 remark">
                    <span>
                      * Child under 10 year and no higher than 120 cm</span
                    >
                  </div>
                </div>
                <hr />
                <!-- ---- Infant ---- -->
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <div class="text-center_">
                      <span class="participants-ages text-participants-size"
                        >Infant
                        <i
                          class="fa fa-info-circle"
                          aria-hidden="true"
                          style="color: #ff6961"
                          tooltip="Under 2 years : Free of Charge no seat providing"
                        ></i>
                        :
                      </span>
                      <!-- <span class="text-participants-size" >*Free</span> -->
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <ngx-number-spinner
                      class="participants-pax"
                      [(value)]="information.infantpax"
                      (change)="onChangeInfant($event)"
                      [step]="1"
                      [min]="0"
                      [max]="2"
                    ></ngx-number-spinner>
                  </div>
                  <div class="col-12 remark">
                    <span>
                      ** Under 2 years : Free of Charge no seat providing</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- ---------- Discount ---------- -->
            <div class="border-form discount">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter promotion code"
                  [(ngModel)]="information.promotion_code"
                  (input)="callGetPromotionCode()"
                  [disabled]="!information.privacy"
                />
                <div class="col-12 remark discount" *ngIf="information.is_active_discount">
                  <span>
                    Promotion code <i>{{ information.promotion_code }}</i> : Discount {{ information.discount | number }}{{ inputPromotionCode.is_percent == 1 ? '%' : 'THB' }}
                  </span>
                </div>
                <div class="col-12 remark discount" *ngIf="!information.is_active_discount && information.promotion_code">
                  <span [innerHTML]="promotionCodeMessage"></span>
                </div>
              </div>
            </div>

            <div class="blog-total-price">
              <div class="text-total">
                <span class="text-total-size float-left">TOTAL</span>
                <ng-container *ngIf="!information.is_active_discount">
                  <span class="text-total-size float-right">
                    <b>{{ information.total_price | number: "1.2-2" }}</b>
                  </span>
                </ng-container>
                <ng-container *ngIf="information.is_active_discount">
                  <span class="text-total-size discount float-right">
                    <strike>{{ information.total_price | number: "1.2-2" }}</strike>
                  </span>
                  <span class="text-total-size discount active float-right">
                    <b>{{ information.amount | number: "1.2-2" }}</b>
                  </span>
                  <br />
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ------------ Information Guest --------------- -->
      <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
        <div class="shadow p-3 mb-5 bg-white rounded">
          <div class="form-information">
            <div class="tittle-information">
              <span class="text-tittle">Information Guest</span>
            </div>
            <br />
            <!-- ------ First Name ------ -->
            <mat-form-field
              appearance="outline"
              class="example-full-width"
              style="width: 100%"
            >
              <mat-label>First Name *</mat-label>
              <input
                matInput
                [(ngModel)]="information.firstname"
                placeholder="First Name"
              />
              <a
                *ngIf="check_firstname == true"
                style="color: red; font-size: 0.6rem"
                >Please enter a valid firstname</a
              >
            </mat-form-field>
            <!-- ------ Last Name ------ -->
            <mat-form-field
              appearance="outline"
              class="example-full-width"
              style="width: 100%"
            >
              <mat-label>Last Name *</mat-label>
              <input
                matInput
                [(ngModel)]="information.lastname"
                placeholder="Last Name"
              />
              <a
                *ngIf="check_lastname == true"
                style="color: red; font-size: 0.6rem"
                >Please enter a valid lastname</a
              >
            </mat-form-field>
            <!-- ------ Email ------ -->
            <mat-form-field
              appearance="outline"
              class="example-full-width"
              style="width: 100%"
            >
              <mat-label>Email *</mat-label>
              <input
                matInput
                [(ngModel)]="information.email"
                placeholder="Email"
              />
              <a
                *ngIf="check_email == true"
                style="color: red; font-size: 0.6rem"
                >Please enter a valid email</a
              >
            </mat-form-field>
            <!-- ------ Phone ------ -->
            <mat-form-field
              appearance="outline"
              class="example-full-width"
              style="width: 100%"
            >
              <mat-label>Phone *</mat-label>
              <input
                matInput
                [(ngModel)]="information.phone"
                placeholder="Phone"
              />
              <a
                *ngIf="check_phone == true"
                style="color: red; font-size: 0.6rem"
                >Please enter a valid phone</a
              >
            </mat-form-field>
            <!-- ------ Hotel ------ -->
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Hotel *</mat-label>
              <mat-select [(value)]="information.hotel">
                <mat-option value="none">None</mat-option>
                <mat-option
                  *ngFor="let hotel of getaccountcode"
                  [value]="hotel.hotel"
                  >{{ hotel.hotel }}</mat-option
                >
                <mat-option value="other">Other</mat-option>
              </mat-select>
              <a
                *ngIf="check_hotel == true"
                style="color: red; font-size: 0.6rem"
                >Please enter a valid hotel</a
              >
            </mat-form-field>
            <!-- Other Hotel -->
            <mat-form-field
              *ngIf="information.hotel == 'other'"
              class="example-full-width"
              style="width: 100%"
              appearance="outline"
            >
              <mat-label>Your Hotel Name *</mat-label>
              <input matInput [(ngModel)]="information.hotel_other" />
            </mat-form-field>
            <!-- check term & condition -->
            <div style="text-align: center">
              <label>
                <input
                  type="checkbox"
                  (click)="onCheckTermAndCondition()"
                />
              </label>
              <span> I agree to the</span>
              <span
                style="padding-left: 10px; color: blue"
                class="pointer"
                (click)="toggleDialog(termDialog)"
                ><u>Terms and conditions</u>
              </span>
            </div>
          </div>
        </div>

        <!-- Save booking -->
        <div
          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 btn-continue"
        >
          <!-- <button class="btn btn-to-payment" [disabled]="checked" type="button">Continue to payment<i *ngIf="reload == true" class="fa fa-refresh fa-spin" style="font-size:18px; margin-left:10px;"></i></button> -->
          <button
            class="btn btn-success btn-to-payment pointer"
            [disabled]="checked"
            (click)="saveBooking(promotionCodeDialog)"
            type="button"
          >
            Continue to payment
            <i
              *ngIf="reload == true"
              class="fa fa-refresh fa-spin"
              style="font-size: 18px; margin-left: 10px"
            ></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- ---- Footer ---- -->
<!-- <app-footer></app-footer> -->

<ng-template #promotionCodeDialog>
  <div class="modal-header tab-term-bg">
    <span class="modal-title title-promotion-dialog">Promotion code is not available</span>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-right">
    <button
      class="btn btn-dark pointer"
      (click)="closeDialogPromotionCode()"
      type="button"
    >
      Close
    </button>
    <button
      class="btn btn-danger pointer ml-2"
      (click)="callSaveBooking()"
      type="button"
    >
      Continue to payment
    </button>
  </div>
</ng-template>


<!-- -------------- Model Ngx ------------------ -->
<!-- ---------- Term & Condition ------------------ -->
<ng-template #termDialog>
  <app-term-and-condition
    (onClose)="toggleTermDialog($event)"
    [isClose]="true"
  ></app-term-and-condition>
</ng-template>