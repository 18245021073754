<app-header></app-header>
<!-- <app-header-non-fixed></app-header-non-fixed> -->

<router-outlet
  (activate)="onActivate($event)"
  [class.browser_change]="browser_variable"
></router-outlet>
<ngx-scrolltop position="left" backgroundColor="#FF6961"></ngx-scrolltop>
<div
  class="fb-customerchat"
  page_id="676226475750588"
  greeting_dialog_display="show"
  size="standard"
></div>

<app-footer></app-footer>
<app-accept-cookies></app-accept-cookies>
<!-- greeting_dialog_display="hide" -->
<!-- <router-outlet [class.browser_change]="browser_variable"></router-outlet> -->
