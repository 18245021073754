<div class="container">
    <div class="blog-content">
        <div class="title">
          <div class="centered">
            <i class="fa fa-info-circle" style="font-size: 3rem; color: red;" aria-hidden="true"></i><br>
            <span class="fz-2">Sorry, this page doesn't exist.</span><br>
            <span class="fz-2">Please check the URL or go back a page.</span><br>
            <span class="fz-1">404 Error. Page Not Found.</span>
            <br>
           <br>
           <span class="web-link pointer" [routerLink]="['/']"><u>www.touringcenter.com</u></span>
           </div> 
        </div>
    </div>
</div>