<nav
  [class.navbar_change]="navbar_variable"
  class="navbar navbar-expand-lg navbar-expand-sm fixed-top index"
>
  <a class="img-logo-tc pointer" [routerLink]="['/']"></a>
  <button
    class="navbar-toggler"
    [class.menu_change]="menu_variable"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <i class="fa fa-bars menu" aria-hidden="true"></i>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="nav navbar-nav ml-auto">
      <li class="nav-item">
        <a
          [class.navbar_active]="'home' == getActiveMenu()"
          class="nav-link text-color pat-1 border-1"
          [routerLink]="['/']"
          (click)="setActivePage('home')"
          >Home<span class="sr-only">(current)</span></a
        >
      </li>
      <li class="nav-item">
        <a
          [class.navbar_active]="'about' == getActiveMenu()"
          class="nav-link text-color pat-1 border-1"
          [routerLink]="['/about']"
          (click)="setActivePage('about')"
          >About</a
        >
      </li>
      <li class="nav-item">
        <a
          [class.navbar_active]="'tours' == getActiveMenu()"
          class="nav-link text-color pat-1 border-1"
          [routerLink]="['/tours']"
          (click)="setActivePage('tours')"
          >Tours</a
        >
      </li>
      <li class="nav-item">
        <a
          [class.navbar_active]="'promotions' == getActiveMenu()"
          class="nav-link text-color pat-1"
          [routerLink]="['/promotions']"
          (click)="setActivePage('promotions')"
          >Promotions</a
        >
      </li>
      <li class="nav-item">
        <a
          [class.navbar_active]="'events' == getActiveMenu()"
          class="nav-link text-color pat-1"
          [routerLink]="['/events']"
          (click)="setActivePage('events')"
          >Event</a
        >
      </li>
      <li class="nav-item">
        <a
          [class.navbar_active]="'activities' == getActiveMenu()"
          class="nav-link text-color pat-1 border-1"
          [routerLink]="['/activities']"
          (click)="setActivePage('activities')"
          >Activities</a
        >
      </li>
      <li class="nav-item">
        <a
          [class.navbar_active]="'contact' == getActiveMenu()"
          class="nav-link text-color pat-1 border-1"
          [routerLink]="['/contact']"
          (click)="setActivePage('contact')"
          >Contact</a
        >
      </li>
    </ul>
  </div>
</nav>

<!-- <app-affiliate></app-affiliate> -->
