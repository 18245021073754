import { Injectable } from "@angular/core";
import { ApiServiceService } from "./api-service.service";
import { Observable, of } from "rxjs";
import { EnvService } from "src/app/services/env.service";

@Injectable({
  providedIn: "root",
})
export class PromotionCodeService {
  private path = "website/promotion/code";
  private configs: any = {};

  constructor(
    private service: ApiServiceService,
    private envService: EnvService,  
  ) {
    let config = this.envService.getConfig();
    this.configs = config;
  }

  getPromotionCode(payload: any): Observable<any> {
    return this.service.callPost(`${this.path}`, payload);
  }

}