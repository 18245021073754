<div class="container">
    <div class="blog-content">
        <div class="title">
            <div class="centered">
                <i class="fa fa-info-circle" style="font-size: 3rem; color: red;" aria-hidden="true"></i><br>
                <span class="fz-2">Sorry, this Internet Browser Not Support.</span><br>
                <span class="fz-2">Please copy link or open on google browser/google chrome/safari.</span><br>
                <span class="fz-1">Thank You.</span>
                <br>
            </div>
        </div>
    </div>
</div>