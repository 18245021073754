import { Component, OnInit } from '@angular/core';
import * as Rellax from 'rellax';
import { Http, RequestOptions } from '@angular/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { map } from "rxjs/operators";


@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {

  public partners =  <any>'';
  public partners_show =  <any>'';
  public partners_  : Array<any> = [];
  public partners__ =  <any>'';
  public partner_logo =  <any>'';


  public font = [ 'ALL','0-9','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];

  constructor(
    private http: Http
  ) { }

  ngOnInit(): void {
    this.getHotelPartners();
    this.getLogopartner();
  }

  getHotelPartners(){
    let url = "./assets/json/hotel_.json";

            return this.http.get(url)
                .pipe(map(res => res.json()))
                .toPromise()
                .then(
                  data => [
                      this.partners = data,
                      this.partners_show = this.partners,
                      // console.log(this.partners)
                  ],
                  err => [
                    console.log(err)
                  ]
                ).then(()=>{
                      // -------- list hotel ----------
                      // for(let i = 0; i <= this.partners.length; i++){
                      //       this.partners__[i] = this.partners[i].list_hotel;
                      // }
                      // console.log(this.partners__);

                      // **** check partner.length - 1
                });
  }

  searchList(value: string){
      // console.log(value);
      this.partners_ = [];
        // --- check ---
        for(let i = 0; i <= this.partners.length-1; i++){
              if(this.partners[i].hotel[0] == value){
                    this.partners_.push(this.partners[i]);
              }
              if( value == 'ALL' ){
                this.partners_ = this.partners;
              }
        }
        if( value == '0-9'){
          this.partners_.push(this.partners[0]);
          this.partners_.push(this.partners[1]);
          this.partners_.push(this.partners[2]);
        }
        this.partners_show = this.partners_;
        // console.log(this.partners_);

        this.getLogopartner();
  }

  getLogopartner(){
    let url = "./assets/json/partner.json";

            return this.http.get(url)
                .pipe(map(res => res.json()))
                .toPromise()
                .then(
                  data => [
                      this.partner_logo = data,
                      // console.log(this.partner_logo)
                  ],
                  err => [
                    console.log(err)
                  ]
                ).then(()=>{
                });
  }


}
