<!-- <app-header></app-header> -->
<!-- ------- Cover Images Tours Page ---------- -->
<section
  [class.browser_change]="browser_variable"
  class="py-5 module parallax parallax-1"
  data-rellax-speed="1.5"
>
  <div class="centered_" id="listcategories">
    <span class="tittle-page">Tours</span><br />
  </div>
  <!-- <div class="centered___ form-inline title-sub">
        <span> Chiang Mai &bull;</span>
        <span> Chiang Rai &bull;</span>
        <span> Lampang</span>
    </div> -->
</section>
<!-- --------------------- Tours---------------------------- -->

<section>
  <div class="container">
    <div class="blog-search"></div>
  </div>
</section>

<section>
  <div class="for-xl-size">
    <div class="row">
      <div class="col-sm-3 col-md-5 col-lg-3 col-xl-3">
        <div class="card" data-aos="fade-up" data-aos-duration="800">
          <div class="card-body card-categories">
            <div class="title-categories">TOURS</div>
            <mat-selection-list
              [(ngModel)]="tour_categories"
              [multiple]="false"
              style="padding-top: 0"
              (click)="changeListCategories()"
            >
              <!-- Default -->
              <div *ngIf="actives_default == true">
                <mat-list-option
                  class="border-1"
                  *ngFor="let categories of typesOfcategories; let i = index"
                  [value]="categories"
                  [selected]="categories && i == 0"
                >
                  <div class="list-categories">
                    <span class="font-list-categories"
                      ><i
                        class="fa fa-caret-square-o-right"
                        style="color: #ed1c24"
                        aria-hidden="true"
                      ></i>
                      {{ categories }}</span
                    >
                  </div>
                </mat-list-option>
              </div>
              <!-- R -->
              <div *ngIf="actives_r == true">
                <mat-list-option
                  class="border-1"
                  *ngFor="let categories of typesOfcategories; let i = index"
                  [value]="categories"
                  [selected]="categories && i == 4"
                >
                  <div class="list-categories">
                    <span class="font-list-categories"
                      ><i
                        class="fa fa-caret-square-o-right"
                        style="color: #ed1c24"
                        aria-hidden="true"
                      ></i>
                      {{ categories }}</span
                    >
                  </div>
                </mat-list-option>
              </div>
              <!-- E -->
              <div *ngIf="actives_e == true">
                <mat-list-option
                  class="border-1"
                  *ngFor="let categories of typesOfcategories; let i = index"
                  [value]="categories"
                  [selected]="categories && i == 5"
                >
                  <div class="list-categories">
                    <span class="font-list-categories"
                      ><i
                        class="fa fa-caret-square-o-right"
                        style="color: #ed1c24"
                        aria-hidden="true"
                      ></i>
                      {{ categories }}</span
                    >
                  </div>
                </mat-list-option>
              </div>
              <!-- Special -->
              <div *ngIf="actives_s == true">
                <mat-list-option
                  class="border-1"
                  *ngFor="let categories of typesOfcategories; let i = index"
                  [value]="categories"
                  [selected]="categories && i == 6"
                >
                  <div class="list-categories">
                    <span class="font-list-categories"
                      ><i
                        class="fa fa-caret-square-o-right"
                        style="color: #ed1c24"
                        aria-hidden="true"
                      ></i>
                      {{ categories }}</span
                    >
                  </div>
                </mat-list-option>
              </div>
            </mat-selection-list>
          </div>
        </div>
        <br />
      </div>

      <div class="col-sm-9 col-md-7 col-lg-9 col-xl-9">
        <div class="row">
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            class="col-sm-12 col-md-10 col-lg-6 col-xl-4 pointer"
            *ngFor="
              let tours of Tours_List
                | paginate: { itemsPerPage: 9, currentPage: pagine }
            "
          >
            <div
              class="card card-show shadow color-3"
              (click)="routeTour(tours.tour)"
            >
              <div *ngIf="tours.image_cover !== null">
                <img
                  class="card-img-top img-tours-view"
                  [src]="tours.image_cover"
                  alt="Card image cap"
                />
              </div>
              <div *ngIf="tours.image_cover == null">
                <img
                  class="card-img-top img-tours-view"
                  [src]="imgCoverPath"
                  alt="Card image cap"
                />
              </div>
              <div class="card-body">
                <p class="card-text text-tittle-tour tours-name">
                  {{ tours.title
                  }}<span class="tours-code"> [{{ tours.tour }}]</span>
                </p>
                <p class="tours-concept">"{{ tours.concept }}"</p>
                <hr style="visibility: hidden" />
                <div class="blog-price">
                  <div class="review">
                    <span class="card-text-review color-2">From</span><br />
                    <span
                      class="card-text-review hidd__ color-2"
                      *ngIf="tours.price != null"
                      >From</span
                    >
                    <span
                      class="card-text-review color-1"
                      *ngIf="tours.price == null && tours.price_private != null"
                      ><small>(Private price)</small></span
                    >
                  </div>
                  <div class="price">
                    <span class="card-text-more color-2">
                      <span
                        class="color-1 text-price"
                        *ngIf="tours.price != null"
                      >
                        {{ tours.price | number }}
                      </span>
                      <span
                        class="color-1 text-price"
                        *ngIf="
                          tours.price == null && tours.price_private != null
                        "
                      >
                        {{ tours.price_private | number }}
                      </span>
                      <span
                        class="color-1 text-price"
                        *ngIf="
                          tours.price == null && tours.price_private == null
                        "
                      >
                        N/A
                      </span>
                      THB </span
                    ><br />
                    <span class="per-person color-2"> per person</span>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
        <br />
        <br />
        <div class="text-center pagination-tour">
          <pagination-controls
            (pageChange)="pagine = $event"
            [ngxScrollTo]="'listcategories'"
          ></pagination-controls>
        </div>
      </div>
    </div>
    <br />
  </div>
</section>

<!-- ------- Footter ---------- -->
<!-- <app-footer></app-footer> -->
