<section
  [class.browser_change]="false"
  class="py-5 module parallax parallax-1"
  data-rellax-speed="1.5"
>
  <div class="centered_" id="listcategories">
    <span class="tittle-page">Event</span><br />
  </div>
</section>

<section>
  <div class="container">
    <div class="blog-search"></div>
  </div>
</section>

<section>
  <div class="for-xl-size">
    <div class="container">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="row">
          <ng-container *ngFor="let item of events">
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              class="col-sm-12 col-md-6 col-lg-4 col-xl-4 pointer"
            >
              <div
                class="card card-show shadow color-3"
                (click)="router.navigate(['event', item?.id])"
              >
                <div>
                  <img
                    class="card-img-top img-events-view"
                    [src]="item?.image_cover"
                    alt="Card image cap"
                  />
                </div>
                <div class="card-body">
                  <p class="card-text text-tittle-event events-name">
                    {{ item?.title }}
                    <ng-container *ngIf="item?.code">
                      <span class="events-code"> [{{ item?.code }}]</span>
                    </ng-container>
                  </p>
                  <p class="events-concept" [innerHTML]="item?.short_description"></p>
                  <hr style="visibility: hidden" />
                  <div class="blog-price">
                    <div class="review">
                      <span class="card-text-review color-2">From</span><br />
                      <span
                        class="card-text-review hidd__ color-2"
                      >From</span>
                    </div>
                    <div class="price">
                      <span class="card-text-more color-2">
                        <span
                          class="color-1 text-price"
                        >
                          {{ item?.types[0]?.adult_price | number }}
                        </span>
                        THB </span>
                      <br />
                      <span class="per-person color-2"> per person</span>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </ng-container>
        </div>

        <ng-container>
          <div class="text-center pagination-event mt-5">
            <!-- <pagination-controls
              (pageChange)="pagine = $event"
              [ngxScrollTo]="'listEvents'"
            ></pagination-controls> -->
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>