<div class="modal-header tab-term-bg">
  <span class="modal-title tab-term-font">Terms & Conditions</span>
  <button
    *ngIf="isClose"
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!-- Children policy -->
  <div class="tab-bg">
    <span class="tab-font">CHILDREN POLICY</span>
  </div>
  <div class="term-title">
    <div class="detail-term">
      <ul>
        <li>There are no age restrictions for attendance.</li>
        <li>Children under 8 years old can join free of charge.</li>
        <li>Children aged 8 and above will be charged the same rate as adult tickets.</li>
        <li>Child seating is available with parent seating.</li>
        <li>No additional lanterns will be provided for children</li>
        <li>Souvenirs will not be provided for children.</li>
        <li>Children are welcome to have dinner with their parents.</li>
        <li>Parents must take full responsibility for their child's care and supervision.</li>
        <li>Please adhere to our security policy and help maintain a peaceful atmosphere for all participants, avoiding any noise disturbance.</li>
      </ul>
    </div>
  </div>
  <hr />

  <!-- Payment Policy -->
  <div class="tab-bg">
    <span class="tab-font">PAYMENT POLICY AND METHODS</span>
  </div>
  <div class="term-title">
    <div class="detail-term">
      <h4>1. Payment Policy:</h4>
      <ul>
        <li>All purchase and transactions must be paid in full after booking is made</li>
        <li>Payment must be made in the currency specified at the time of purchase.</li>
        <li>Unpaid reservation is subject to cancellation if payment is not received within the specified time frame.</li>
      </ul>

      <h4>2. Payment Methods:</h4>
      <ul>
        <li>Credit card: we accept major credit cards, providing a secure and seamless online payment experience.</li>
        <li>Online Payment Gateways: Our website integrates with trusted online payment gateways for safe and efficient transactions.</li>
        <li>Bank Transfers: Bank transfer details will be provided upon request for those who prefer this methos. Please note that bank charges or fees associated with the transfer are the responsibility of the customer.</li>
        <li>Cash Payment (In-Person): For certain in-person transactions, cash payments may be accepted. Please inquire with our staff for details.</li>
      </ul>

      <h4>3. Other Policy</h4>
      <ul>
        <li>Crones are strictly prohibited within the event premises.</li>
        <li>Smoking is not allowed within the event premises, including indoor and outdoor areas.</li>
        <li>Bringing outside alcoholic beverages onto the event premises is strictly prohibited.</li>
      </ul>
    </div>
  </div>
  <hr />

  <!-- Cancellation Policy -->
  <div class="tab-bg">
    <span class="tab-font">CANCELLATION POLICY</span>
  </div>
  <div class="term-title">
    <div class="detail-term">
      <ul>
        <li>All sales are final.</li>
        <li>After reservation the ticket and paid are non-refundable and unable to process any refunds or exchanges for tickets under any circumstances.</li>
        <li>The unused feature is non-refundable.</li>
        <li>No Show is non-refundable.</li>
      </ul>
    </div>
  </div>
  <hr />

  <!-- Liabilities -->
  <ng-container *ngIf="liabilities && liabilities?.title">
    <div class="tab-bg">
      <span class="tab-font">{{ 'Liabilities'.toUpperCase() }}</span>
    </div>
    <div class="term-title">
      <div class="detail-term">
        <br>
        <p>{{liabilities?.title }}</p>
        <ng-container *ngIf="liabilities?.details">
          <br />
          <div *ngFor="let item of liabilities?.details; index as i">
            <h4><b>{{i+1}}. {{ item?.title }}</b></h4>
            <div class="pl-3">
              <p [innerHTML]="item?.detail"></p>
            </div>
            <br/>
          </div>
        </ng-container>
      </div>
    </div>
    <hr />
  </ng-container>

</div>