import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-term-and-condition-event',
  templateUrl: './terms-and-conditions-event.component.html',
  styleUrls: ['./terms-and-conditions-event.component.scss']
})
export class TermsAndConditionsEventComponent implements OnInit {
  @Input() isClose: boolean = false;
  @Input() liabilities: any = {};

  @Output() onClose = new EventEmitter;

  public date_now = new Date();
  public year_now = this.date_now.getFullYear();

  constructor() { }

  ngOnInit(): void {
  }

  close(): void {
    this.onClose.emit(true);
  }

}
