import { Component, OnInit, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { DatePipe } from "@angular/common";
import { ApiServiceService } from "./../../../services/api-service.service";
import { DayCode } from "./../../../enum/enum";
import { displayDate } from "./../../../helpers";

@Component({
  selector: "app-booking-tours",
  templateUrl: "./booking-tours.component.html",
  styleUrls: ["./booking-tours.component.scss"],
})
export class BookingToursComponent implements OnInit {
  public date_now = new Date();
  public year_now = this.date_now.getFullYear();
  public next_year = this.date_now.getFullYear()+1;

  // <<< Start Variable >>>
  public html: string = `
    <span class="btn btn-danger">Never trust not sanitized HTML!!!</span>
  `;
  public disabledDates = [
    // new Date(this.dates),
    // new Date('2020-11-09')
  ];
  public sortDisabledDates = [];

  public getaccountcode = <any>"";
  public modalRef: BsModalRef;
  public checked: boolean = true;

  public currentDate = new Date();
  public minDate = this.currentDate;
  public maxDate = new Date((this.currentDate.getFullYear()+1)+"-12-30");

  public bsInlineValue = new Date();
  public bsInlineValue_ = <any>"";
  public savebooking = <any>"";
  public getTourPrice_ = <any>"";
  public reload: boolean = false;

  public minTime: Date = new Date();
  public maxTime: Date = new Date();
  public bsInlineMinDate: Date = new Date();
  // public bsInlineMaxDate: Date = new Date("2020-10-31");

  public pickup_time: Date = new Date();
  public labelPosition = <any>"";

  public adult_price: number = <any>0;
  public child_price: number = <any>0;

  public close_tour_list: any = [];
  public holiday_tour: any = [];
  public holiday_date = [];

  public checkJoin: boolean = true;
  public checkPrivate: boolean = true;

  public check_firstname: boolean = false;
  public check_lastname: boolean = false;
  public check_email: boolean = false;
  public check_phone: boolean = false;
  public check_hotel: boolean = false;
  public check_time: boolean = false;
  public check_privacy: boolean = false;

  public minvans = <any>"";
  public maxvans = <any>"";
  public minguests = <any>"";
  public maxguests = <any>"";
  public minguides = <any>"";
  public maxguides = <any>"";
  public vans = <any>"";
  public guest = <any>"";
  public vans_price = <any>"";
  public guides_price = <any>"";

  public aff_id = <any>"";

  public information = {
    pax: 0,
    adultpax: 2,
    childpax: 0,
    infantpax: 0,
    total_price: <any>"0.00",
    privacy: <any>"",
    tourtime: <any>"",
    date_traveling: <any>"",
    firstname: <any>"",
    lastname: <any>"",
    email: <any>"",
    phone: <any>"",
    hotel: <any>"",
    hotel_other: <any>"",
    vans: <any>"",
    guides: <any>"",
    van_price: <any>"",
    guide_price: <any>"",
    total_vans_price: <any>"",
    total_guides_price: <any>"",
    pickup_time: <any>"",
    guests: <any>"",
    is_active_date: true,
    promotion_code: "",
    discount: 0,
    discount_rate: "",
    discount_price: 0,
    is_active_discount: false,
    amount: 0,
  };

  public pax: any = 0;
  public maxPax: any = 9;
  public maxAdult = <any>"";
  public maxChild = <any>"";
  public minAdult = <any>"";
  public minChild = <any>"";
  // <<< End Variable >>>

  public inputPromotionCode: any = {};
  public isSave: boolean = false;
  public dialogPromotionCode: TemplateRef<any>;

  public promotionCodeMessage: string = "Promotion code is not available";

  constructor(
    private router: Router,
    private modalService: BsModalService,
    private apiService: ApiServiceService,
    public datepipe: DatePipe,
  ) {
    this.maxAdult = 9;
    this.maxChild = 7;
    this.minAdult = 2;
    this.minChild = 0;

    this.minguests = 0;
    this.maxguests = 9;
    this.minvans = 1;
    this.maxvans = 5;

    this.labelPosition = 0;
    this.minTime.setHours(6);
    this.minTime.setMinutes(0);
    this.maxTime.setHours(10);
    this.maxTime.setMinutes(0);
    this.pickup_time.setHours(6);
    this.pickup_time.setMinutes(0);

    this.vans = 1;
    this.information.vans = 1;
    this.information.guests = 9;
    this.information.guides = 0;

    this.maxguides = this.information.vans;
    this.minguides = 1;

    this.bsInlineMinDate.setDate(this.bsInlineMinDate.getDate() + 3);
  }

  // <<< Initial >>>
  ngOnInit(): void {
    let getDate = new Date();
    getDate.setDate(getDate.getDate() + 2);
    this.minDate = getDate;
    this.bsInlineValue = this.minDate

    this.callGetTour();
    this.callJsonGetAccountHotelCode();
    this.callGetCloseTourPerDay();
    this.callHolidayByTourCode();

    // this.setDateTravelDefault();
    this.getAffId();
  }

  // <<< -----------------------------------------
  // ------------------- Call API ----------------
  // ----------------------------------------- >>>
  // get tour
  callGetTour(): void {
    const url = "Website/Tour/GetTours";
    let tourcode = sessionStorage.getItem("Tour_code");

    let setPayload = {
      TourCode: tourcode,
      date: this.bsInlineValue
    };

    this.apiService.callPost(url, setPayload).subscribe(
      (result) => [
        (this.getTourPrice_ = result.data),
        (this.onCheckTourPrices(this.getTourPrice_)),
        this.onSelectPrivacy()
      ],
      // (err) => [console.log(err)]
    );
  }
  // save booking
  callSaveBooking(): void {
    this.reload = true;
    const url = "Website/Booking/SaveBooking";
    let setPayload = this.setSaveBookingPayload();

    // console.log("Save booking : ", setPayload);
    this.apiService.callPost(url, setPayload).subscribe(
      (result) => [(this.savebooking = result), this.checkedSavebooking()],
      (err) => [console.log(err), this.reload = false]
    );

    // this.toggleDialog(this.dialogPromotionCode, false);
    if (this.dialogPromotionCode) {
      this.modalRef.hide();
    }
    this.dialogPromotionCode = null;
    this.isSave = false;
  }
  // get close tour
  callGetCloseTourPerDay(): void {
    const url = "Tour/GetCloseTourPerDay";
    let tourcode = sessionStorage.getItem("Tour_code");
    let setPayload = {
      tour_code: tourcode,
    };

    this.apiService.callPost(url, setPayload).subscribe(
      (result) => [(this.close_tour_list = result.data)],
      // (err) => [console.log(err)]
    );
  }
  // get holiday by tour (code)
  callHolidayByTourCode(): void {
    // const url = "Reservation/ManageGetHolidaysbyID";
    const url = "Website/Booking/GetTourByHoliday";
    let tourcode = sessionStorage.getItem("Tour_code");
    let setPayload = {
      TourCode: tourcode,
    };

    this.apiService.callPost(url, setPayload).subscribe(
      (result) => [
        (this.holiday_tour = result.data),
        this.onDisabledCalendar(),
      ],
      // (err) => [console.log(err)]
    );
  }

  // <<< -----------------------------------------
  // ------------------- Call JSON ---------------
  // ----------------------------------------- >>>
  // get account code
  callJsonGetAccountHotelCode(): void {
    const url = "account_code/account_Hotel.json";
    this.apiService.callGetJSON(url).subscribe(
      (result) => [(this.getaccountcode = result)],
      // (err) => [console.log(err)]
    );
  }

  // <<< -----------------------------------------
  // ------------------- On event ----------------
  // ----------------------------------------- >>>
  onCheckTourPrices(params: any): void {
    if(params && params.price.join.adult){
      this.checkJoin = true;
    }else{
      this.checkJoin = false;
    }

    if(params && params.price.private.length > 0){
      this.checkPrivate = true;
    }else{
      this.checkPrivate = false;
    }
  }
  // select privacy
  onSelectPrivacy() {
    if (this.information.privacy == "Join") {
      this.minAdult = 2;
      this.information.adultpax = this.information.adultpax == 1 ? 2 : this.information.adultpax;
      this.adult_price = this.getTourPrice_.price.join.adult;
      this.child_price = this.getTourPrice_.price.join.child;
      this.onChangeAdult(this.information.adultpax);
      this.onChangeChild(this.information.childpax);
    } else if (this.information.privacy == "Private") {
      this.minAdult = 1;
      this.adult_price = this.getTourPrice_.price.private[0]?.adult || 0;
      this.child_price = this.getTourPrice_.price.private[0]?.child || 0;
      this.onChangeAdult(this.information.adultpax);
      this.onChangeChild(this.information.childpax);
    }

    this.onDisabledCalendar();
    // this.callHolidayByTourCode();
    // this.onCheckDiscount();
    this.callGetPromotionCode();
  }

  // change pax
  onChangePax(value: number, name: string): void {
    let maximum = 9;

    if(name === "Adult"){
      if(value <= this.maxAdult){
        this.information.adultpax = value;
      }
    }else{
      if(value <= this.maxChild){
        this.information.childpax = value;
      }
    }
    this.maxAdult = maximum - this.information.childpax;
    this.maxChild = maximum - this.information.adultpax;

    // this.information.pax = this.information.adultpax + this.information.childpax;
    this.information.pax = this.information.adultpax;

    this.onChangeAdult(this.information.adultpax);
    // this.onChangeChild(this.information.childpax);
  }

  // change adult
  onChangeAdult(value: number): void {
    if (this.information.adultpax == 1 && this.information.childpax == 0) {
      this.information.adultpax = this.information.privacy == "Join" ? 2 : this.information.adultpax;
    }

    if (this.information.privacy == "Join") {
      this.adult_price = this.getTourPrice_.price.join.adult;
      this.child_price = this.getTourPrice_.price.join.child;
      if (this.information.childpax == 1 && this.information.adultpax == 1) {
        this.child_price = this.getTourPrice_.price.join.adult;
      } else if (
        this.information.adultpax >= 2 &&
        this.information.adultpax <= 3
      ) {
        this.adult_price = this.getTourPrice_.price.join.adult;
        this.child_price = this.getTourPrice_.price.join.child;
      }
    }

    if (this.information.privacy == "Private") {
      if (this.information.adultpax == 1 && this.information.childpax == 0) {
        this.adult_price = this.getTourPrice_.price.private[0].adult;
        this.child_price = 0;
      } else if (this.information.adultpax == 1 && this.information.childpax == 1) {
        this.adult_price = this.getTourPrice_.price.private[0].adult;
        this.child_price = this.getTourPrice_.price.private[0].adult;
      } else if (this.information.adultpax == 1 && this.information.childpax > 1) {
        this.adult_price = this.getTourPrice_.price.private[0].adult;
        this.child_price = this.getTourPrice_.price.private[0].child;
      } else if (
        this.information.pax >= 2 &&
        this.information.pax <= 3
      ) {
        this.adult_price = this.getTourPrice_.price.private[0].adult;
        this.child_price = this.getTourPrice_.price.private[0].child;
      } else if (
        this.information.pax >= 4 &&
        this.information.pax <= 5
      ) {
        this.adult_price = this.getTourPrice_.price.private[1].adult;
        this.child_price = this.getTourPrice_.price.private[1].child;
      } else if (
        this.information.pax >= 6 &&
        this.information.pax <= 9
      ) {
        this.adult_price = this.getTourPrice_.price.private[2].adult;
        this.child_price = this.getTourPrice_.price.private[2].child;
      }
    }

    if (this.information.adultpax == 1 && this.information.childpax == 0) {
      this.adult_price = this.adult_price * 2
    }

    this.information.total_price =
      this.information.adultpax * this.adult_price +
      this.information.childpax * this.child_price;

    this.onCheckDiscount();
  }
  // change child
  onChangeChild(value: number): void {
    if (this.information.privacy == "Join") {
      this.adult_price = this.getTourPrice_.price.join.adult;
      this.child_price = this.getTourPrice_.price.join.child;
      if (this.information.childpax == 1 && this.information.adultpax == 1) {
        this.child_price = this.getTourPrice_.price.join.adult;
      } else if (
        this.information.adultpax >= 2 &&
        this.information.adultpax <= 3
      ) {
        this.adult_price = this.getTourPrice_.price.join.adult;
        this.child_price = this.getTourPrice_.price.join.child;
      }
    }

    if (this.information.privacy == "private") {
      if (this.information.adultpax == 1 && this.information.childpax == 0) {
        this.adult_price = this.getTourPrice_.price.private[0].adult;
        this.child_price = 0;
      } else if (this.information.adultpax == 1 && this.information.childpax == 1) {
        this.adult_price = this.getTourPrice_.price.private[0].adult;
        this.child_price = this.getTourPrice_.price.private[0].adult;
      } else if (this.information.adultpax == 1 && this.information.childpax > 1) {
        this.adult_price = this.getTourPrice_.price.private[0].adult;
        this.child_price = this.getTourPrice_.price.private[0].child;
      } else if (
        this.information.pax >= 2 &&
        this.information.pax <= 3
      ) {
        this.adult_price = this.getTourPrice_.price.private[0].adult;
        this.child_price = this.getTourPrice_.price.private[0].child;
      } else if (
        this.information.pax >= 4 &&
        this.information.pax <= 5
      ) {
        this.adult_price = this.getTourPrice_.price.private[1].adult;
        this.child_price = this.getTourPrice_.price.private[1].child;
      } else if (
        this.information.pax >= 6 &&
        this.information.pax <= 9
      ) {
        this.adult_price = this.getTourPrice_.price.private[2].adult;
        this.child_price = this.getTourPrice_.price.private[2].child;
      }
    }

    if (this.information.adultpax == 1 && this.information.childpax == 0) {
      this.adult_price = this.adult_price * 2
    }

    this.information.total_price =
      this.information.adultpax * this.adult_price +
      this.information.childpax * this.child_price;

    this.onCheckDiscount();
  }
  // change infant
  onChangeInfant(value: number): void {
    this.information.infantpax = value;
  }
  // on check term & condition
  onCheckTermAndCondition() {
    if (this.checked == false) {
      this.checked = true;
    } else if (this.checked == true) {
      this.checked = false;
    }
  }
  // on disabled calendar
  onDisabledCalendar(privacy: any=null) {
    this.disabledDates = [];

    //! Set close tour : API holiday
    this.closeTourHoliday();

    //! Set close tour : API close tour
    this.close_tour_list.forEach((item) => {
      this.disabledDates.push(item);
    });

    //! Fix close book in advance 3 days
    // let advanceDate = new Date();
    for(let i = 0; i <= 1; i++){
      let getDate = new Date();
      getDate.setDate(getDate.getDate()+i);

      this.disabledDates.push(this.displayDateServer(getDate));
    }

    //! Fix close tour : Days of week
    if(this.information.privacy == "Join"){
      this.fixCloseTourJoin();
    }else if(this.information.privacy == "Private"){
      this.fixCloseTourPrivate();
    }

    //! Fix close tour : New years all
    let fixDate = new Date(this.next_year + "-01-02");
    fixDate = new Date(
      fixDate.getFullYear(),
      fixDate.getMonth(),
      fixDate.getDate()
    );
    this.disabledDates.push(
      fixDate.getFullYear()+
      "-"+
      (fixDate.getMonth() + 1).toString().padStart(2, '0')+
      "-"+
      fixDate.getDate().toString().padStart(2, '0')
    );

    //! +++ Change format string to date +++
    let newDisabledDates = [];
    this.disabledDates.forEach((item: any) => {
      newDisabledDates.push(new Date(item));
    });

    // sort date
    let sortDisabledDates = [];
    newDisabledDates = newDisabledDates.sort(function(a,b){return a.getTime() - b.getTime()});
    newDisabledDates.forEach((item: any) => {
      sortDisabledDates.push(
        item.getFullYear()+
        "-"+
        (item.getMonth() + 1).toString().padStart(2, '0')+
        "-"+
        item.getDate().toString().padStart(2, '0')
      );
    });

    // set first date for booking
    let bookingDateStringList = [];
    let bookingDateList = this.getDateListByYears();
    bookingDateList.forEach((item: any) => {
      bookingDateStringList.push(item.getFullYear()+
              "-"+
              (item.getMonth() + 1).toString().padStart(2, '0')+
              "-"+
              item.getDate().toString().padStart(2, '0'));
    });

    let differenceDate = bookingDateStringList.filter(x => !sortDisabledDates.includes(x));

    let setDifferenceDate = [];
    if(differenceDate.length > 0){
      differenceDate.forEach((item: any) => {
        setDifferenceDate.push(new Date(item));
      });
    }

    if(sortDisabledDates.length > 0){
      setDifferenceDate = setDifferenceDate.filter(
        (r) => r >= this.bsInlineValue
        // (r) => r >= new Date()
      );
      // console.log("Default date : ", setDifferenceDate[0], this.bsInlineValue);
      this.bsInlineValue = setDifferenceDate[0] || this.bsInlineValue;
    }

    //
    // this.setDateTravelDefault(this.bsInlineValue);

    // set disabled date for use
    this.sortDisabledDates = [];
    sortDisabledDates.forEach((item: any) => {
      this.sortDisabledDates.push(new Date(item));
    });
    // console.log("sortDisabledDates date : ", this.sortDisabledDates);

  }

  // Close tour by holiday
  closeTourHoliday(): void {
    //! Close by api holiday
    if(this.information.privacy === null){
      // privacy
      this.holiday_tour.forEach(item => {
        if(item.privacy=="All"){
          // holiday
          item.privacies.forEach(value => {
            if(value.holidaysDate){
              // holiday date
              value.holidaysDate.forEach(val => {
                // this.disabledDates.push(new Date(val));
                this.disabledDates.push(val);
              });
            }else{
              this.disabledDates = [];
            }
          });
        }
      });
    }else{ // On select privacy
      // privacy
      this.holiday_tour.forEach(item => {
        if(item.privacy == this.information.privacy){

          // holiday
          item.privacies.forEach(value => {
            if(value.holidaysDate){
              // holiday date
              value.holidaysDate.forEach(val => {
                // this.disabledDates.push(new Date(val));
                this.disabledDates.push(val);
              });
            }else{
              this.disabledDates = [];
            }
          });
        }
      });
    }
  }

  // toggle modal
  toggleDialog(dialog: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      dialog,
      Object.assign(
        {},
        { class: "gray modal-lg modal-dialog-centered", centered: true }
      )
    );    
  }

  // <<< -----------------------------------------
  // ---------------- Save booking ---------------
  // ----------------------------------------- >>>
  // validate field
  saveBooking(dialog: TemplateRef<any> = null) {
    if (dialog) {
      this.dialogPromotionCode = dialog;
    }

    // Check disabled date
    let checkDisableDate = this.sortDisabledDates.filter(
      (r) => this.displayDateServer(r) == this.displayDateServer(this.bsInlineValue)
    )

    if(checkDisableDate && checkDisableDate.length > 0){
      this.information.is_active_date = false;
    }else{
      this.information.is_active_date = true;
    }

    if (
      this.information.firstname !== "" &&
      this.information.email !== "" &&
      this.information.lastname !== "" &&
      this.information.phone !== "" &&
      this.information.hotel !== "" &&
      this.information.tourtime !== "" &&
      this.information.tourtime !== null &&
      this.information.tourtime !== undefined &&
      this.information.privacy !== "" &&
      this.information.privacy !== null &&
      this.information.privacy !== undefined &&
      this.information.is_active_date === true
    ) {
      if (this.inputPromotionCode.discount && this.inputPromotionCode.is_active) {
        this.isSave = true;
        this.callGetPromotionCode();
      } else {
        this.dialogPromotionCode = null;
        this.callSaveBooking();
      }
    }
    if (
      this.information.firstname == "" ||
      this.information.firstname == null ||
      this.information.firstname == undefined
    ) {
      this.check_firstname = true;
    } else {
      this.check_firstname = false;
    }
    if (
      this.information.lastname == "" ||
      this.information.lastname == null ||
      this.information.lastname == undefined
    ) {
      this.check_lastname = true;
    } else {
      this.check_lastname = false;
    }
    if (
      this.information.email == "" ||
      this.information.email == null ||
      this.information.email == undefined
    ) {
      this.check_email = true;
    } else {
      this.check_email = false;
    }
    if (
      this.information.phone == "" ||
      this.information.phone == null ||
      this.information.phone == undefined
    ) {
      this.check_phone = true;
    } else {
      this.check_phone = false;
    }
    if (
      this.information.hotel == "" ||
      this.information.hotel == null ||
      this.information.hotel == undefined
    ) {
      this.check_hotel = true;
    } else {
      this.check_hotel = false;
    }
    if (
      this.information.tourtime == "" ||
      this.information.tourtime == null ||
      this.information.tourtime == undefined
    ) {
      this.check_time = true;
    } else {
      this.check_time = false;
    }
    if (
      this.information.privacy == "" ||
      this.information.privacy == null ||
      this.information.privacy == undefined
    ) {
      this.check_privacy = true;
    } else {
      this.check_privacy = false;
    }
  }
  // check save booking success
  checkedSavebooking() {
    if (this.savebooking.status == true) {
      (async () => {
        this.reload = true; //icon reload
        await this.delay(3000);
        this.reload = false;
        //go to Payment
        this.router.navigate(["payment/" + this.savebooking.Transaction]);
      })();
    } else {
      //Send Data again
      // this.sendData();
    }
  }

  // <<< -----------------------------------------
  // ------------------- Set data ----------------
  // ----------------------------------------- >>>
  // set save booking paylaod
  setSaveBookingPayload() {
    let setPayload = {
      bookingInfo: {
        tour_id: this.getTourPrice_.tour_id,
        tour_code: this.getTourPrice_.tour,
        tour_title: this.getTourPrice_.title,
        privacy: this.information.privacy,
        tour_time: this.information.tourtime || "",
        date_travel: this.bsInlineValue.toDateString(),
        pax:
          this.information.adultpax +
          this.information.childpax +
          this.information.infantpax,
        adult_pax: this.information.adultpax,
        child_pax: this.information.childpax,
        infant_pax: this.information.infantpax,
        adult_price: this.adult_price,
        child_price: this.child_price,
        total_adult_price: this.information.adultpax * this.adult_price,
        total_child_price: this.information.childpax * this.child_price,
        total_price: 
          this.information.is_active_discount
            ? this.information.amount
            : this.information.total_price,
        commission: 0,
        // promotion_code: this.information.promotion_code,
        promotion_code: this.inputPromotionCode.is_active === 1 ? this.information.promotion_code : "",
        discount: this.information.discount,
        discount_rate: this.information.discount_rate,
        discount_price: this.information.discount_price,
        servicecharge: 0,
        insurance: null,
        payment_mode: "Selling price",
        payment_collect: "Guest",
        note_by: "Website",
        issued_by: "Website",
        isServicecharge: false,
        isInsurance: true,
        isCommission: false,
        operator: 1,
        booking_channel: 1,
        booking_sub_channel: 1,
      },
      guestInfo: [
        {
          fullname: this.information.firstname + " " + this.information.lastname,
          firstname: this.information.firstname,
          lastname: this.information.lastname,
          email: this.information.email,
          phone: this.information.phone,
          hotel: this.information.hotel,
          hotel_other: this.information.hotel_other,
          gender: null,
          is_primary: true,
        }
      ],
      affiliate: {
        aff_id: this.displayNullValue(this.aff_id?.aff_id),
      },
      otherService: {
        vans: this.information.vans,
        guides: this.information.guides,
        van_price: this.vans_price,
        guide_price: this.guides_price,
        total_vans_price: this.information.vans * this.vans_price,
        total_guides_price: this.information.guides * this.guides_price,
        pickup_time: this.pickup_time,
        // .toDateString(),
        //.toTimeString()
      },
    };
    return setPayload;
  }
  // Set travel date default
  setDateTravelDefault(date: any = null) {
    if(date === null){
      this.bsInlineValue = new Date(
        this.bsInlineValue.getFullYear(),
        this.bsInlineValue.getMonth(),
        this.bsInlineValue.getDate() + 2 // +2
      );
    }else{
      this.bsInlineValue = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );

      let dateNow = new Date();

      let dateBetween = this.getNumberOfDays(dateNow, this.bsInlineValue);
      dateBetween = +dateBetween;

      if(dateBetween < 0){ // normal case (date > holiday)
        // console.log("< 0");
        this.bsInlineValue = new Date(
          this.bsInlineValue.getFullYear(),
          this.bsInlineValue.getMonth(),
          this.bsInlineValue.getDate() + 1 // +3
        );
      }else if(dateBetween >= 0 && dateBetween < 3){ //
        if(dateBetween == 0){
          // console.log("= 0");
          this.bsInlineValue = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate() + 1 // 3
          );
        }else if(dateBetween == 1){
          // console.log("= 1");
          this.bsInlineValue = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate() + 1 // +2
          );
        }else if(dateBetween == 2){
          // console.log("= 2");
          this.bsInlineValue = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate() + 1 // 1
          );
        }
      }else{ // just between > 2
        // console.log("> 2");
        this.bsInlineValue = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate()
        );
      }
    }

  }

  // Check difference date, between
  getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.floor(diffInTime / oneDay);

    return diffInDays;
  }

  // Get all date of the years
  getDateListByYears(year: any = null) {
    let dateNow = new Date();
    if(year){
      dateNow = new Date(year);
    }

    let firstDate = new Date(dateNow.getFullYear() + "-01-01");
    let endDate = new Date(dateNow.getFullYear() + "-12-31");

    let first = +firstDate;
    let end = +endDate;

    let diff = end - first;
    let dayOfYear = Math.floor(diff / (1000 * 60 * 60 * 24));

    // set list
    let yearList = [];
    for(let i = 0; i <= dayOfYear; i++){
      // let setDate = new Date(
      //         firstDate.getFullYear()+
      //         "-"+
      //         (firstDate.getMonth() + 1).toString().padStart(2, '0')+
      //         "-"+
      //         firstDate.getDate().toString().padStart(2, '0')
      // );
      yearList.push(firstDate);

      firstDate = new Date(firstDate);
      firstDate.setDate(firstDate.getDate() +1); // 1
    }

    return yearList;
  }

  // <<< -----------------------------------------
  // -------------------- Share ------------------
  // ----------------------------------------- >>>
  delay(time: number) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  // <<< -----------------------------------------
  // --------------- Affiliate ZONE --------------
  // ----------------------------------------- >>>
  getAffId() {
    this.aff_id = JSON.parse(localStorage.getItem("aff_id"));
  }

  displayNullValue(value: any) {
    if(value === ""){
      return null;
    }else if(value === "none"){
      return null;
    }else if(value === "N/A"){
      return null;
    }else if(value === undefined){
      return null;
    }else{
      return value;
    }
  }

  // <<< Fix close tour >>>
  fixCloseTourJoin(): void {
    // check different date
    let startDate = new Date();
    let endDate = new Date((startDate.getFullYear()+2) + "-01-01");

    let diffDate = +endDate - +startDate;
    let dayOfYear = Math.floor(diffDate / (1000 * 60 * 60 * 24));

    for(let i = 0; i <= dayOfYear; i++){
      let date = new Date();
      date = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + i
      );

      // Fix Holiday
      if (
        (date.getMonth() == 4 && date.getDate() == 13) ||
        (date.getMonth() == 4 && date.getDate() == 14) ||
        (date.getMonth() == 4 && date.getDate() == 15) ||
        (date.getMonth() == 12 && date.getDate() == 31) ||
        (date.getMonth() == 1 && date.getDate() == 1)
      ) {
        this.disabledDates.push(
          date.getFullYear()+
          "-"+
          (date.getMonth()).toString().padStart(2, '0')+
          "-"+
          date.getDate().toString().padStart(2, '0')
        );
      }

      // Check next years
      // if(date <= new Date(this.next_year + "-01-02")){
        if(this.getTourPrice_.tour == "TC-R01"){ // Doi suthep
          if(this.information.tourtime == "Morning"){

          }else{
            // this.disabledDates.push(
            //   date.getFullYear()+
            //   "-"+
            //   (date.getMonth() + 1).toString().padStart(2, '0')+
            //   "-"+
            //   date.getDate().toString().padStart(2, '0')
            // );
          }

          // if(){
          //   this.disabledDates.push(
          //     date.getFullYear()+
          //     "-"+
          //     (date.getMonth() + 1).toString().padStart(2, '0')+
          //     "-"+
          //     date.getDate().toString().padStart(2, '0')
          //   );
          // }
        }else if(this.getTourPrice_.tour == "TC-R02"){ // City
          if(this.information.tourtime == "Morning"){
            if(date.getDay() == DayCode.Monday || date.getDay() == DayCode.Tuesday){
              this.disabledDates.push(
                date.getFullYear()+
                "-"+
                (date.getMonth() + 1).toString().padStart(2, '0')+
                "-"+
                date.getDate().toString().padStart(2, '0')
              );
            }
          }else{
            if(date.getDay() == DayCode.Monday || date.getDay() == DayCode.Tuesday){
              this.disabledDates.push(
                date.getFullYear()+
                "-"+
                (date.getMonth() + 1).toString().padStart(2, '0')+
                "-"+
                date.getDate().toString().padStart(2, '0')
              );
            }
          }
        }else if(this.getTourPrice_.tour == "TC-R04"){ // Chiang Rai
          // Fullday
          // if(date.getDay() == DayCode.Sunday || date.getDay() == DayCode.Monday || date.getDay() == DayCode.Thursday){
          //   this.disabledDates.push(
          //     date.getFullYear()+
          //     "-"+
          //     (date.getMonth() + 1).toString().padStart(2, '0')+
          //     "-"+
          //     date.getDate().toString().padStart(2, '0')
          //   );
          // }
        }else if(this.getTourPrice_.tour == "TC-R05"){ // Inthanon
          // Fullday
          // if(){
          //   this.disabledDates.push(
          //     date.getFullYear()+
          //     "-"+
          //     (date.getMonth() + 1).toString().padStart(2, '0')+
          //     "-"+
          //     date.getDate().toString().padStart(2, '0')
          //   );
          // }


        }else if(this.getTourPrice_.tour == "TC-R07"){ // Kiew mae pan
          let start_date = new Date(this.year_now + "-05-31");
          let end_date = new Date(this.year_now + "-10-31");

          if(date > start_date && date <= end_date){ // close
            this.disabledDates.push(
              date.getFullYear()+
              "-"+
              (date.getMonth() + 1).toString().padStart(2, '0')+
              "-"+
              date.getDate().toString().padStart(2, '0')
            );
          }else{
            // if(date.getDay() == DayCode.Tuesday || date.getDay() == DayCode.Thursday || date.getDay() == DayCode.Saturday){
            //   this.disabledDates.push(
            //     date.getFullYear()+
            //     "-"+
            //     (date.getMonth() + 1).toString().padStart(2, '0')+
            //     "-"+
            //     date.getDate().toString().padStart(2, '0')
            //   );
            // }
          }

          // Fix next year
          let next_start_date = new Date((+this.year_now + 1) + "-05-31");
          let next_end_date = new Date((+this.year_now + 1) + "-10-31");

          if(date > next_start_date && date <= next_end_date){ // close
            this.disabledDates.push(
              date.getFullYear()+
              "-"+
              (date.getMonth() + 1).toString().padStart(2, '0')+
              "-"+
              date.getDate().toString().padStart(2, '0')
            );
          }else{
            // if(date.getDay() == DayCode.Tuesday || date.getDay() == DayCode.Thursday || date.getDay() == DayCode.Saturday){
            //   this.disabledDates.push(
            //     date.getFullYear()+
            //     "-"+
            //     (date.getMonth() + 1).toString().padStart(2, '0')+
            //     "-"+
            //     date.getDate().toString().padStart(2, '0')
            //   );
            // }
          }

        }else if(this.getTourPrice_.tour == "TC-E01"){ // Morning buddhist life (Morning only)
          if(this.information.tourtime == "Morning"){
            // if(){
            //   this.disabledDates.push(
            //     date.getFullYear()+
            //     "-"+
            //     (date.getMonth() + 1).toString().padStart(2, '0')+
            //     "-"+
            //     date.getDate().toString().padStart(2, '0')
            //   );
            // }
          }else{

          }
        }else if(this.getTourPrice_.tour == "TC-E02"){ // Hill tribe (Afternoon only)
          if(this.information.tourtime == "Morning"){

          }else{
            // if(){
            //   this.disabledDates.push(
            //     date.getFullYear()+
            //     "-"+
            //     (date.getMonth() + 1).toString().padStart(2, '0')+
            //     "-"+
            //     date.getDate().toString().padStart(2, '0')
            //   );
            // }
          }
        }else if(this.getTourPrice_.tour == "TC-E03"){ // Rhythm of Chiang Mai (Morning only)
          if(this.information.tourtime == "Morning"){
            // if(){
            //   this.disabledDates.push(
            //     date.getFullYear()+
            //     "-"+
            //     (date.getMonth() + 1).toString().padStart(2, '0')+
            //     "-"+
            //     date.getDate().toString().padStart(2, '0')
            //   );
            // }
          }else{

          }
        }else if(this.getTourPrice_.tour == "TC-E04"){ // Art & Craft (Morning only)
          if(this.information.tourtime == "Morning"){
            // if(date.getDay() == DayCode.Sunday || date.getDay() == DayCode.Monday || date.getDay() == DayCode.Wednesday || date.getDay() == DayCode.Friday){
            //   this.disabledDates.push(
            //     date.getFullYear()+
            //     "-"+
            //     (date.getMonth() + 1).toString().padStart(2, '0')+
            //     "-"+
            //     date.getDate().toString().padStart(2, '0')
            //   );
            // }
          }else{

          }

        }else if(this.getTourPrice_.tour == "TC-E05"){ // Understand local lift
          // Fullday
          // if(){
          //   this.disabledDates.push(
          //     date.getFullYear()+
          //     "-"+
          //     (date.getMonth() + 1).toString().padStart(2, '0')+
          //     "-"+
          //     date.getDate().toString().padStart(2, '0')
          //   );
          // }
        }else if(this.getTourPrice_.tour == "TC-R06"){ // Lampang
          // Fullday
          // if(){
          //   this.disabledDates.push(
          //     date.getFullYear()+
          //     "-"+
          //     (date.getMonth() + 1).toString().padStart(2, '0')+
          //     "-"+
          //     date.getDate().toString().padStart(2, '0')
          //   );
          // }
        }else if(this.getTourPrice_.tour == "TC-R08"){ // Soft Hike-Pha Dok Siew Trail in the Inthanon National Park
        }else if(this.getTourPrice_.tour == "TC-R09"){ // Chiang Mai Evening City Tour by EV Tram
          if(date.getDay() == DayCode.Sunday){
            this.disabledDates.push(
              date.getFullYear()+
              "-"+
              (date.getMonth() + 1).toString().padStart(2, '0')+
              "-"+
              date.getDate().toString().padStart(2, '0')
            );
          }
        }else if(this.getTourPrice_.tour == "TC-S01"){ // The Clay-Shapers of Baan Muangkung
          if(date.getDay() == DayCode.Sunday){
            this.disabledDates.push(
              date.getFullYear()+
              "-"+
              (date.getMonth() + 1).toString().padStart(2, '0')+
              "-"+
              date.getDate().toString().padStart(2, '0')
            );
          }
        }
      // } // if check next year
    } // for
  }

  fixCloseTourPrivate(): void {
    // check different date
    let startDate = new Date();
    let endDate = new Date((startDate.getFullYear()+2) + "-01-01");

    let diffDate = +endDate - +startDate;
    let dayOfYear = Math.floor(diffDate / (1000 * 60 * 60 * 24));

    for(let i = 0; i <= dayOfYear; i++){
      let date = new Date();
      date = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + i
      );

      // Fix Holiday
      if (
        (date.getMonth() == 4 && date.getDate() == 13) ||
        (date.getMonth() == 4 && date.getDate() == 14) ||
        (date.getMonth() == 4 && date.getDate() == 15) ||
        (date.getMonth() == 12 && date.getDate() == 31) ||
        (date.getMonth() == 1 && date.getDate() == 1)
      ) {
        this.disabledDates.push(
          date.getFullYear()+
          "-"+
          (date.getMonth()).toString().padStart(2, '0')+
          "-"+
          date.getDate().toString().padStart(2, '0')
        );
      }

      // Check next years
      // if(date <= new Date(this.next_year + "-01-02")){
        if(this.getTourPrice_.tour == "TC-R01"){ // Doi suthep
          if(this.information.tourtime == "Morning"){

          }else{
            // this.disabledDates.push(
            //   date.getFullYear()+
            //   "-"+
            //   (date.getMonth() + 1).toString().padStart(2, '0')+
            //   "-"+
            //   date.getDate().toString().padStart(2, '0')
            // );
          }
          // if(){
          //   this.disabledDates.push(
          //     date.getFullYear()+
          //     "-"+
          //     (date.getMonth() + 1).toString().padStart(2, '0')+
          //     "-"+
          //     date.getDate().toString().padStart(2, '0')
          //   );
          // }
        }else if(this.getTourPrice_.tour == "TC-R02"){ // City
          if(this.information.tourtime == "Morning"){
            if(date.getDay() == DayCode.Monday || date.getDay() == DayCode.Tuesday){
              this.disabledDates.push(
                date.getFullYear()+
                "-"+
                (date.getMonth() + 1).toString().padStart(2, '0')+
                "-"+
                date.getDate().toString().padStart(2, '0')
              );
            }
          }else{
            if(date.getDay() == DayCode.Monday || date.getDay() == DayCode.Tuesday){
              this.disabledDates.push(
                date.getFullYear()+
                "-"+
                (date.getMonth() + 1).toString().padStart(2, '0')+
                "-"+
                date.getDate().toString().padStart(2, '0')
              );
            }
          }
        }else if(this.getTourPrice_.tour == "TC-R04"){ // Chiang Rai
          // Fullday
          // if(date.getDay() == DayCode.Sunday || date.getDay() == DayCode.Monday || date.getDay() ==  DayCode.Thursday){
          //   this.disabledDates.push(
          //     date.getFullYear()+
          //     "-"+
          //     (date.getMonth() + 1).toString().padStart(2, '0')+
          //     "-"+
          //     date.getDate().toString().padStart(2, '0')
          //   );
          // }
        }else if(this.getTourPrice_.tour == "TC-R05"){ // Inthanon
          // Fullday
          // if(){
          //   this.disabledDates.push(
          //     date.getFullYear()+
          //     "-"+
          //     (date.getMonth() + 1).toString().padStart(2, '0')+
          //     "-"+
          //     date.getDate().toString().padStart(2, '0')
          //   );
          // }
        }else if(this.getTourPrice_.tour == "TC-R07"){ // Kiew mae pan
          let start_date = new Date(this.year_now + "-05-31");
          let end_date = new Date(this.year_now + "-10-31");

          if(date > start_date && date <= end_date){ // close
            this.disabledDates.push(
              date.getFullYear()+
              "-"+
              (date.getMonth() + 1).toString().padStart(2, '0')+
              "-"+
              date.getDate().toString().padStart(2, '0')
            );
          }else{
            // if(date.getDay() == DayCode.Tuesday || date.getDay() == DayCode.Thursday || date.getDay() == DayCode.Saturday){
              // this.disabledDates.push(
              //   date.getFullYear()+
              //   "-"+
              //   (date.getMonth() + 1).toString().padStart(2, '0')+
              //   "-"+
              //   date.getDate().toString().padStart(2, '0')
              // );
            // }
          }

          // Fix next year
          let next_start_date = new Date((+this.year_now + 1) + "-05-31");
          let next_end_date = new Date((+this.year_now + 1) + "-10-31");

          if(date > next_start_date && date <= next_end_date){ // close
            this.disabledDates.push(
              date.getFullYear()+
              "-"+
              (date.getMonth() + 1).toString().padStart(2, '0')+
              "-"+
              date.getDate().toString().padStart(2, '0')
            );
          }else{
            // if(date.getDay() == DayCode.Tuesday || date.getDay() == DayCode.Thursday || date.getDay() == DayCode.Saturday){
            //   this.disabledDates.push(
            //     date.getFullYear()+
            //     "-"+
            //     (date.getMonth() + 1).toString().padStart(2, '0')+
            //     "-"+
            //     date.getDate().toString().padStart(2, '0')
            //   );
            // }
          }
        }else if(this.getTourPrice_.tour == "TC-E01"){ // Morning buddhist life (Morning only)
          if(this.information.tourtime == "Morning"){
            // if(){
            //   this.disabledDates.push(
            //     date.getFullYear()+
            //     "-"+
            //     (date.getMonth() + 1).toString().padStart(2, '0')+
            //     "-"+
            //     date.getDate().toString().padStart(2, '0')
            //   );
            // }
          }else{

          }
        }else if(this.getTourPrice_.tour == "TC-E02"){ // Hill tribe (Afternoon only)
          if(this.information.tourtime == "Morning"){

          }else{
            // if(){
            //   this.disabledDates.push(
            //     date.getFullYear()+
            //     "-"+
            //     (date.getMonth() + 1).toString().padStart(2, '0')+
            //     "-"+
            //     date.getDate().toString().padStart(2, '0')
            //   );
            // }
          }
        }else if(this.getTourPrice_.tour == "TC-E03"){ // Rhythm of Chiang Mai (Morning only)
          if(this.information.tourtime == "Morning"){
            // if(){
            //   this.disabledDates.push(
            //     date.getFullYear()+
            //     "-"+
            //     (date.getMonth() + 1).toString().padStart(2, '0')+
            //     "-"+
            //     date.getDate().toString().padStart(2, '0')
            //   );
            // }
          }else{

          }
        }else if(this.getTourPrice_.tour == "TC-E04"){ // Art & Craft (Morning only)
          if(this.information.tourtime == "Morning"){
            // if(date.getDay() == DayCode.Sunday || date.getDay() == DayCode.Monday || date.getDay() == DayCode.Wednesday || date.getDay() == DayCode.Friday){
            //   this.disabledDates.push(
            //     date.getFullYear()+
            //     "-"+
            //     (date.getMonth() + 1).toString().padStart(2, '0')+
            //     "-"+
            //     date.getDate().toString().padStart(2, '0')
            //   );
            // }
          }else{

          }

        }else if(this.getTourPrice_.tour == "TC-E05"){ // Understand local lift
          // Fullday
          // if(){
          //   this.disabledDates.push(
          //     date.getFullYear()+
          //     "-"+
          //     (date.getMonth() + 1).toString().padStart(2, '0')+
          //     "-"+
          //     date.getDate().toString().padStart(2, '0')
          //   );
          // }
        }else if(this.getTourPrice_.tour == "TC-R06"){ // Lampang
          // Fullday
          // if(){
          //   this.disabledDates.push(
          //     date.getFullYear()+
          //     "-"+
          //     (date.getMonth() + 1).toString().padStart(2, '0')+
          //     "-"+
          //     date.getDate().toString().padStart(2, '0')
          //   );
          // }
        }else if(this.getTourPrice_.tour == "TC-R08"){ // Soft Hike-Pha Dok Siew Trail in the Inthanon National Park
        }else if(this.getTourPrice_.tour == "TC-R09"){ // Chiang Mai Evening City Tour by EV Tram
          if(date.getDay() == DayCode.Sunday){
            this.disabledDates.push(
              date.getFullYear()+
              "-"+
              (date.getMonth() + 1).toString().padStart(2, '0')+
              "-"+
              date.getDate().toString().padStart(2, '0')
            );
          }
        }else if(this.getTourPrice_.tour == "TC-S01"){ // The Clay-Shapers of Baan Muangkung
          if(date.getDay() == DayCode.Sunday){
            this.disabledDates.push(
              date.getFullYear()+
              "-"+
              (date.getMonth() + 1).toString().padStart(2, '0')+
              "-"+
              date.getDate().toString().padStart(2, '0')
            );
          }
        }
      // } // if check next year
    } // for
  }

  // Date format
  displayDateServer(date: any) {
    let getDate = new Date(date);
    return  getDate.getFullYear()+
            "-"+
            (getDate.getMonth() + 1).toString().padStart(2, '0')+
            "-"+
            getDate.getDate().toString().padStart(2, '0')+5;
  }

  // Check promotion code
  callGetPromotionCode(): void {
    // if (this.information.promotion_code.length >= 5) {
      setTimeout(() => {
        const url = "website/promotion/code";
        let setPayload = {
          code: this.information.promotion_code,
          type: "TOUR",
          type_id: 1,
          reference_id: this.getTourPrice_.tour_id,
          reference_type_id: this.information.privacy === "Join" ? 1 : 2
        };
    
        this.apiService.callPost(url, setPayload).subscribe(
          (result) => {
            this.inputPromotionCode = result.result;
            this.onCheckDiscount();
          },
          // (err) => [console.log(err)]
        );
      }, 1000);
    // }
  }

  onCheckDiscount() {
    if (this.inputPromotionCode.travel_start && this.inputPromotionCode.travel_end) {
      if (this.bsInlineValue < new Date(this.inputPromotionCode.travel_start) || this.bsInlineValue > new Date(this.inputPromotionCode.travel_end)) {
        this.information.is_active_discount = false;
          this.promotionCodeMessage = `
            Promotion code is not available <br/>(Can be used traveling between
            ${displayDate(new Date(this.inputPromotionCode.travel_start), "DD MMM YYYY")} - 
            ${displayDate(new Date(this.inputPromotionCode.travel_end), "DD MMM YYYY")})
          `;
        this.inputPromotionCode.discount = 0;
        this.inputPromotionCode.is_active = false;
      } else {
        this.promotionCodeMessage = `Promotion code is not available`;
      }
    } else {
      this.promotionCodeMessage = `Promotion code is not available`;
    }

    if (this.inputPromotionCode.discount && this.inputPromotionCode.is_active) {
      this.dialogPromotionCode = null;
      this.information.is_active_discount = true;

      this.information.discount_rate = this.inputPromotionCode.is_percent ? "%" : "THB";
      this.calculateDiscount(this.inputPromotionCode.discount, this.inputPromotionCode.is_percent, true);
    } else {
      if (!this.isSave) {
        this.dialogPromotionCode = null;
      }
      this.information.is_active_discount = false;
      this.calculateDiscount();
    }
  }

  calculateDiscount(rate: number = 0, is_percent: boolean = false, is_reset: boolean = false) {
    this.information.discount = rate;
    this.information.discount_price = 0;

    if (is_reset) {
      if (is_percent) {
        this.information.discount_price = this.information.total_price * (this.information.discount / 100);
      } else {
        this.information.discount_price = this.information.discount;
      }
    }

    this.information.discount_price = this.information.discount_price > this.information.total_price ? this.information.total_price : this.information.discount_price;

    this.information.amount = this.information.total_price - this.information.discount_price;

    if (this.isSave) {
      if (this.dialogPromotionCode) {
        this.toggleDialog(this.dialogPromotionCode);
      } else {
        this.callSaveBooking();
      }
    }
  }

  closeDialogPromotionCode() {
    this.information.promotion_code = "";
    this.inputPromotionCode = {};
    this.isSave = false;
    this.modalRef.hide();
    this.dialogPromotionCode = null;
  }

  toggleTermDialog(event: boolean = false): void {
    if (event) {
      this.modalRef.hide()
    }
  }
}
