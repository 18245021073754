import { Injectable } from "@angular/core";
import { Headers, Http, RequestOptions } from "@angular/http";
import { map } from "rxjs/operators";
import { EnvService } from '../services/env.service';

@Injectable({
  providedIn: "root",
})
export class ApiServiceService {
  private configs: any = {};

  constructor(
    private http: Http,
    private envService: EnvService
  ) {
    let config = this.envService.getConfig();
    this.configs = config;
  }

  // <<< Call API >>>
  // Post
  callPost(path: any, data: any, is_header: boolean = false) {
    // console.log(path, data);
    const url = this.configs?.API_URL + path;
    const options = new RequestOptions();

    if(is_header === false){
      return this.http.post(url, data, options).pipe(map((res) => res.json()));
    }else{
      const token = localStorage.getItem("TOKEN_LOGIN") || "";
      const headers =  {
        headers: new Headers(
          {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + JSON.parse(token),
          }
        ),
        options
      };

      return this.http.post(url, data, headers).pipe(map((res) => res.json()));
    }

  }
  // Get
  callGet(path: any, data: any) {
    // console.log(path, data);
    const url = this.configs?.API_URL + path;
    // const options = new RequestOptions();
    return this.http.get(url, data).pipe(map((res) => res.json()));
  }

  // <<< Call JSON Files >>>
  // Get
  callGetJSON(path: any) {
    const url = this.configs?.JSON_URL + path;
    return this.http.get(url).pipe(map((res) => res.json()));
  }

  // Post
  callPostLineNotify(path: string, data: any) {
    const headers =  {
      headers: new Headers(
        {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer ' + this.configs?.LINE_NOTIFY_TOKEN,
        }
      )
    };
    // const options = new RequestOptions();

    return this.http.post(this.configs?.LINE_NOTIFY_URL, data, headers).pipe(map((res) => res.json()));
  }
}
