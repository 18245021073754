<!-- <app-header></app-header> -->

<!-- ------- Cover Images Promotions ---------- -->
<section class="py-5 module parallax parallax-1" data-rellax-speed="2">
    <div class="centered_">
        <span class="tittle-page">Promotions</span>
    </div>
</section>

<!-- ----------- Content ---------------------------- -->
<section class="content-promotion">
    <div class="container">


        <div style="text-align: center; padding: 3%;">
            <span style="font-size: 2rem;">NOT AVAILABLE </span>
        </div>



        <!-- ----------- P 1 ------------ -->
        <!-- <div class="card mx-auto shadow" style="width: 80%;">
    <a href="https://touringcenter.com/promotions2020/#/promotion/getfree/inthanon-and-city">
      <img class="card-img-top" src="../../assets/images/promotions/jpg/inthanon_free_city_New.jpg" alt="promotion buy 1 get 1">
    </a>
    <div class="card-body bg-content-card">
      <h2 class="card-title text-bold" style="color: white">Inthanon National Park & City Temple  </h2>
      <span class="card-text text-bold" style="color: white">Booking Period	:	01 Jul 20 – 30 Sep 20</span> &nbsp;&nbsp;&nbsp;
      <span class="card-text text-bold" style="color: white">Traveling Period	:	05 Jan 21 - 30 Sep 21</span>
      <br>
      <a href="https://touringcenter.com/promotions2020/#/promotion/getfree/inthanon-and-city" class="card-link text-bold pointer" style="float: right; color:white">Read More</a>
    </div>
  </div>

  <br>
  <hr>
  <br> -->

        <!-- ----------- P 2 ------------ -->
        <!-- <div class="card mx-auto shadow" style="width: 80%;">
    <a href="https://touringcenter.com/promotions2020/#/promotion/freetransfer/buy3-getfree-transferairport">
      <img class="card-img-top" src="../../assets/images/promotions/jpg/3-free-transer.jpg" alt="buy 3 free airport transfer">
    </a>
    <div class="card-body bg-content-card_">
      <h2 class="card-title text-bold" style="color: white">Inthanon National Park & Breath of Nature & Khan Toke Dinner</h2>
      <span class="card-text text-bold" style="color: white">Traveling Period : 1 Auguest - 31 October 2020</span>
      <br>
      <a href="https://touringcenter.com/promotions2020/#/promotion/freetransfer/buy3-getfree-transferairport" class="card-link text-bold pointer" style="float: right; color:white">Read More</a>
    </div>
  </div>
  <br>
  <hr>
  <br> -->

        <!-- ----------- P 3 ------------ -->
        <!-- <div class="card mx-auto shadow" style="width: 80%;">
      <a href="/vanhire/TC-VH01">
        <img class="card-img-top" src="../../assets/images/promotions/jpg/vanhire-vh-01.jpg" alt="van hire">
       </a>
      <div class="card-body bg-content-card">
        <h2 class="card-title text-bold" style="color: white">เช่ารถตู้ แบบรวมน้ำมัน ในเชียงใหม่  </h2>
        <span class="card-text text-bold" style="color: white">ช่วงเวลาการเดินทาง : 13 กรกฏาคม - 20 ตุลาคม 2563</span> &nbsp;&nbsp;&nbsp;
        <span class="card-text text-bold" style="color: white">ระยะเวลาการจอง : 13 กรกฏาคม - 31 ตุลาคม 2563</span>
        <br>
        <a href="/vanhire/TC-VH01" class="card-link text-bold pointer" style="float: right; color:white">รายละเอียด</a>
      </div>
    </div> -->

    </div>
</section>


<!-- ------- Footter ---------- -->
<!-- <app-footer></app-footer> -->
