import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as AOS from 'aos';
import { EnvService } from "src/app/services/env.service";
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  public configs: any = {};
  public pagine: number = 1;
  public imgCoverPath = <any>'';

  public events: Array<any> = [];

  constructor(
    public router: Router,
    private envService: EnvService,
    private eventsService: EventsService
  ) {
    let config = this.envService.getConfig();
    this.configs = config;
  }

  ngOnInit(): void {
    this.getEvents();

    AOS.init({once: true});
    this.imgCoverPath = `${this.configs.SERVER_URL}`+"images/cover-size.jpg";
  }

  getEvents(): void {
    const payload = {};

    this.eventsService.getEvents(payload).subscribe((res:any) => {
      if (res?.status) {
        this.events = res?.data || [];
      }
    })
  }

}
