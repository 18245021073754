import { Injectable } from '@angular/core';
import { ApiServiceService } from "./api-service.service";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ReceiptService {
  private path = "Website/Transaction"
  private pathPayment = "Website/Payment";

  constructor(private service: ApiServiceService) { }

  getReceipt(payload: any = {}): Observable<any> {
    return this.service.callPost(`${this.path}/GetReceiptPDF`, payload);
  }
}