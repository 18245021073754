import * as moment from 'moment';

export const isBoolean = (value: any) => {
  return 'boolean' === typeof value;
};

export const isUndefined = (value: any) => {
  return 'undefined' === typeof value;
};

export const isNull = (value: any) => {
  return value === null;
};

export const isNumber = (value: any) => {
  return 'number' === typeof value;
};

export const isString = (value: any) => {
  return 'string' === typeof value;
};

export const isDateObject = (value: any) => {
  return value instanceof Date;
};

export const isObject = (value: any) => {
  return 'object' === typeof value && !Array.isArray(value) && value !== null;
};

export const isEmptyObject = (value: any) => {
  if (isObject(value)) {
    return Object.keys(value).length === 0;
  }
  return false;
};

export const isDate = (value: any) => {
  if (isDateObject(value)) {
    return true;
  } else if (isString(value) && value !== '') {
    let date = moment(value, 'YYYY-MM-DDTHH:mm:ss', true).isValid();
    let dateUTC = moment(value, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid();
    let dateUTC2 = moment(
      value,
      'YYYY-MM-DDTHH:mm:ss.SSSSSSSSSZ',
      true
    ).isValid();
    let dateUTC3 = moment(
      value,
      'YYYY-MM-DDTHH:mm:ss.SSSSSSSSS',
      true
    ).isValid();
    let dateDay = moment(value, 'YYYY-MM-DD', true).isValid();
    return date || dateUTC || dateUTC2 || dateUTC3 || dateDay;
  }
  return false;
};
