import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-love-out-loud',
  templateUrl: './love-out-loud.component.html',
  styleUrls: ['./love-out-loud.component.scss']
})
export class LoveOutLoudComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
