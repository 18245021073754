<!-- <app-header-non-fixed></app-header-non-fixed> -->

<!-- -------------- Tour ? Id --------------------- -->
<section class="body-navbar">
  <div class="container__">
    <div class="tag">
      <ul class="ul-tag">
        <li><a [routerLink]="['/']">Home</a></li>
        <li> / </li>
        <li>
          <a [routerLink]="['/tours']" (click)="routeTours()">{{
            Tour.categories
          }}</a>
        </li>
        <li> / </li>
        <li>
          <a class="tag-tours">{{ Tour.title }}</a>
        </li>
      </ul>
    </div>
    <div class="title-tour">
      <span class="font-title-tour"
        ><b>{{ Tour.title }}</b></span
      ><span class="font-code-title"> [{{ Tour.tour }}]</span>
    </div>
  </div>

  <!-- --------------- Image Tour ------------------- -->
  <div class="container____">
    <div class="main-img-tour">
      <img class="img-tour" [src]="Tour.image" />
    </div>
  </div>

  <!-- --------------- Concept Tour ------------------- -->
  <div class="container___">
    <div class="concept">
      <span style="color: #d45079">"{{ Tour.concept }}"</span>
    </div>
  </div>

  <!-- --------------- Detail Tour ------------------- -->
  <div class="container___">
    <div class="row flex-column-reverse flex-md-row">
      <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <!-- --------------- Service Icon -------------------- -->
        <!-- <div class="service-tag" style="text-align: center;">
                  <ul class="service-tag-icon">
                    <li style="margin: 0 5%;"><img src="./assets/images/tours/icon/icons8-language-50.png"></li>
                    <li style="margin: 0 5%;"><img src="./assets/images/tours/icon/icons8-child-64.png"></li>
                    <li style="margin: 0 5%;"><img src="./assets/images/tours/icon/icons8-tourist-guide-1-64_.png"></li>
                    <li style="margin: 0 5%;"><img src="./assets/images/tours/icon/icons8-water-bottle-64.png"></li>
                    <li style="margin: 0 5%;"><img src="./assets/images/tours/icon/icons8-van-50.png"></li>
                  </ul>
                  <ul class="service-tag-icon">
                    <li style="margin: 0 5%;"><span>English</span></li>
                    <li style="margin: 0 5%;"><span>No charge Infant</span></li>
                    <li style="margin: 0 5%;"><span>Professional Guide</span></li>
                    <li style="margin: 0 5%;"><span>Free Drinking Water</span></li>
                    <li style="margin: 0 5%;"><span>Pick up at Hotel</span></li>
                  </ul>
              </div> -->

        <div class="service-tag" style="text-align: center">
          <div class="row">
            <div class="col-1 iocn-hidden">
              <span>test</span>
            </div>
            <div class="col-2 icon-service">
              <img src="./assets/images/tours/icon/world.png" /><br /><br
                class="br-hidd"
              />
              <span class="icon-text-s">English</span>
            </div>
            <div class="col-2 icon-service">
              <img src="./assets/images/tours/icon/baby.png" /><br /><br
                class="br-hidd"
              />
              <span class="icon-text-s">No charge Infant</span>
            </div>
            <div class="col-2 icon-service">
              <img src="./assets/images/tours/icon/guides.png" /><br /><br
                class="br-hidd"
              />
              <span class="icon-text-s">Professional Guide</span>
            </div>
            <div class="col-2 icon-service">
              <img src="./assets/images/tours/icon/bottle.png" /><br /><br
                class="br-hidd"
              />
              <span class="icon-text-s">Free Drinking Water</span>
            </div>
            <div class="col-2 icon-service">
              <img src="./assets/images/tours/icon/van.png" /><br /><br
                class="br-hidd"
              />
              <span class="icon-text-s">Pick up at Hotel</span>
            </div>
            <div class="col-1 iocn-hidden">
              <span>test</span>
            </div>
          </div>
        </div>

        <div class="service-tag-m" style="text-align: center">
          <hr />
          <div class="row">
            <div class="col-4 icon-service">
              <img src="./assets/images/tours/icon/world.png" /><br /><br
                class="br-hidd"
              />
              <span class="icon-text-s">English</span>
            </div>
            <div class="col-4 icon-service">
              <img src="./assets/images/tours/icon/baby.png" /><br /><br
                class="br-hidd"
              />
              <span class="icon-text-s">No charge Infant</span>
            </div>
            <div class="col-4 icon-service">
              <img src="./assets/images/tours/icon/guides.png" /><br /><br
                class="br-hidd"
              />
              <span class="icon-text-s">Professional Guide</span>
            </div>
          </div>
          <div class="row">
            <div class="col-4 icon-service">
              <img src="./assets/images/tours/icon/bottle.png" /><br /><br
                class="br-hidd"
              />
              <span class="icon-text-s">Free Drinking Water</span>
            </div>
            <div class="col-4 icon-service">
              <img src="./assets/images/tours/icon/van.png" /><br /><br
                class="br-hidd"
              />
              <span class="icon-text-s">Pick up at Hotel</span>
            </div>
            <div class="col-4 icon-service" style="visibility: hidden">
              <img src="./assets/images/tours/icon/bottle.png" /><br /><br
                class="br-hidd"
              />
              <span class="icon-text-s">Free Drinking Water</span>
            </div>
          </div>
          <hr />
        </div>

        <!-- --------------- Tour Time -------------------- -->
        <div class="tour-time">
          <div class="tour-time-content">
            <div class="tour-time-title">
              <span class="content-title"
                >{{ Tour.timetype }} ({{ Tour.duration }})</span
              >
            </div>
            <br />
            <div class="tour-time-detail font-1r">
              <table>
                <ng-container *ngIf="Tour.tourtime && Tour.tourtime.length > 0">
                  <tr *ngFor="let tourtime of Tour.tourtime; let i = index">
                    <td *ngIf="i == 0" class="w-30 td-1">Tour Time</td>
                    <td *ngIf="i !== 0"></td>
                    <td></td>
                    <td class="td-pad" *ngIf="tourtime.meridiem && tourtime.meridiem !== '-'">
                      {{ tourtime.meridiem }}, {{ tourtime.travel_time_start }} - {{ tourtime.travel_time_end }}
                    </td>
                    <td class="td-pad" *ngIf="!tourtime.meridiem || tourtime.meridiem == '-'">
                      {{ tourtime.travel_time_start }} - {{ tourtime.travel_time_end }}
                    </td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="Tour.tourtime && Tour.tourtime.length > 0">
                  <tr *ngFor="let tourtime of Tour.tourtime; let i = index">
                    <td *ngIf="i == 0" class="w-30 td-1">Pick up Time</td>
                    <td *ngIf="i !== 0"></td>
                    <td></td>
                    <td class="td-pad" *ngIf="Tour.tour !== 'TC-R09' && (tourtime.meridiem && tourtime.meridiem !== '-')">
                      {{ tourtime.meridiem }}, Standby for pick up at
                      {{ tourtime.pickup_time }} at hotel lobby
                    </td>
                    <!-- E09 only -->
                    <td class="td-pad" *ngIf="!tourtime.meridiem || tourtime.meridiem === '-'">
                      Standby for pick up at {{ tourtime.pickup_time }} at hotel lobby
                    </td>
                    <!-- R09 only -->
                    <td class="td-pad" *ngIf="Tour.tour == 'TC-R09'">
                      Three King Monument (15 min before tour time )
                    </td>
                  </tr>
                </ng-container>
              </table>
            </div>
          </div>
        </div>
        <hr />
        <!-- --------------- Tour Description -------------------- -->
        <div class="description pad-t-b mar-t">
          <div class="description-title">
            <span class="content-title">Description</span>
          </div>
          <br />
          <div class="description-detail font-1r">
            <span [innerHTML]="Tour.description"></span>
          </div>
        </div>
        <!-- --------------- Tour Images -------------------- -->
        <div class="tour-images mar-t">
          <div
            id="carouselExampleIndicators"
            class="carousel slide"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  class="d-block w-100"
                  [src]="Tour?.gallery_active?.images_path"
                />
              </div>
              <ng-container *ngIf="Tour.gallery && Tour.gallery.length > 0">
                <div *ngFor="let Tour_img of Tour.gallery" class="carousel-item">
                  <img class="d-block w-100" [src]="Tour_img?.images_path" />
                </div>
              </ng-container>
            </div>
            <a
              class="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
        <!-- --------------- Tour itinerary -------------------- -->
        <div class="itinerary mar-t">
          <div class="itinerary-title">
            <span class="content-title">Itinerary</span>
          </div>
          <br />
          <div class="itinerary-detail font-1r">
            <div class="tour-itinerary-detail font-1r">
              <table>
                <ng-container *ngIf="Tour.itinerary && Tour.itinerary.length > 0">
                  <tr *ngFor="let itinerary of Tour.itinerary; let i = index">
                    <td class="w-30 td-1">{{ itinerary?.time }}</td>
                    <!-- <td></td> -->
                    <td class="td-pad">{{ itinerary?.detail }}<br /></td>
                  </tr>
                </ng-container>
              </table>
            </div>
          </div>
        </div>
        <hr />
        <!-- --------------- Tour Include -------------------- -->
        <div class="include mar-t">
          <div class="include-title">
            <span class="content-title">Tour Includes</span>
          </div>
          <br />
          <div class="content-includes font-1r">
            <ng-container *ngIf="Tour.includes && Tour.includes.length > 0">
              <div *ngFor="let includes of Tour.includes">
                <i class="fa fa-check" aria-hidden="true" *ngIf="includes?.include?.substr(0, 1) !== '-'"></i>
                <span
                  [innerHTML]="includes?.include"
                  [style.padding-left]="
                    includes?.include?.substr(0, 1) === '-' ? '25px' : '5px'
                  "
                ></span>
                <br />
              </div>
            </ng-container>
          </div>
        </div>
        <hr />
        <!-- --------------- Tour Excludes -------------------- -->
        <div class="excludes mar-t">
          <div class="excludes-title">
            <span class="content-title">Tour Excludes</span>
          </div>
          <br />
          <div class="content-Excludes font-1r">
            <ng-container *ngIf="Tour.excludes && Tour.excludes.length > 0">
              <div *ngFor="let excludes of Tour?.excludes">
                <i class="fa fa-times" aria-hidden="true" *ngIf="excludes?.exclude?.substr(0, 1) !== '-'"></i>
                  <span
                    [innerHTML]="excludes?.exclude"
                    [style.padding-left]="
                      excludes?.exclude?.substr(0, 1) === '-' ? '25px' : '5px'
                    "
                  ></span>
                <br />
              </div>
            </ng-container>
          </div>
        </div>
        <hr />
        <!-- --------------- Tour Important Note -------------------- -->
        <div class="important-note mar-t">
          <div class="important-note-title">
            <span class="content-title">Important Note</span>
          </div>
          <br />
          <div class="content-Excludes font-1r">
            <ng-container *ngIf="Tour.important && Tour.important.length > 0">
              <div *ngFor="let important of Tour.important">
                <span [innerHTML]="important.important"></span><br />
              </div>
            </ng-container>
          </div>
        </div>
        <hr />
        <!-- --------------- Terms and Conditions  -------------------- -->
        <div class="terms-condition mar-t">
          <div class="terms-condition-title">
            <span class="content-title">Terms and conditions</span>
            <br /><br />
            <span
              class="text-term pointer font-1r"
              (click)="openModal(template)"
              ><u>Read More</u></span
            >
          </div>
        </div>
        <hr />
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <div class="form-book">
          <div class="blog-book">
            <div *ngIf="Tour.is_booking == 1">
              <!-- Join & private -->
              <ng-container *ngIf="Tour.price.join.adult && Tour.price.private.length > 0">
                <div
                  class="blog-price"
                  *ngIf="Tour.price.join.adult"
                >
                  <span class="card-text-review color-2 font-blog-form">From</span
                  ><br />
                  <div class="col-12 blog-price_">
                    <span class="card-text-more_ color-2 font-blog-currency"
                      >THB</span
                    >
                    &nbsp;&nbsp;
                    <span class="card-text-more_ color-4 font-blog-price">{{
                      Tour.price.join.adult | number
                    }}</span>
                    &nbsp;&nbsp;
                    <span class="per-person_ color-2">per person</span>
                  </div>
                </div>
              </ng-container>
              
              <!-- Join only -->
              <ng-container *ngIf="Tour.price.join.adult && Tour.price.private.length <= 0">
                <div
                  class="blog-price"
                  *ngIf="Tour.price.join.adult"
                >
                  <span class="card-text-review color-2 font-blog-form">From</span
                  >&nbsp;<small>(Join only)</small><br />
                  <div class="col-12 blog-price_">
                    <span class="card-text-more_ color-2 font-blog-currency"
                      >THB</span
                    >
                    &nbsp;&nbsp;
                    <span class="card-text-more_ color-4 font-blog-price">{{
                      Tour.price.join.adult | number
                    }}</span>
                    &nbsp;&nbsp;
                    <span class="per-person_ color-2">per person</span>
                  </div>
                </div>
              </ng-container>

              <!-- Private only -->
              <ng-container *ngIf="Tour.price.join.length <= 0 && Tour.price.private.length > 0">
                <div
                  class="blog-price"
                  *ngIf="Tour.price.private[0].adult"
                >
                  <span class="card-text-review color-2 font-blog-form">From</span
                  >&nbsp;<small class="color-1">(Private price)</small><br />
                  <div class="col-12 blog-price_">
                    <span class="card-text-more_ color-2 font-blog-currency"
                      >THB</span
                    >
                    &nbsp;&nbsp;
                    <span class="card-text-more_ color-4 font-blog-price">{{
                      Tour.price.private[0].adult | number
                    }}</span>
                    &nbsp;&nbsp;
                    <span class="per-person_ color-2">per person</span>
                  </div>
                </div>
              </ng-container>

              <!-- Btn Book -->
              <div class="btn-book">
                <button
                  mat-raised-button
                  class="book-btn"
                  (click)="routeBooking()"
                >
                  BOOK NOW
                </button>
              </div>
              <br />
              <div class="code-etc" *ngIf="false">
                <a
                  ><span class="pointer" (click)="showGetCode()"
                    >Enter gift code or promo code</span
                  ></a
                >
                <div class="get-code" *ngIf="showgetCode == true">
                  <input
                    type="input"
                    class="putcode"
                    style="border: 1px solid #333333"
                  />
                  &nbsp;
                  <button
                    type="button"
                    class="btn btn-outline-secondary btn-redeem"
                    (click)="checkCode()"
                  >
                    Redeem
                  </button>
                  <br />
                  <span *ngIf="checkCode_ == true" class="vaildate-code">{{
                    textvaildateCode
                  }}</span>
                </div>
              </div>
            </div>
            <div *ngIf="Tour.is_booking == 0">
              <div class="message-alert">{{ Tour.message }}</div>
            </div>
            <hr />
            <div class="list-guides-book font-1r">
              <span
                ><i class="fa fa-user" aria-hidden="true"></i> Guarantee daily
                departure with 2 adults</span
              ><br />
              <span
                ><i class="fa fa-book" aria-hidden="true"></i> Reserve at least
                {{ Tour.reserve }} day(s) in advance.</span
              ><br />
              <span
                ><i class="fa fa-check" aria-hidden="true"></i> No booking fees
                or any hidden cost.</span
              ><br />
              <span
                ><i class="fa fa-check" aria-hidden="true"></i> Instant booking
                directly.</span
              ><br />
              <span
                ><i class="fa fa-check" aria-hidden="true"></i> Best
                price.</span
              >
            </div>
          </div>
        </div>
        <!-- -------------- icon service (email | print | share) ----------------- -->
        <div class="service-etc">
          <span
            ><i
              class="fa fa-envelope-o pointer"
              aria-hidden="true"
              (click)="openModal(template_)"
            ></i
          ></span>
          &nbsp;
          <span
            ><i
              class="fa fa-print pointer"
              aria-hidden="true"
              (click)="Tour.document && OpenPDF()"
              [class.disabled]="!Tour.document"
            ></i
          ></span>
          &nbsp;
          <!-- <span><i class="fa fa-share-alt pointer" aria-hidden="true"></i></span> -->
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="Tours container___ bg-light index-1">
      <div class="Tourst-List">
        <span class="content-title">You might also like</span>
      </div>
      <br />
      <div class="consolg-btn">
        <div class="btn">
          <div class="btn-left">
            <i
              class="fa fa-chevron-left left-btn pointer"
              aria-hidden="true"
            ></i>
          </div>
          <div class="btn-right">
            <i
              class="fa fa-chevron-right right-btn pointer"
              aria-hidden="true"
            ></i>
          </div>
        </div>
      </div>
      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide *ngFor="let tours of Tours_List">
          <div
            class="card card-show color-3 pointer card-m"
            (click)="routeTour(tours.tour)"
          >
            <div *ngIf="tours.image_cover !== null">
              <img
                class="card-img-top img-tours-view"
                [src]="tours.image_cover"
                alt="Card image cap"
              />
            </div>
            <div *ngIf="tours.image_cover == null">
              <img
                class="card-img-top img-tours-view"
                [src]="imgCoverPath"
                alt="Card image cap"
              />
            </div>
            <div class="card-body">
              <p class="card-text text-tittle-tour tours-name">
                {{ tours.title
                }}<span class="tours-code"> [{{ tours.tour }}]</span>
              </p>
              <p class="tours-concept">"{{ tours.concept }}"</p>
              <hr style="visibility: hidden" />
              <span class="card-text-review color-2">From</span>
              <span
                class="card-text-review color-1"
                *ngIf="tours.price == null && tours.price_private != null"
                ><small>&nbsp;(Private price)</small></span
              >
              <span class="card-text-more color-2">
                <span class="color-1 text-price" *ngIf="tours.price != null">
                  {{ tours.price | number }}
                </span>
                <span
                  class="color-1 text-price"
                  *ngIf="tours.price == null && tours.price_private != null"
                >
                  {{ tours.price_private | number }}
                </span>
                <span
                  class="color-1 text-price"
                  *ngIf="tours.price == null && tours.price_private == null"
                >
                  N/A
                </span>
                THB</span
              ><br />
              <span class="per-person color-2"> per person</span>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>

  <!-- ------- Tours Promotion ---------- -->
  <!-- <section style="padding: 0;">
  <div class="Tours container___p" style="text-align: center;">
      <div class="promo-content shadow pointer">
        <a href="https://touringcenter.com/promotions2020/#/promotion/getfree/inthanon-and-city" target="blank">
          <img class="w-100" src="./assets/images/home/BUT1GET1.jpg">
         </a>
      </div>
  </div>
</section> -->
</section>

<!-- ------- Footter ---------- -->
<!-- <app-footer></app-footer> -->

<!-- -------------- Model Ngx ------------------ -->
<!-- ---------- Term & Condition ------------------ -->
<ng-template #template>
  <app-term-and-condition
    (onClose)="toggleTermDialog($event)"
    [isClose]="true"
  ></app-term-and-condition>
</ng-template>

<!-- -------------- Model Ngx ------------------ -->
<!-- ---------- Contact Email ------------------ -->
<ng-template #template_>
  <!-- <div class="modal-header tab-term-bg">
    <h4 class="modal-title tab-term-font">Terms & Conditions</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> -->
  <div class="modal-body">
    <div class="title-contact"><b>Contact Email</b></div>
    <div style="text-align: center">
      <span class="tours-email"><b>Tour :</b> {{ Tour.title }}</span>
    </div>
    <br />
    <div class="form-group form-send-email font-08r">
      <a *ngIf="check_fullname == true" style="color: red"
        >Please enter a valid fullname</a
      >
      <input
        type="text"
        class="form-control font-08r"
        id="InputName"
        [(ngModel)]="fullname"
        placeholder="Your Name *"
      /><br />
      <a *ngIf="check_email == true" style="color: red"
        >Please enter a valid email address</a
      >
      <input
        type="email"
        class="form-control font-08r"
        id="InputEmail"
        [(ngModel)]="email"
        aria-describedby="emailHelp"
        placeholder="@Email *"
      />
      <br />
      <a *ngIf="check_message == true" style="color: red"
        >Please enter a valid message</a
      >
      <textarea
        type="textarea"
        class="form-control area-h font-08r"
        id="Inputmessage"
        [(ngModel)]="message"
        placeholder="Your Message *"
      ></textarea>
      <br />
      <div class="centered">
        <button
          mat-raised-button
          class="btn-send-mail"
          (click)="checkValueInfo()"
        >
          Send <i class="fa fa-envelope-o" aria-hidden="true"></i>
        </button>
        <span *ngIf="reload == true"
          ><br /><img
            class="icon-send-gif"
            src="./assets/images/contact/Ellipsis-1.6s-197px (1).gif"
        /></span>
      </div>
    </div>
  </div>
</ng-template>
