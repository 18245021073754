import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import Swal from "sweetalert2";
import { ApiServiceService } from "../../../services/api-service.service";

@Component({
  selector: "app-close-booking-tour",
  templateUrl: "./close-booking-tour.component.html",
  styleUrls: ["./close-booking-tour.component.scss"],
})
export class CloseBookingTourComponent implements OnInit {
  public token: any = null;
  public input: any;
  public tour_list: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiServiceService
  ) {}

  ngOnInit(): void {
    this.token = JSON.parse(localStorage.getItem("TOKEN_LOGIN"));
    if (this.token && this.token != undefined) {
      this.callCheckLoginExpired(this.token);
    } else {
      this.checkLoginExpired({ status: false });
    }

    // set default
    this.input = {
      tour: {},
      tour_id: "",
      tour_code: "",
      date: "",
      remark: "",
    };

    this.tour_list = [];

    this.callGetAllTours();
  }

  //! <<< --------- Check login expired --------- >>>
  callCheckLoginExpired(token): void {
    const url = "User/CheckLoginExpired";
    let setPayload = {
      token: "Bearer " + token
    };

    this.apiService
      .callPost(url, setPayload)
      .toPromise()
      .then(
        (result) => [this.checkLoginExpired(result)],
        (err) => [console.log(err)]
      )
      .then(() => {});
  }

  // check login expired
  checkLoginExpired(params): void {
    if (params.status === false) {
      localStorage.removeItem("TOKEN_LOGIN");
      this.router.navigate(["login"]);
    }
  }

  //! <<< --------- Get tour --------- >>>
  callGetAllTours(): void {
    const url = "Website/Tour/GetAllTours";
    let setPayload = {};

    this.apiService
      .callPost(url, setPayload)
      .toPromise()
      .then(
        (result) => [(this.tour_list = result.data)],
        (err) => [console.log(err)]
      );
  }

  //! <<< --------- Save close tour --------- >>>
  // Save
  onClickSave(): void {
    console.log("Input data : ", this.input);
    let tourData = this.tour_list.filter(
      (r) => r.tour === this.input.tour_code
    )[0];

    if (tourData) {
      this.input.tour = tourData;
    }

    // Check valid
    if (!this.input.tour.tour_id || !this.input.tour.tour || !this.input.date) {
      Swal.fire({
        icon: "warning",
        text: "Tour or date invalid",
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {});
    }

    // Set data save
    const url = "Tour/SaveCloseTour";

    let setPayload = {
      tour_id: this.input.tour.tour_id,
      tour_code: this.input.tour.tour,
      period_start: this.input.date,
      period_end: this.input.date,
      remark: this.input.remark,
      token: "Bearer " + this.token,
    };

    // Call save close tour
    this.apiService
      .callPost(url, setPayload)
      .toPromise()
      .then(
        (result) => [this.checkSaveCloseTour(result)],
        (err) => [console.log(err)]
      );
  }

  // Call save close tour
  checkSaveCloseTour(params: any): void {
    if (params.status === true) {
      Swal.fire({
        icon: "success",
        text: params.message,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        // this.ngOnInit();
      });
    } else {
      Swal.fire({
        icon: "error",
        text: params.message,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {});
    }
  }

  //! <<< --------- Logout --------- >>>
  // Logout
  onClickLogout(): void {
    const url = "User/Logout";
    let setPayload = {
      token: "Bearer " + this.token
    };

    this.apiService
      .callPost(url, setPayload)
      .toPromise()
      .then(
        (result) => [this.checkLogout(result)],
        (err) => [console.log(err)]
      );
  }

  // Click logout
  checkLogout(params: any): void {
    if (params.status === true) {
      Swal.fire({
        icon: "success",
        text: params.message,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        localStorage.removeItem("TOKEN_LOGIN");
        this.router.navigate(["login"]);
      });
    } else {
      Swal.fire({
        icon: "error",
        text: params.message,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {});
    }
  }
}
