import { Component, OnInit } from '@angular/core';
import { setLocalStorage, getLocalStorage } from "./../../helpers";

@Component({
  selector: 'app-accept-cookies',
  templateUrl: './accept-cookies.component.html',
  styleUrls: ['./accept-cookies.component.scss']
})
export class AcceptCookiesComponent implements OnInit {
  isAcceptCookies: boolean = false

  constructor() { }

  ngOnInit(): void {
    this.checkAcceptCookies()
  }

  checkAcceptCookies(): void {
    // const keyAccept = getLocalStorage('accept-cookies');
    // if (keyAccept) {
    //   this.isAcceptCookies = JSON.parse(keyAccept);
    // }
    this.isAcceptCookies = true;
  }

  onClickAccept(): void {
    setLocalStorage('accept-cookies', true);
    this.checkAcceptCookies();
  }

}
