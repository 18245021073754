import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-breath-of-nature',
  templateUrl: './breath-of-nature.component.html',
  styleUrls: ['./breath-of-nature.component.scss']
})
export class BreathOfNatureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
