import { Component, OnInit } from "@angular/core";
import * as Rellax from "rellax";
import { Meta } from "@angular/platform-browser";
import * as AOS from "aos";
import { EnvService } from "src/app/services/env.service";

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.scss"],
})
export class AboutComponent implements OnInit {
  public configs: any = {};
  public browser_variable: boolean = false;

  constructor(
    private meta: Meta,
    private metaTagService: Meta,
    private envService: EnvService
  ) {
    let config = this.envService.getConfig();
    this.configs = config;
  }

  // <<< Initial >>>
  ngOnInit(): void {
    this.DetectBrowser();
    AOS.init({
      once: true,
    });
    this.metaTagUpdate();
  }

  // <<< -----------------------------------------
  // -------------------- Share ------------------
  // ----------------------------------------- >>>
  // detect browser
  DetectBrowser() {
    // Detect Browser
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (ua.indexOf("chrome") > -1) {
        this.browser_variable = false; // Chrome
        var rellaxHeader = new Rellax(".rellax-header");
      } else {
        this.browser_variable = true; // Safari
      }
    }
  }

  // <<< -----------------------------------------
  // ------------------- Meta Tag ----------------
  // ----------------------------------------- >>>
  metaTagUpdate() {
    this.meta.addTags(
      [
        {
          name: "title",
          property: "og:title",
          content:
            "Tour in Chiang Mai and around &amp; Activities with Premium Service : Touring Center",
        },
        {
          name: "description",
          property: "og:description",
          content:
            "Explore and Experience tour in Chiang Mai and around from professional team with the unbeatable service. Touring Center provide a daily departure day tour and package for both join with group (small size) and private trip.",
        },
        {
          name: "author",
          content: "Touring Center",
        },
        {
          name: "keywords",
          content:
            "Tour in Chiang Mai, Day Tour, Chiang Mai Tour, Day Trip, Join Premium, Join Tour, Private Tour, Touring Center, Yi Peng Chiang Mai, chiang mai tour company, Chiang Mai, small group",
        },
        {
          name: "image",
          property: "og:image",
          content:
            `${this.configs.WEBSITE_URL}` +
            "assets/images/TC/logo-tc-52549811-1.png",
        },
        {
          name: "url",
          property: "og:url",
          content: `${this.configs.WEBSITE_URL}`,
        },
        {
          name: "subject",
          content: "Tour Company in Chiang Mai",
        },
        {
          name: "type",
          property: "og:type",
          content: "business.business",
        },
        {
          name: "site_name",
          property: "og:site_name",
          content:
            " Tour in Chiang Mai and around &amp; Activities with Premium Service : Touring Center",
        },
      ],
      true
    );
    this.metaTagService.updateTag({
      name: "title",
      property: "og:title",
      content:
        "Tour in Chiang Mai and around &amp; Activities with Premium Service : Touring Center",
    });
    this.metaTagService.updateTag({
      name: "description",
      property: "og:description",
      content:
        "Explore and Experience tour in Chiang Mai and around from professional team with the unbeatable service. Touring Center provide a daily departure day tour and package for both join with group (small size) and private trip.",
    });
    this.metaTagService.updateTag({
      name: "keywords",
      content:
        "Tour in Chiang Mai, Day Tour, Chiang Mai Tour, Day Trip, Join Premium, Join Tour, Private Tour, Touring Center, Yi Peng Chiang Mai, chiang mai tour company, Chiang Mai, small group",
    });
    this.metaTagService.updateTag({
      name: "image",
      property: "og:image",
      content:
        `${this.configs.WEBSITE_URL}` +
        "assets/images/TC/logo-tc-52549811-1-sh.png",
    });
    this.metaTagService.updateTag({
      name: "url",
      property: "og:url",
      content: `${this.configs.WEBSITE_URL}`,
    });
  }
}
