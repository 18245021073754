<!-- <app-header-non-fixed></app-header-non-fixed> -->

<!-- ---------- image main Update / Update Booking Page ----------- -->
<!-- <div class="bg-payment-page">
  <div class="centered_">
    <span class="tittle-page" >Update Book</span>
   </div>
</div> -->

<div class="container body-navbar">
  <span class="font-3r color-red-pastel inline-h"><b>UPDATE &nbsp; BOOKING</b></span>
    <hr class="hr-1"><br>
</div>

<!-- ------------ Update Content -------------- -->
<section>
      <div class="container">
          <!-- Form Update -->
          <div class="form-update-content">
              <div class="row">
                <!-- form update Guest -->
                <div class="border-1 form-guest col-sm-12 col-md-7 col-sm-7 col-lg-7 col-xl-7">
                  <label><span class="title-information-guest">Information Guest</span></label>
                    <div class="shadow p-3 mb-5 bg-white rounded">
                          <span>Person 1.</span><br><br>
                          <mat-form-field appearance="outline" class="example-full-width" style="width: 100%;">
                            <mat-label>name</mat-label>
                            <input matInput [(ngModel)]="primary_informaiton.fullname" name="fullname" placeholder="fullname" disabled>
                          </mat-form-field>
                          <mat-form-field appearance="outline" class="example-full-width" class="form-input-email">
                            <mat-label>email</mat-label>
                            <input matInput [(ngModel)]="primary_informaiton.email" name="email" placeholder="Email" disabled>
                          </mat-form-field>
                          &nbsp;
                          <mat-form-field appearance="outline" class="example-full-width" class="form-input-phone">
                            <mat-label>phone</mat-label>
                            <input matInput [(ngModel)]="primary_informaiton.phone" name="phone" placeholder="Phone" disabled>
                          </mat-form-field>
                          <br>
                          <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-label>Hotel *</mat-label>
                                <mat-select [(value)]="primary_informaiton.hotel">
                                    <mat-option value="none">None</mat-option>
                                    <mat-option *ngFor="let hotel of getaccountcode" [value]="hotel.hotel">{{hotel.hotel}}</mat-option>
                                    <mat-option value="other">Other</mat-option>
                            </mat-select>
                            <!-- <a *ngIf="check_hotel == true" style="color:red; font-size: 0.6rem;">Please enter a valid hotel</a>  -->
                          </mat-form-field>
                          <mat-form-field *ngIf="primary_informaiton.hotel == 'other'" class="example-full-width" style="width: 100%;" appearance="outline">
                            <mat-label>Your Hotel Name *</mat-label>
                            <input matInput [(ngModel)] ="primary_informaiton.hotel_other">
                          </mat-form-field>
                    </div>
                    <div class="" *ngFor="let guest_name of guest_name; let i = index;">
                          <span>Person {{i+2}}.</span>
                            <mat-form-field appearance="outline" class="example-full-width" style="width: 100%;">
                              <mat-label>name</mat-label>
                            <input matInput [(ngModel)]="guest_name.fullname" name="fullname_{{i}}" placeholder="Fullname">
                              <!-- <a *ngIf="check_name == true" style="color:red; font-size: 0.6rem;">Please enter a valid hotel</a> -->
                            </mat-form-field>
                      </div>
                  </div>
                  <!-- Remark & btn Update -->
                  <div class="col-sm-12 col-md-5 col-sm-5 col-lg-5 col-xl-5" style="margin-top:2%">
                        <div class="remark-content">
                          <span class="remark-text">*All travelers' name are needed in order to do the travel accident insurance during the trip.</span>
                        </div>
                        <hr>
                        <div class="btn-update">
                            <a mat-raised-button color="warn" class="btn-update-1" style="color: white;" (click)="callUpdateTransaction()">Update</a><br>
                            <span *ngIf="reload == true"><br><img class="icon-send-gif" src="./assets/images/contact/Ellipsis-1.6s-197px (1).gif"></span>
                        </div>
                        <br>
                        <div class="content-back">
                            <a (click)="back()" class="btn-back pointer"><span><u>back to receipt</u></span></a>
                        </div>
                  </div>
              </div>
          </div>
      </div>
</section>

<!-- ------- Footter ---------- -->
<!-- <app-footer></app-footer> -->
