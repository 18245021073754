import { Component, OnInit, TemplateRef } from "@angular/core";
// import { Http, RequestOptions } from "@angular/http";
// import { Router, ActivatedRoute } from "@angular/router";
// import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
// import { map } from "rxjs/operators";
import { Meta } from "@angular/platform-browser";
import Swal from "sweetalert2";
import { ApiServiceService } from "./../../services/api-service.service";
import { EnvService } from "src/app/services/env.service";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
  public configs: any = {};

  public fullname = <any>"";
  public email = <any>"";
  public message = <any>"";
  public checksendEmail = <any>"";
  public check_email: boolean = <any>"";
  public check_message: boolean = <any>"";
  public check_fullname: boolean = <any>"";

  reload: boolean = false;

  icon_down_one: boolean = true;
  icon_down_two: boolean = true;
  icon_down_three: boolean = true;
  icon_down_four: boolean = true;

  icon_up_one: boolean = false;
  icon_up_two: boolean = false;
  icon_up_three: boolean = false;
  icon_up_four: boolean = false;

  getIcon_ = <any>"";

  constructor(
    // private http: Http,
    // private router: Router,
    // private meta: Meta,
    private metaTagService: Meta,
    private apiService: ApiServiceService,
    private envService: EnvService
  ) {
    let config = this.envService.getConfig();
    this.configs = config;
  }

  // <<< Initial >>>
  ngOnInit(): void {
    this.metaTagUpdate();
  }

  // <<< -----------------------------------------
  // ------------------- Call API ----------------
  // ----------------------------------------- >>>
  // send email
  callSendEmail(): void {
    this.reload = true;

    const url = "Email/SendEmailContact";
    let setPayload = {
      fullname: this.fullname,
      email: this.email,
      message: this.message,
    };

    this.apiService
      .callPost(url, setPayload)
      .toPromise()
      .then(
        (result) => [
          (this.checksendEmail = result),
          console.log(this.checksendEmail),
        ],
        (err) => [console.log(err)]
      )
      .then(() => {
        // this.checkTourListCategories();
        if (this.checksendEmail.status == true) {
          Swal.fire({
            icon: "success",
            text: this.checksendEmail.message,
            showConfirmButton: false,
            timer: 2000,
          });

          // reset data
          this.fullname = "";
          this.email = "";
          this.message = "";
        } else {
          Swal.fire({
            icon: "error",
            text: this.checksendEmail.message,
            showConfirmButton: false,
            timer: 2000,
          });
        }
        this.reload = false;
      });
  }

  // <<< -----------------------------------------
  // ------------------- Validate ----------------
  // ----------------------------------------- >>>
  checkValidateField() {
    if (this.fullname !== "" && this.email !== "" && this.message !== "") {
      this.callSendEmail();
    }
    if (
      this.fullname == "" ||
      this.fullname == null ||
      this.fullname == undefined
    ) {
      this.check_fullname = true;
    } else {
      this.check_fullname = false;
    }
    if (this.email == "" || this.email == null || this.email == undefined) {
      this.check_email = true;
    } else {
      this.check_email = false;
    }
    if (
      this.message == "" ||
      this.message == null ||
      this.message == undefined
    ) {
      this.check_message = true;
    } else {
      this.check_message = false;
    }
  }

  // <<< -----------------------------------------
  // ------------------- Meta Tag ----------------
  // ----------------------------------------- >>>
  metaTagUpdate() {
    this.metaTagService.updateTag({
      name: "title",
      property: "og:title",
      content:
        "Tour in Chiang Mai and around &amp; Activities with Premium Service : Touring Center",
    });
    this.metaTagService.updateTag({
      name: "description",
      property: "og:description",
      content:
        "Explore and Experience tour in Chiang Mai and around from professional team with the unbeatable service. Touring Center provide a daily departure day tour and package for both join with group (small size) and private trip.",
    });
    this.metaTagService.updateTag({
      name: "keywords",
      content:
        "Tour in Chiang Mai, Day Tour, Chiang Mai Tour, Day Trip, Join Premium, Join Tour, Private Tour, Touring Center, Yi Peng Chiang Mai, chiang mai tour company, Chiang Mai, small group",
    });
    this.metaTagService.updateTag({
      name: "image",
      property: "og:image",
      content:
        `${this.configs.WEBSITE_URL}` +
        "assets/images/TC/logo-tc-52549811-1-sh.png",
    });
    this.metaTagService.updateTag({
      name: "url",
      property: "og:url",
      content: `${this.configs.WEBSITE_URL}`,
    });
  }
}
