<!-- ------------- content Contact ------------------ -->
<div class="title-contact body-navbar">
    <span class="font-3r color-red-pastel"><b>CONTACT US</b></span>
    <hr class="hr-1">
    <div class="map-google">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d236.07472180688976!2d98.99118842999948!3d18.78943670672814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30da3aa0b0e4fdc3%3A0xc6151e459a94bc5e!2sTouring%20Center!5e0!3m2!1sen!2sth!4v1596071551381!5m2!1sen!2sth" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
    </div>
    <div class="form-contact">
      <div class="row">
        <!-- form help -->
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="title-form-contact">
              <span class="font-2r">How can we help?</span>
            </div>
            <hr class="hr1">
            <div id="accordion">

              <!-- Form contact -->
              <div class="card card-custom">
                <div class="card-header card-custom" id="headingThree">
                  <h4 class="mb-0"><b>Send an email</b></h4>
                </div>
                <div id="collapseThree" class="collapse show" aria-labelledby="headingThree" data-parent="#accordion">
                  <div class="card-body">
                    <div class="form-group form-send-email font-08r">
                      <a *ngIf="check_fullname == true" style="color:red">Please enter a valid fullname</a> 
                      <input type="text" class="form-control font-08r" id="InputName" [(ngModel)]="fullname"  placeholder="Your Name *"><br>
                      <a *ngIf="check_email == true" style="color:red">Please enter a valid email address</a> 
                      <input type="email" class="form-control font-08r" id="InputEmail" [(ngModel)]="email" aria-describedby="emailHelp"  placeholder="@Email *"> <br>
                      <a *ngIf="check_message == true" style="color:red">Please enter a valid message</a> 
                      <textarea type="textarea" class="form-control area-h font-08r" id="Inputmessage" [(ngModel)]="message"  placeholder="Your Message *"></textarea> <br>
                      <div class="centered">
                        <button mat-raised-button class="btn-send-mail" (click)="checkValidateField()">Send <i class="fa fa-envelope-o" aria-hidden="true"></i></button>
                        <span *ngIf="reload == true"><br><img class="icon-send-gif" src="./../../../assets/images/contact/Ellipsis-1.6s-197px (1).gif"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="hr1">
            </div>
          </div>
        <!-- form address & social -->
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="title-form-contact" style="visibility: hidden;">
              <span class="font-2r">How can we help?</span>
            </div>
            <div class="form-address">
              <div class="tc-logo-content">
                <img class="tc-logo" src="./../../../assets/images/logo/TC-LOGO-2024.png">
                </div>
              <div class="address-table">
                <table>
                    <tr>
                      <td class="td-1">Address</td>
                      <td class="td-1">:</td>
                      <td>14, Soi 5, Ratchadamnoen Road, Sriphum, Muang, Chiang Mai 50200 Thailand</td>
                      <!-- <td>13-14 (2nd Floor), Ratchadamnoen Plaza, Ratchadamnoen Road, Sriphum, Muang, Chiang Mai 50200 Thailand</td> -->
                    </tr>
                    <tr>
                      <td class="td-1">Mobile</td>
                      <td class="td-1">:</td>
                      <td><a href="tel:+66882585817">+66 (0) 88 258 5817</a></td>
                    </tr>
                    <tr>
                      <td class="td-1">Tel</td>
                      <td class="td-1">:</td>
                      <td><a href="tel:+6653259644">+66 (0) 53 289 644 </a></td>
                    </tr>
                    <tr>
                      <td class="td-1">Email</td>
                      <td class="td-1">:</td>
                      <td><a href="mailto:reservations@touringcenter.com">reservations@touringcenter.com</a></td>
                    </tr>
                    <!-- <tr>
                      <td class="td-1"></td>
                      <td class="td-1">:</td>
                      <td><a href="mailto:tour@touringcenter.com">tour@touringcenter.com</a></td>
                    </tr> -->
                    <tr>
                      <td class="td-1">Hours</td>
                      <td class="td-1">:</td>
                      <td>Daily 07.00 a.m. - 08.30 p.m.</td>
                    </tr>
                </table>
                <div class="footer-icon">
                  <a class="footer-icon-size facebook-icon pointer" href="https://www.facebook.com/toursinchiangmai" target="blank"></a> &nbsp; &nbsp;
                  <a class="footer-icon-size youtube-icon pointer" href="https://www.youtube.com/channel/UCqx6cadulg7se3n-991yDrA" target="blank"></a> &nbsp; &nbsp;
                  <!-- <a class="footer-icon-size twitter-icon pointer" href="https://twitter.com/chiangmaisights" target="blank"></a> &nbsp; &nbsp; -->
                  <a class="footer-icon-size line-icon pointer" href="https://line.me/ti/p/~touringcnx.com" target="blank"></a> &nbsp; &nbsp;
                  <a class="footer-icon-size whatsapp-icon pointer" href="https://wa.me/66882585817" target="blank"></a>
                </div> 
              </div>
            </div>
            <div class="btn-message">
              <a mat-stroked-button class="btn-meg" href="https://m.me/toursinchiangmai" target="blank"><div class="icon-meg"></div> Chat with Us</a>
            </div>
          </div>
      </div>
    </div>
</div>