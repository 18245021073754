import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { EnvService } from "src/app/services/env.service";
import { EventsService } from 'src/app/services/events.service';
import { displayDate } from 'src/app/helpers'
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {
  readonly displayDate = displayDate;

  public configs: any = {};
  public pagine: number = 1;
  public imgCoverPath = <any>'';

  public id: number = 0;
  public event: any = {};

  modalRef: BsModalRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private envService: EnvService,
    private eventsService: EventsService,
    public sanitizer: DomSanitizer,
  ) {
    let config = this.envService.getConfig();
    this.configs = config;
  }

  ngOnInit(): void {
    this.id = +this.route.snapshot.params['id'];

    if (this.id) {
      this.getEvent();
    }
  }

  getEvent(): void {
    const payload = { id: this.id };

    this.eventsService.getEvent(payload).subscribe((res:any) => {
      if (res?.status) {
        this.event = res?.data || {};
        if (this.event?.video?.link) {
          this.event.video.link = this.sanitizer.bypassSecurityTrustHtml(this.event.video.link);
        }
        // console.log("getEvent : ", this.id, this.event);
      }
    })
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "gray modal-lg" })
    );
  }

  toggleTermDialog(event: boolean = false): void {
    if (event) {
      this.modalRef.hide()
    }
  }

  onClickBooking(): void {
    this.router.navigate(['event/booking', this.id]);
  }

  go(route: string): void {
    this.router.navigate([route]);
  }

  openPDF() {
    window.open(this.event?.document, "_blank");
  }
}
