<link rel="stylesheet" type="text/css" href="/assets/templates/Login_v18/vendor/select2/select2.min.css" />
<link rel="stylesheet" type="text/css" href="/assets/templates/Login_v18/css/util.css" />
<link rel="stylesheet" type="text/css" href="/assets/templates/Login_v18/css/main.css" />
<link rel="stylesheet" type="text/css" href="/assets/templates/Login_v18/vendor/daterangepicker/daterangepicker.css">

<!-- Template -->
<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <form class="login100-form validate-form">
        <i
          class="fa fa-sign-out fa-3x"
          title="LOGOUT"
          aria-hidden="true"
          style="color: #3e3e3e; position: absolute; top: 20px; right: 20px; cursor: pointer;"
          (click)="onClickLogout()"
        ></i>

        <span class="login100-form-title p-b-43">
          CLOSE TOUR
          <i
            class="fa fa-calendar-times-o fa-1x"
            aria-hidden="true"
            style="position: relative; top: -3px; padding-left: 5px;"
          ></i>
        </span>

        <div class="wrap-select100 validate-select">
          <select
            class="select100"
            name="tour_code"
            [(ngModel)]="input.tour_code"
          >
            <!-- <option [value]=""> เลือกทัวร์ </option> -->
            <option
              *ngFor="let item of tour_list"
              [value]="item.tour"
            >
              {{ item.tour }} : {{ item.title }}
            </option>
          </select>
          <span class="focus-select100"></span>
					<span class="label-select100" *ngIf="!input.tour_code">Select tour</span>
        </div>

        <!-- Date -->
        <div class="wrap-input100 validate-input">
          <input
            class="input100"
            type="date"
            name="date"
            [(ngModel)]="input.date"
          />
          <span class="focus-input100"></span>
					<span class="label-input100" *ngIf="!input.date"></span>
        </div>

        <!-- Remark -->
        <div class="wrap-input100 validate-input">
          <input
            class="input100"
            [(ngModel)]="input.remark"
          >
          <span class="focus-input100"></span>
					<span class="label-input100" *ngIf="!input.remark">Remark</span>
        </div>

        <!-- Btn save -->
        <div class="container-login100-form-btn">
          <button class="submit-form-btn" (click)="onClickSave()">Save</button>
        </div>

        <div class="text-center p-t-46 p-b-20">
          <!-- <span class="txt2"> or sign up using </span> -->
        </div>
      </form>

      <div
        class="login100-more"
        style="background-image: url('http://api.tourinchiangmai.com/images/TC-R05/5f98fb50e25b3-Head - Inthanon.jpg');"
      ></div>
    </div>
  </div>
</div>

<!-- Import script -->
<script src="vendor/daterangepicker/daterangepicker.js"></script>
