import { Injectable } from '@angular/core';
import { ApiServiceService } from "./api-service.service";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private path = "Website/Transaction"
  private pathPayment = "Website/Payment";

  constructor(private service: ApiServiceService) { }

  getTransactionById(payload: any = {}): Observable<any> {
    return this.service.callPost(`${this.path}/GetTransactionById`, payload);
  }

  getPaymentByTransactionId(payload: any = {}): Observable<any> {
    return this.service.callPost(`${this.pathPayment}/GetPaymentByTransactionId`, payload);
  }

  updatePaymentById(payload: any = {}): Observable<any> {
    return this.service.callPost(`${this.pathPayment}/UpdatePaymentById`, payload);
  }

  checkPaymentPaid(payload: any = {}): Observable<any> {
    return this.service.callPost(`${this.pathPayment}/CheckPaymentPaid`, payload);
  }

}