<!-- <app-header></app-header> -->

<!-- ------- Cover Images Activities ---------- -->
<section class="py-5 module parallax parallax-1 rellax-header" data-rellax-speed="2">
  <div class="centered_">
    <span class="tittle-page" >Activities</span>
   </div>
</section>

<!-- ------------- content Activities ------------------ -->
 <!-- <section>
  <div class="container">
      <div class="centered_text title"> -->
          <!-- Our Activities -->
      <!-- </div>
      <div class="btn-menu">
      <button mat-raised-button class="activities-btn" (click)="checkContent('all')">ALL</button> &nbsp;
        <button mat-raised-button class="activities-btn" (click)="checkContent('csr')">CSR</button> &nbsp;
        <button mat-raised-button class="activities-btn" (click)="checkContent('famtrip')">FAMTRIP</button> &nbsp;
        <button mat-raised-button class="activities-btn" (click)="checkContent('youtube')">YOUTUBE</button>
      </div>
      <br> -->
      <!-- CSR -->
      <!-- <div *ngIf="content_csr == true" class="List-Activities">
          <div class="row flex-column-reverse flex-md-row">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 pad-0">
              <div class="blog-content-activities">
                <div class="font-w">
                  <span>Corporate Social Responsibility (CSR)</span><br>
                  <div class="topic-title">
                      <span class="font-3-5r">Love Out Loud</span>
                    </div>
                  <span>
                    On January 29, 2020, We, the Touring Center, recognize 
                    the importance of tourism with the community. Including 
                    the potential of people in the community ....
                  </span><br>
                  <button mat-raised-button class="btn-content-more" [routerLink]="['/love-out-loud']">Read More</button>
                 </div> 
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 pad-0">
              <div class="blog-img">
                <img class="img-activities" src="./../../assets/images/activities/CSR(S02)-photo-ceontent-CSR.jpg">
              </div>
            </div>
          </div>
      </div> 
      <br> -->

      <!-- FRAMTRIP -->
      <!-- <div *ngIf="content_famtrip == true" class="List-Activities">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 pad-0">
            <div class="blog-img">
              <img class="img-activities" src="./../../assets/images/activities/DSCF1118.jpg">
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 pad-0">
            <div class="blog-content-activities" style="background-color: white; border: 1px solid #333333;">
              <div class="font-w" style="color: #333333">
                <span>Familiarization Trip (FAMTRIP)</span><br>
                <div class="topic-title">
                    <span class="font-3-5r">Breath of Nature</span>
                  </div>
                <span>
                  Familiarization Trip of Breath of Nature 2019
                </span><br>
                <button mat-raised-button class="btn-content-more" [routerLink]="['/breath-of-nature']">Read More</button>
               </div> 
            </div>
          </div>
        </div>
    </div>
    <br> -->

    <!-- YOUTUBE -->
    <!-- <div *ngIf="content_you == true" class="List-Activities">
      <div class="row flex-column-reverse flex-md-row">
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 pad-0">
          <div class="blog-content-activities" style="background-color: #1A2B49;">
            <div class="font-w">
              <span>Chiang Mai & Beyond (YOUTUBE)</span><br>
              <div class="topic-title">
                  <span class="font-2-5r">What is Chiang Mai?</span>
                </div>
              <span>
                Chiang Mai is the largest and most culturally significant city in northern Thailand.
                This video, we will help you understand more about Chiang Mai from our experienced guides.
              </span><br>
              <a type="button" mat-raised-button class="btn-content-more" style="background: white; color: #1A2B49" href="https://www.youtube.com/watch?v=hFvSshFSH-Y" target="blank">Watch</a>
             </div> 
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 pad-0">
          <div class="blog-img">
            <img class="img-activities" src="./../../assets/images/activities/YT-01.jpg">
          </div>
        </div>
      </div>
  </div> 

  </div>
</section>  -->
<!-- <br>
<br> -->

<!-- <section>
  <div class="btn-menu">
    <button mat-raised-button class="activities-btn" (click)="checkContent('all')">ALL</button> &nbsp;
      <button mat-raised-button class="activities-btn" (click)="checkContent('csr')">CSR</button> &nbsp;
      <button mat-raised-button class="activities-btn" (click)="checkContent('famtrip')">FAMTRIP</button> &nbsp;
      <button mat-raised-button class="activities-btn" (click)="checkContent('youtube')">YOUTUBE</button>
    </div>
</section> -->

<!-- -------------------------- CSR ------------------------ -->
<!-- <section>
  <div *ngIf="content_csr == true" class="List-Activities">
  <div class="container">
  <ul class="title-ul">
    <li>
      <div class="card">
        <div class="card-body content-title" > 
          <div class="bg-content">
            <span class="font-3r color-red-pastel">Corporate Social Responsibility <span class="code-title">(CSR)</span></span><br><br>
            <span class="des-content">
              On January 29, 2020, We, the Touring Center, recognize 
              the importance of tourism with the community. Including 
              the potential of people in the community ....
            </span><br>
          </div>
        </div>
      </div>
    </li>
    <li>
      <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
          <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="w-100 pointer" src="../../../assets/images/activities/about-01-251674.jpg" [routerLink]="['/love-out-loud']">
            <div class="carousel-caption d-none d-md-block">
              <div style="padding: 2%; background-color: rgb(15, 15, 15,0.25); margin: 0 20%;">
                  <span style="color:white; font-weight: bold; font-size: 1.5rem;">Love Out Loud</span>
              </div>
            </div>
          </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </li>
  </ul>
</div>
</div>
</section>

<br> -->

<!-- -------------------------- FAM TRIP ------------------------ -->
<!-- <section>
  <div *ngIf="content_famtrip == true" class="List-Activities">
  <div class="container">
  <ul class="title-ul">
    <li>
      <div class="card">
        <div class="card-body content-title" > 
          <div class="bg-content">
            <span class="font-3r color-red-pastel">Familiarization Trip<span class="code-title">(FAM TRIP)</span></span><br><br>
            <span class="des-content">

            </span><br>
          </div>
        </div>
      </div>
    </li>
    <li>
      <div id="carouselExampleIndicators2" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
          <li data-target="#carouselExampleIndicators2" data-slide-to="0" class="active"></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="w-100 pointer" src="../../../assets/images/activities/FAM.jpg" [routerLink]="['/breath-of-nature']">
            <div class="carousel-caption d-none d-md-block">
              <div style="padding: 2%; background-color: rgb(15, 15, 15,0.25); margin: 0 20%;">
                  <span style="color:white; font-weight: bold; font-size: 1.5rem;">Breath of Nature</span>
              </div>
            </div>
          </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators2" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators2" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </li>
  </ul>
</div>
</div>
</section>
<br> -->

<!-- -------------------------- YOUTUBE ------------------------ -->
<!-- <section>
  <div *ngIf="content_you == true" class="List-Activities">
  <div class="container">
  <ul class="title-ul">
    <li>
      <div class="card">
        <div class="card-body content-title" > 
          <div class="bg-content">
            <span class="font-3r color-red-pastel">Chiang Mai & Beyond <span class="code-title">(Youtube)</span></span><br><br>
            <span class="des-content">
              Chiang Mai is the largest and most culturally significant city in northern Thailand.
              This video, we will help you understand more about Chiang Mai from our experienced guides.
              .....
            </span><br>
          </div>
        </div>
      </div>
    </li>
    <li>
      <div id="carouselExampleIndicators3" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
          <li data-target="#carouselExampleIndicators3" data-slide-to="0" class="active"></li>
          <li data-target="#carouselExampleIndicators3" data-slide-to="1" ></li>
          <li data-target="#carouselExampleIndicators3" data-slide-to="2" ></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <a href="https://www.youtube.com/watch?v=hFvSshFSH-Y"><img class="w-100" src="../../../assets/images/activities/EP1-W.jpg"></a>
          </div>
          <div class="carousel-item">
            <a href="https://www.youtube.com/watch?v=TNA7zQOL6b8"><img class="w-100" src="../../../assets/images/activities/EP2-W.jpg"></a>
          </div>
          <div class="carousel-item">
            <a href="https://www.youtube.com/watch?v=ozCHdj14N68"><img class="w-100" src="../../../assets/images/activities/EP3-W.jpg"></a>
          </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators3" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators3" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </li>
  </ul>
</div>
</div>
</section> -->


<!-- <div class="card-container">
  <div class="card u-clearfix">
    <div class="card-body">
      <span class="card-number card-circle subtle">01</span>
      <span class="card-author subtle">John Smith</span>
      <h2 class="card-title">New Brunch Recipe</h2>
      <span class="card-description subtle">These last few weeks I have been working hard on a new brunch recipe for you all.</span>
      <div class="card-read">Read</div>
      <span class="card-tag card-circle subtle">C</span>
    </div>
    <img src="https://s15.postimg.cc/temvv7u4r/recipe.jpg" alt="" class="card-media" />
  </div>
  <div class="card-shadow"></div>
</div> -->

<div class="container">
  <div class="blog-content">

<div class="csr-content">
      <span class="title-content color-red-pastel">CSR</span><br><br>
      <span class="title-sub-content">: Corporate Social Responsibility</span><br>
      <div class="shadow-none p-3 bg-light rounded descript-content">
        On January 29, 2020, We, the Touring Center, recognize 
        the importance of tourism with the community. Including 
        the potential of people in the community.
       </div>

      <div class="list-item-content">
      <owl-carousel-o [options]="CSROptions">

        <ng-template carouselSlide >
          <div class="card color-3 mr-2 pointer border-card-view" [routerLink]="['/love-out-loud']">
            <img class="card-img-top img-tours-view" src="../../../assets/images/activities/CSR(S02)-photo-ceontent-CSR.jpg">
              <div class="card-body">
                <div class="text-centered">
                  <span class="item-title">Love out loud</span>
                </div> 
              </div>
           </div>
        </ng-template>

        <ng-template carouselSlide style="visibility: hidden;">
          <div class="card color-3 mr-2 pointer" style="visibility: hidden;">
            <img class="card-img-top img-tours-view" src="">
              <div class="card-body">
                <div class="text-centered" target="blank">
                  <span></span>
                </div> 
              </div>
           </div>
        </ng-template>

        <ng-template carouselSlide style="visibility: hidden;">
          <div class="card color-3 mr-2 pointer" style="visibility: hidden;">
            <img class="card-img-top img-tours-view" src="">
              <div class="card-body">
                <div class="text-centered" target="blank">
                  <span></span>
                </div> 
              </div>
           </div>
        </ng-template>
        
    </owl-carousel-o>
  </div>
</div> 

<hr class="hr-2">

<div class="famtrip-content">
      <span class="title-content color-red-pastel">FAM TRIP</span><br><br>
      <span class="title-sub-content">: Familiarization Trip</span><br>
      <div class="shadow-none p-3 bg-light rounded descript-content">
       Touring Center organizes Familiarization Trip (Fam Trip) to our partners (Hotels & Tour desks) 
        in order to update products and experience our service standard every month; moreover, we establish 
        intimacy and spent our time doing activities together during the trip. The partners can gain confidence 
        while selling the product to you, and they may have a chance to meet new friends who work in the same industry 
        from this FAM Trip. Therefore, they can answer your questions when you would like to spend a purchase on your wonderful trip.
      </div>

      <div class="list-item-content">
      <owl-carousel-o [options]="CSROptions">

        <ng-template carouselSlide >
          <div class="card color-3 mr-2 pointer border-card-view" [routerLink]="['/breath-of-nature']">
            <img class="card-img-top img-tours-view" src="../../../assets/images/activities/DSCF1118.jpg">
              <div class="card-body">
                <div class="text-centered" target="blank">
                  <span class="item-title">Breath of Nature</span>
                </div> 
              </div>
           </div>
        </ng-template>

        <ng-template carouselSlide style="visibility: hidden;">
          <div class="card color-3 mr-2 pointer" style="visibility: hidden;">
            <img class="card-img-top img-tours-view" src="">
              <div class="card-body">
                <div class="text-centered" target="blank">
                  <span></span>
                </div> 
              </div>
           </div>
        </ng-template>

        <ng-template carouselSlide style="visibility: hidden;">
          <div class="card color-3 mr-2 pointer" style="visibility: hidden;">
            <img class="card-img-top img-tours-view" src="">
              <div class="card-body">
                <div class="text-centered" target="blank">
                  <span></span>
                </div> 
              </div>
           </div>
        </ng-template>
        
    </owl-carousel-o>
  </div>
</div> 

<hr class="hr-2">

<div class="youtube-content">
  <span  class="title-content color-red-pastel">YOUTUBE</span><br><br>
  <span class="title-sub-content">: Touring Center</span><br>
  <div class="shadow-none p-3 bg-light rounded descript-content">
    Chiang Mai is the largest and most culturally significant city in northern Thailand.
    This video, we will help you understand more about Chiang Mai from our experienced guides.
  </div>
  

  <div class="list-item-content">
  <owl-carousel-o [options]="CSROptions">

    <ng-template carouselSlide >
      <a href="https://www.youtube.com/watch?v=hFvSshFSH-Y" target="blank">
      <div class="card color-3 mr-2 pointer border-card-view">
        <img class="card-img-top img-tours-view" src="../../../assets/images/activities/YT-E01.jpg">
          <div class="card-body">
            <div class="text-centered" target="blank">
              <span class="item-title">Chiang Mai & Beyond EP.1</span> 
            </div> 
          </div>
       </div>
      </a> 
    </ng-template>

    
    <ng-template carouselSlide>
      <a href="https://www.youtube.com/watch?v=TNA7zQOL6b8" target="blank">
      <div class="card color-3 mr-2 pointer border-card-view">
        <img class="card-img-top img-tours-view" src="../../../assets/images/activities/YT-E02.jpg">
          <div class="card-body">
            <div class="text-centered" target="blank">
              <span class="item-title">Chiang Mai & Beyond EP.2</span>
            </div> 
          </div>
       </div>
      </a> 
    </ng-template>
    

    <ng-template carouselSlide>
      <a href="https://www.youtube.com/watch?v=ozCHdj14N68" target="blank">
      <div class="card color-3 mr-2 pointer border-card-view">
        <img class="card-img-top img-tours-view" src="../../../assets/images/activities/YT-E03.jpg">
          <div class="card-body">
            <div class="text-centered" target="blank">
              <span class="item-title">Chiang Mai & Beyond EP.3</span>
            </div> 
          </div>
       </div>
      </a> 
    </ng-template>
    
</owl-carousel-o>
</div>
</div> 


  </div>
</div>

<!-- ------- Footter ---------- -->
<!-- <app-footer></app-footer> -->
