<div class="container">
  <p>ทัวร์ริ่ง เซนเตอร์ (บริษัท เดย์ทริป จำกัด) ให้ความสำคัญกับการคุ้มครองข้อมูลส่วนบุคคลของคุณ โดยนโยบายความเป็นส่วนตัวฉบับนี้ได้อธิบายแนวปฏิบัติเกี่ยวกับการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล รวมถึงสิทธิต่าง ๆ ของเจ้าของข้อมูลส่วนบุคคล ตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล</p>
  <br/>

  <div class="content">
    <label><b>การเก็บรวบรวมข้อมูลส่วนบุคคล</b></label>
    <p>เราจะเก็บรวบรวมข้อมูลส่วนบุคคลที่ได้รับโดยตรงจากคุณผ่านช่องทาง ดังต่อไปนี้</p>
    <ul>
      <li>การสมัครสมาชิก</li>
      <li>โทรศัพท์</li>
      <li>อีเมล</li>
      <li>Facebook Login</li>
      <li>Google Login</li>
      <li>LINE Login</li>
      <li>Twitter Login</li>
      <li>LinkedIn Login</li>
    </ul>
    <p>เราอาจเก็บรวบรวมข้อมูลส่วนบุคคลของคุณที่เราเข้าถึงได้จากแหล่งอื่น เช่น เสิร์ชเอนจิน โซเชียลมีเดีย หน่วยงานราชการ บุคคลภายนอกอื่น ๆ เป็นต้น</p>
  </div>
  <br/>

  <div class="content">
    <label><b>ประเภทข้อมูลส่วนบุคคลที่เก็บรวบรวม</b></label>
    <p>
      <b>ข้อมูลส่วนบุคคล </b>
      เช่น ชื่อ นามสกุล อายุ วันเดือนปีเกิด สัญชาติ เลขประจำตัวประชาชน หนังสือเดินทาง เป็นต้น
    </p>
    <p>
      <b>ข้อมูลการติดต่อ </b>
      เช่น ที่อยู่ หมายเลขโทรศัพท์ อีเมล เป็นต้น
    </p>
    <p>
      <b>ข้อมูลบัญชี </b>
      เช่น บัญชีผู้ใช้งาน ประวัติการใช้งาน เป็นต้น
    </p>
    <p>
      <b>หลักฐานแสดงตัวตน </b>
      เช่น สำเนาบัตรประจำตัวประชาชน สำเนาหนังสือเดินทาง เป็นต้น
    </p>
    <!-- <p>
      <b>ข้อมูลการทำธุรกรรมและการเงิน </b>
      เช่น ประวัติการสั่งซื้อ รายละเอียดบัตรเครดิต บัญชีธนาคาร เป็นต้น
    </p> -->
    <p>
      <b>ข้อมูลทางเทคนิค </b>
      เช่น IP address, Cookie ID, ประวัติการใช้งานเว็บไซต์ (Activity Log) เป็นต้น
    </p>
    <p>
      <b>ข้อมูลอื่น ๆ </b>
      เช่น รูปภาพ ภาพเคลื่อนไหว และข้อมูลอื่นใดที่ถือว่าเป็นข้อมูลส่วนบุคคลตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล เราจะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลอ่อนไหว ดังต่อไปนี้ เมื่อเราได้รับความยินยอมโดยชัดแจ้งจากคุณ เว้นแต่กฎหมายกำหนดให้ทำได้
      <ul>
        <li>เชื้อชาติ</li>
        <li>ความพิการ</li>
      </ul>
      ข้อมูลอื่นใดที่กระทบต่อข้อมูลส่วนบุคคลของคุณตามที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลประกาศกำหนด
  </div>
  <br/>

  <div class="content">
    <label><b>ผู้เยาว์</b></label>
    <p>หากคุณมีอายุต่ำกว่า 20 ปีหรือมีข้อจำกัดความสามารถตามกฎหมาย เราอาจเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ เราอาจจำเป็นต้องให้พ่อแม่หรือผู้ปกครองของคุณให้ความยินยอมหรือที่กฎหมายอนุญาตให้ทำได้ หากเราทราบว่ามีการเก็บรวบรวมข้อมูลส่วนบุคคลจากผู้เยาว์โดยไม่ได้รับความยินยอมจากพ่อแม่หรือผู้ปกครอง เราจะดำเนินการลบข้อมูลนั้นออกจากเซิร์ฟเวอร์ของเรา</p>
  </div>
  <br/>

  <div class="content">
    <label><b>วิธีการเก็บรักษาข้อมูลส่วนบุคคล</b></label>
    <p>เราจะเก็บรักษาข้อมูลส่วนบุคคลของคุณในรูปแบบเอกสารและรูปแบบอิเล็กทรอนิกส์</p>
    <p>เราเก็บรักษาข้อมูลส่วนบุคคลของคุณ ดังต่อไปนี้</p>
    <ul><li>เซิร์ฟเวอร์บริษัทของเราในต่างประเทศ</li></ul>
  </div>
  <br/>

  <div class="content">
    <label><b>การประมวลผลข้อมูลส่วนบุคคล</b></label>
    <p>เราจะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณเพื่อวัตถุประสงค์ดังต่อไปนี้</p>
    <ul>
      <li>เพื่อสร้างและจัดการบัญชีผู้ใช้งาน</li>
      <li>เพื่อจัดส่งสินค้าหรือบริการ</li>
      <li>เพื่อปรับปรุงสินค้า บริการ หรือประสบการณ์การใช้งาน</li>
      <li>เพื่อการบริหารจัดการภายในบริษัท</li>
      <li>เพื่อการตลาดและการส่งเสริมการขาย</li>
      <li>เพื่อการบริการหลังการขาย</li>
      <li>เพื่อรวบรวมข้อเสนอแนะ</li>
      <li>เพื่อชำระค่าสินค้าหรือบริการ</li>
      <li>เพื่อปฏิบัติตามข้อตกลงและเงื่อนไข (Terms and Conditions)</li>
      <li>เพื่อปฏิบัติตามกฎหมายและกฎระเบียบของหน่วยงานราชการ</li>
    </ul>
  </div>
  <br/>

  <div class="content">
    <label><b>การเปิดเผยข้อมูลส่วนบุคคล</b></label>
    <p>เราอาจเปิดเผยข้อมูลส่วนบุคคลของคุณให้แก่ผู้อื่นภายใต้ความยินยอมของคุณหรือที่กฎหมายอนุญาตให้เปิดเผยได้ ดังต่อไปนี้</p>
  </div>
  <br/>

  <div class="content">
    <label><b>การบริหารจัดการภายในองค์กร</b></label>
    <p>เราอาจเปิดเผยข้อมูลส่วนบุคคลของคุณภายในบริษัทเท่าที่จำเป็นเพื่อปรับปรุงและพัฒนาสินค้าหรือบริการของเรา เราอาจรวบรวมข้อมูลภายในสำหรับสินค้าหรือบริการต่าง ๆ ภายใต้นโยบายนี้เพื่อประโยชน์ของคุณและผู้อื่นมากขึ้น</p>
  </div>
  <br/>

  <div class="content">
    <label><b>พันธมิตรทางธุรกิจ</b></label>
    <p>เราอาจเปิดเผยข้อมูลบางอย่างกับพันธมิตรทางธุรกิจเพื่อติดต่อและประสานงานในการให้บริการสินค้าหรือบริการ และให้ข้อมูลเท่าที่จำเป็นเกี่ยวกับความพร้อมใช้งานของสินค้าหรือบริการ</p>
  </div>
  <br/>

  <div class="content">
    <label><b>การบังคับใช้กฎหมาย</b></label>
    <p>ในกรณีที่มีกฎหมายหรือหน่วยงานราชการร้องขอ เราจะเปิดเผยข้อมูลส่วนบุคคลของคุณเท่าที่จำเป็นให้แก่หน่วยงานราชการ เช่น ศาล หน่วยงานราชการ เป็นต้น</p>
    <p>ในกรณีที่มีการเปิดเผยหรือโอนข้อมูลส่วนบุคคลจากเขตเศรษฐกิจยุโรป (EEA) ไปยังสหรัฐอเมริกา และประเทศอื่นนอกเขตเศรษฐกิจยุโรป (Non-EEA)  เราจะจัดให้มีมาตรการที่เหมาะสมในการโอนข้อมูลส่วนบุคคลไปยังต่างประเทศต่าง ๆ ตามที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลของสหภาพยุโรป (GDPR) กำหนดหรือที่กฎหมายอนุญาตให้กระทำได้</p>
  </div>
  <br/>

  <div class="content">
    <label><b>ระยะเวลาจัดเก็บข้อมูลส่วนบุคคล</b></label>
    <p>เราจะเก็บรักษาข้อมูลส่วนบุคคลของคุณไว้ตามระยะเวลาที่จำเป็นในระหว่างที่คุณเป็นลูกค้าหรือมีความสัมพันธ์อยู่กับเราหรือตลอดระยะเวลาที่จำเป็นเพื่อให้บรรลุวัตถุประสงค์ที่เกี่ยวข้องกับนโยบายฉบับนี้ ซึ่งอาจจำเป็นต้องเก็บรักษาไว้ต่อไปภายหลังจากนั้น หากมีกฎหมายกำหนดไว้ เราจะลบ ทำลาย หรือทำให้เป็นข้อมูลที่ไม่สามารถระบุตัวตนของคุณได้ เมื่อหมดความจำเป็นหรือสิ้นสุดระยะเวลาดังกล่าว</p>
  </div>
  <br/>

  <div class="content">
    <label><b>สิทธิของเจ้าของข้อมูลส่วนบุคคล</b></label>
    <p>ภายใต้กฎหมายคุ้มครองข้อมูลส่วนบุคคล  คุณมีสิทธิในการดำเนินการดังต่อไปนี้</p>
  </div>
  <br/>

  <div class="content">
    <label><b>สิทธิขอถอนความยินยอม (right to withdraw consent) </b></label>
    หากคุณได้ให้ความยินยอม เราจะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ ไม่ว่าจะเป็นความยินยอมที่คุณให้ไว้ก่อนวันที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลใช้บังคับหรือหลังจากนั้น คุณมีสิทธิที่จะถอนความยินยอมเมื่อใดก็ได้ตลอดเวลา
  </div>
  <br/>

  <div class="content">
    <label><b>สิทธิขอเข้าถึงข้อมูล (right to access) </b></label>
    คุณมีสิทธิขอเข้าถึงข้อมูลส่วนบุคคลของคุณที่อยู่ในความรับผิดชอบของเราและขอให้เราทำสำเนาข้อมูลดังกล่าวให้แก่คุณ  รวมถึงขอให้เราเปิดเผยว่าเราได้ข้อมูลส่วนบุคคลของคุณมาได้อย่างไร
  </div>
  <br/>

  <div class="content">
    <label><b>สิทธิขอถ่ายโอนข้อมูล (right to data portability) </b></label>
    คุณมีสิทธิขอรับข้อมูลส่วนบุคคลของคุณในกรณีที่เราได้จัดทำข้อมูลส่วนบุคคลนั้นอยู่ในรูปแบบให้สามารถอ่านหรือใช้งานได้ด้วยเครื่องมือหรืออุปกรณ์ที่ทำงานได้โดยอัตโนมัติและสามารถใช้หรือเปิดเผยข้อมูลส่วนบุคคลได้ด้วยวิธีการอัตโนมัติ รวมทั้งมีสิทธิขอให้เราส่งหรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่นเมื่อสามารถทำได้ด้วยวิธีการอัตโนมัติ และมีสิทธิขอรับข้อมูลส่วนบุคคลที่เราส่งหรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่นโดยตรง เว้นแต่ไม่สามารถดำเนินการได้เพราะเหตุทางเทคนิค
  </div>
  <br/>

  <div class="content">
    <label><b>สิทธิขอคัดค้าน (right to object) </b></label>
    คุณมีสิทธิขอคัดค้านการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณในเวลาใดก็ได้ หากการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณที่ทำขึ้นเพื่อการดำเนินงานที่จำเป็นภายใต้ประโยชน์โดยชอบด้วยกฎหมายของเราหรือของบุคคลหรือนิติบุคคลอื่น โดยไม่เกินขอบเขตที่คุณสามารถคาดหมายได้อย่างสมเหตุสมผลหรือเพื่อดำเนินการตามภารกิจเพื่อสาธารณประโยชน์
  </div>
  <br/>

  <div class="content">
    <label><b>สิทธิขอให้ลบหรือทำลายข้อมูล (right to erasure/destruction) </b></label>
    คุณมีสิทธิขอลบหรือทำลายข้อมูลส่วนบุคคลของคุณหรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวคุณได้ หากคุณเชื่อว่าข้อมูลส่วนบุคคลของคุณถูกเก็บรวบรวม ใช้ หรือเปิดเผยโดยไม่ชอบด้วยกฎหมายที่เกี่ยวข้องหรือเห็นว่าเราหมดความจำเป็นในการเก็บรักษาไว้ตามวัตถุประสงค์ที่เกี่ยวข้องในนโยบายฉบับนี้ หรือเมื่อคุณได้ใช้สิทธิขอถอนความยินยอมหรือใช้สิทธิขอคัดค้านตามที่แจ้งไว้ข้างต้นแล้ว
  </div>
  <br/>

  <div class="content">
    <label><b>สิทธิขอให้ระงับการใช้ข้อมูล (right to restriction of processing) </b></label>
    คุณมีสิทธิขอให้ระงับการใช้ข้อมูลส่วนบุคคลชั่วคราวในกรณีที่เราอยู่ระหว่างตรวจสอบตามคำร้องขอใช้สิทธิขอแก้ไขข้อมูลส่วนบุคคลหรือขอคัดค้านของคุณหรือกรณีอื่นใดที่เราหมดความจำเป็นและต้องลบหรือทำลายข้อมูลส่วนบุคคลของคุณตามกฎหมายที่เกี่ยวข้องแต่คุณขอให้เราระงับการใช้แทน
  </div>
  <br/>

  <div class="content">
    <label><b>สิทธิขอให้แก้ไขข้อมูล (right to rectification) </b></label>
    คุณมีสิทธิขอแก้ไขข้อมูลส่วนบุคคลของคุณให้ถูกต้อง เป็นปัจจุบัน สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด
  </div>
  <br/>

  <div class="content">
    <label><b>สิทธิร้องเรียน (right to lodge a complaint) </b></label>
    คุณมีสิทธิร้องเรียนต่อผู้มีอำนาจตามกฎหมายที่เกี่ยวข้อง หากคุณเชื่อว่าการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ เป็นการกระทำในลักษณะที่ฝ่าฝืนหรือไม่ปฏิบัติตามกฎหมายที่เกี่ยวข้อง
    <p>หากคุณอยู่ในเขตเศรษฐกิจยุโรป (EEA) นอกจากสิทธิของเจ้าของข้อมูลส่วนบุคคลดังกล่าวข้างต้นแล้ว คุณมีสิทธิที่จะยื่นเรื่องร้องเรียนกับสำนักงานคุ้มครองข้อมูลส่วนบุคคลในประเทศของคุณ หากคุณเชื่อว่าเราไม่ได้ปฏิบัติตามกฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล</p>
    <p>คุณสามารถใช้สิทธิของคุณในฐานะเจ้าของข้อมูลส่วนบุคคลข้างต้นได้ โดยติดต่อมาที่เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของเราตามรายละเอียดท้ายนโยบายนี้ เราจะแจ้งผลการดำเนินการภายในระยะเวลา 30 วัน นับแต่วันที่เราได้รับคำขอใช้สิทธิจากคุณ ตามแบบฟอร์มหรือวิธีการที่เรากำหนด ทั้งนี้ หากเราปฏิเสธคำขอเราจะแจ้งเหตุผลของการปฏิเสธให้คุณทราบผ่านช่องทางต่าง ๆ เช่น ข้อความ (SMS) อีเมล โทรศัพท์ จดหมาย เป็นต้น</p>
  </div>
  <br/>

  <div class="content">
    <label><b>กฎหมายความเป็นส่วนตัวของผู้บริโภคแห่งรัฐแคลิฟอร์เนีย สหรัฐอเมริกา (CCPA)</b></label>
    <p>แนวปฏิบัติเกี่ยวกับการประมวลผลข้อมูลส่วนบุคคลตามกฎหมายความเป็นส่วนตัวของผู้บริโภคแห่งรัฐแคลิฟอร์เนีย สหรัฐอเมริกา 2018 (CCPA) โปรดดูนโนบายความเป็นส่วนตัวของผู้บริโภคแห่งรัฐแคลิฟอร์เนีย (CCPA Privacy Policy)</p>
  </div>
  <br/>

  <div class="content">
    <label><b>การโฆษณาและการตลาด</b></label>
    <p>เพื่อประโยชน์ในการได้รับสินค้าหรือบริการของเรา เราใช้ข้อมูลของคุณเพื่อวิเคราะห์และปรับปรุงสินค้าหรือบริการ และทำการตลาดผ่าน Google, Facebook, pixel tracking code และอื่น ๆ เราใช้ข้อมูลดังกล่าวเพื่อให้สินค้าหรือบริการเหมาะสมกับคุณ</p>
    <p>เว็บไซต์ของเราอาจแสดงโฆษณาจากบุคคลที่สามเพื่ออำนวยความสะดวกในการบริการของเรา เช่น Google AdSense, BuySellAds บุคคลที่สามเหล่านี้อาจเข้าถึงข้อมูลส่วนบุคคลของคุณเพื่อดำเนินการเหล่านี้เท่านั้น และมีหน้าที่ไม่เปิดเผยหรือใช้เพื่อวัตถุประสงค์อย่างอื่น</p>
    <p>เราจะไม่ใช้ระบบการตัดสินใจอัตโนมัติ (automated decision-making)โดยปราศจากการบุคคลกำกับดูแล รวมถึงการทำโปรไฟล์ลิ่ง (profiling) ในลักษณะที่ก่อให้เกิดผลกระทบกับคุณอย่างมีนัยสำคัญ</p>
    <p>เราอาจส่งข้อมูลหรือจดหมายข่าวไปยังอีเมลของคุณ โดยมีวัตถุประสงค์เพื่อเสนอสิ่งที่น่าสนกับคุณ หากคุณไม่ต้องการรับการติดต่อสื่อสารจากเราผ่านทางอีเมลอีกต่อไป คุณสามารถกด "ยกเลิกการติดต่อ" ในลิงก์อีเมลหรือติดต่อมายังอีเมลของเราได้</p>
  </div>
  <br/>

  <div class="content">
    <label><b>เทคโนโลยีติดตามตัวบุคคล (Cookies)</b></label>
    <p>เพื่อเพิ่มประสบการณ์การใช้งานของคุณให้สมบูรณ์และมีประสิทธิภาพมากขึ้น เราใช้คุกกี้ (Cookies)หรือเทคโนโลยีที่คล้ายคลึงกัน เพื่อพัฒนาการเข้าถึงสินค้าหรือบริการ โฆษณาที่เหมาะสม และติดตามการใช้งานของคุณ เราใช้คุกกี้เพื่อระบุและติดตามผู้ใช้งานเว็บไซต์และการเข้าถึงเว็บไซต์ของเรา หากคุณไม่ต้องการให้มีคุกกี้ไว้ในคอมพิวเตอร์ของคุณ คุณสามารถตั้งค่าบราวเซอร์เพื่อปฏิเสธคุกกี้ก่อนที่จะใช้เว็บไซต์ของเราได้</p>
  </div>
  <br/>

  <div class="content">
    <label><b>การรักษาความมั่งคงปลอดภัยของข้อมูลส่วนบุคคล</b></label>
    <p>เราจะรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลของคุณไว้ตามหลักการ การรักษาความลับ (confidentiality) ความถูกต้องครบถ้วน (integrity) และสภาพพร้อมใช้งาน (availability) ทั้งนี้ เพื่อป้องกันการสูญหาย เข้าถึง ใช้ เปลี่ยนแปลง แก้ไข หรือเปิดเผย นอกจากนี้เราจะจัดให้มีมาตรการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล ซึ่งครอบคลุมถึงมาตรการป้องกันด้านการบริหารจัดการ (administrative safeguard) มาตรการป้องกันด้านเทคนิค (technical safeguard) และมาตรการป้องกันทางกายภาพ (physical safeguard) ในเรื่องการเข้าถึงหรือควบคุมการใช้งานข้อมูลส่วนบุคคล (access control)</p>
  </div>
  <br/>

  <div class="content">
    <label><b>การแจ้งเหตุละเมิดข้อมูลส่วนบุคคล</b></label>
    <p>ในกรณีที่มีเหตุละเมิดข้อมูลส่วนบุคคลของคุณเกิดขึ้น เราจะแจ้งให้สำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลทราบโดยไม่ชักช้าภายใน 72 ชั่วโมง นับแต่ทราบเหตุเท่าที่สามารถกระทำได้ ในกรณีที่การละเมิดมีความเสี่ยงสูงที่จะมีผลกระทบต่อสิทธิและเสรีภาพของคุณ เราจะแจ้งการละเมิดให้คุณทราบพร้อมกับแนวทางการเยียวยาโดยไม่ชักช้าผ่านช่องทางต่าง ๆ เช่น  เว็บไซต์ ข้อความ (SMS) อีเมล โทรศัพท์ จดหมาย เป็นต้น</p>
  </div>
  <br/>

  <div class="content">
    <label><b>การแก้ไขเปลี่ยนแปลงนโยบายความเป็นส่วนตัว</b></label>
    <p>เราอาจแก้ไขเปลี่ยนแปลงนโยบายนี้เป็นครั้งคราว โดยคุณสามารถทราบข้อกำหนดและเงื่อนไขนโยบายที่มีการแก้ไขเปลี่ยนแปลงนี้ได้ผ่านทางเว็บไซต์ของเรา</p>
    <p>นโยบายนี้แก้ไขล่าสุดและมีผลใช้บังคับตั้งแต่วันที่ 24 สิงหาคม 2566</p>
  </div>
  <br/>

  <div class="content">
    <label><b>นโยบายความเป็นส่วนตัวของเว็บไซต์อื่น</b></label>
    <p>นโยบายความเป็นส่วนตัวฉบับนี้ใช้สำหรับการเสนอสินค้า บริการ และการใช้งานบนเว็บไซต์สำหรับลูกค้าของเราเท่านั้น หากคุณเข้าชมเว็บไซต์อื่นแม้จะผ่านช่องทางเว็บไซต์ของเรา การคุ้มครองข้อมูลส่วนบุคคลต่าง ๆ จะเป็นไปตามนโยบายความเป็นส่วนตัวของเว็บไซต์นั้น ซึ่งเราไม่มีส่วนเกี่ยวข้องด้วย</p>
  </div>
  <br/>

  <div class="content">
    <label><b>รายละเอียดการติดต่อ</b></label>
    <p>หากคุณต้องการสอบถามข้อมูลเกี่ยวกับนโยบายความเป็นส่วนตัวฉบับนี้ รวมถึงการขอใช้สิทธิต่าง ๆ คุณสามารถติดต่อเราหรือเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของเราได้ ดังนี้</p>
  </div>
  <br/>

  <div class="content">
    <label><b>ผู้ควบคุมข้อมูลส่วนบุคคล</b></label>
    <p>ทัวร์ริ่ง เซนเตอร์ (บริษัท เดย์ทริป จำกัด)</p>
    <p>31/1 ถนนมูลเมือง ซอย 5  ศรีภูมิ  เมืองเชียงใหม่  เชียงใหม่  50200</p>
    <p>อีเมล reservations@touringcnx.com</p>
    <p>เว็บไซต์ www.touringcenter.com</p>
    <p>หมายเลขโทรศัพท์ 0882585817</p>
  </div>
  <br/>

  <div class="content">
    <label><b>เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล</b></label>
    <p>ทีม Touring Center</p>
    <p>31/1 ถนนมูลเมือง ซอย 5  ศรีภูมิ  เมืองเชียงใหม่  เชียงใหม่  50200</p>
    <p>อีเมล tour@touringcnx.com</p>
    <p>หมายเลขโทรศัพท์ 0882585817</p>
  </div>
</div>
<hr>

<div class="container">
  <h2>นโยบายความเป็นส่วนตัวของผู้บริโภครัฐแคลิฟอร์เนีย (CCPA Policy)</h2>
  <p>ทัวร์ริ่ง เซนเตอร์ (บริษัท เดย์ทริป จำกัด) ให้ความสำคัญกับความเป็นส่วนตัว และการรักษาความปลอดภัยข้อมูลส่วนบุคคลของคุณ  นโยบายความเป็นส่วนตัวฉบับนี้อธิบายแนวปฏิบัติการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลที่เกี่ยวข้องกับผู้พักอาศัยในรัฐแคลิฟอร์เนียตามกฎหมายความเป็นส่วนตัวของผู้บริโภคแห่งรัฐแคลิฟอร์เนีย (CCPA) ปี 2018</p>

  <div class="content">
    <label><b>สิทธิของคุณตาม CCPA</b></label>
    <p>นอกจากสิทธิตามที่กำหนดไว้ในนโยบายฉบับนี้ ผู้อยู่อาศัยในแคลิฟอร์เนียมีสิทธิต่าง ๆ ดังต่อไปนี้</p>
  </div>
  <br/>

  <div class="content">
    <label><b>สิทธิในการได้รับการแจ้ง</b></label>
    <p>เราเก็บรวบรวมข้อมูลส่วนบุคคลของผู้พักอาศัยในรัฐแคลิฟอร์เนียประเภทต่าง ๆ และเปิดเผยแก่บุคคลภายนอกหรือผู้ให้บริการเพื่อจุดประสงค์ทางธุรกิจตามที่ระบุไว้ในนโยบายฉบับนี้ ทั้งนี้ ในช่วง 12 เดือนที่ผ่านมา เราอาจได้เก็บรวบรวมข้อมูลส่วนบุคคลประเภทต่าง ๆ ต่อไปนี้ โดยขึ้นอยู่กับการปฏิสัมพันธ์ของคุณกับเรา</p>
  </div>
  <br/>

  <div class="content">
    <label><b>ประเภทข้อมูลส่วนบุคคลตาม CCPA</b></label>
    <p>ข้อมูลที่ระบุตัวตน เช่น ชื่อ นามสกุล เพศ สัญชาติ บัตรประจำตัวประชาชน หนังสือเดินทาง ข้อมูลการติดต่อ เป็นต้น</p>
    <p>ข้อมูลส่วนบุคคลตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลของรัฐแคลิฟอร์เนีย เช่น ข้อมูลทางการเงิน รายละเอียดบัตรเครดิต บัญชีธนาคาร เป็นต้น</p>
    <p>ประเภทข้อมูลที่ได้รับการคุ้มครองภายใต้กฎหมายของรัฐแคลิฟอร์เนียหรือกฎหมายของรัฐบาลกลาง เช่น ความทุพพลภาพ หรือลักษณะทางกายภาพอื่น ๆ เป็นต้น</p>
    <p>ข้อมูลทางการค้า เช่น ข้อมูลการซื้อสินค้าหรือบริการ เป็นต้น</p>
    <p>กิจกรรมทางอินเทอร์เน็ตหรือบนเครือข่ายอิเล็กทรอนิกส์ เช่น ประวัติการเข้าชม ประวัติการค้นหา เป็นต้น</p>
    <p>ข้อมูลตำแหน่งที่ตั้งตามพิกัดทางภูมิศาสตร์ เช่น ตำแหน่งที่ตั้ง เป็นต้น</p>
    <p>ข้อมูลเสียง สื่ออิเล็กทรอนิกส์ ภาพ หรือข้อมูลที่คล้ายคลึงกัน เช่น บันทึกเสียงและวิดีโอ เป็นต้น</p>
    <p>ข้อมูลวิชาชีพหรือเกี่ยวกับการจ้างงาน เช่น ประวัติการทำงาน เป็นต้น</p>
    <p>ข้อมูลทางการศึกษา เช่น ระดับการศึกษา ประกาศนียบัตร เป็นต้น</p>
    <p>ข้อมูลอื่น ๆ เช่น โปรไฟล์ ความชื่นชอบ หรือคุณลักษณะของคุณ เป็นต้น</p>
    <br/>
    <p>แหล่งที่มาของข้อมูล ได้แก่ ข้อมูลที่คุณให้เราโดยตรง การมีปฏิสัมพันธ์ระหว่างคุณกับเรา ข้อมูลจากพันธมิตรทางธุรกิจหรือผู้ให้บริการ เป็นต้น</p>
    <br/>
    <p>เราใช้ข้อมูลส่วนบุคคลเพื่อวัตถุประสงค์ทางธุรกิจดังต่อไปนี้</p>
    <ul>
      <li>เพื่อสร้างและจัดการบัญชีผู้ใช้งาน</li>
      <li>เพื่อจัดส่งสินค้าหรือบริการ</li>
      <li>เพื่อปรับปรุงสินค้า บริการ หรือประสบการณ์การใช้งาน</li>
      <li>เพื่อการบริหารจัดการภายในบริษัท</li>
      <li>เพื่อการตลาดและการส่งเสริมการขาย</li>
      <li>เพื่อการบริการหลังการขาย</li>
      <li>เพื่อรวบรวมข้อเสนอแนะ</li>
      <li>เพื่อชำระค่าสินค้าหรือบริการ</li>
      <li>เพื่อปฏิบัติตามข้อตกลงและเงื่อนไข (Terms and Conditions)</li>
      <li>เพื่อปฏิบัติตามกฎหมายและกฎระเบียบของหน่วยงานราชการ</li>
    </ul>
  </div>
  <br/>

  <div class="content">
    <label><b>สิทธิขอข้อมูลส่วนบุคคล</b></label>
    <p>คุณมีสิทธิขอให้เราเปิดเผยการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณในช่วง 12 เดือนที่ผ่านมา รวมถึงประเภทของข้อมูลส่วนบุคคล วัตถุประสงค์ในการประมวลผลข้อมูลส่วนบุคคล ประเภทแหล่งที่มาของข้อมูล ประเภทบุคคลที่สามที่เราเปิดเผยข้อมูลให้ในวัตถุประสงค์ทางธุรกิจ และวัตถุประสงค์ของเราในการดำเนินการดังกล่าว</p>
  </div>
  <br/>

  <div class="content">
    <label><b>สิทธิขอให้ลบข้อมูลส่วนบุคคล</b></label>
    <p>คุณมีสิทธิขอให้เราลบข้อมูลส่วนบุคคลของคุณที่เราได้เก็บหรือรักษาไว้ ในการขอใช้สิทธิลบข้อมูลส่วนบุคคล คุณสามารถส่งคำขอมายังอีเมลของเราได้ ทั้งนี้ โปรดระบุข้อมูลส่วนบุคคลที่คุณที่ต้องการลบหรือจะขอให้เราลบข้อมูลส่วนบุคคลของคุณทั้งหมดตามที่ CCPA กำหนดก็ได้</p>
  </div>
  <br/>

  <div class="content">
    <label><b>สิทธิในการไม่เลือกปฏิบัติ</b></label>
    <p>ตามกฎหมาย CCPA ห้ามไม่ให้เลือกปฏิบัติต่อผู้พักอาศัยแคลิฟอร์เนียในการใช้สิทธิตามกฎหมาย CCPA ดังนี้ คุณจะไม่ได้รับการถูกเลือกปฏิบัติในการขอใช้สิทธิของคุณตามกฎหมาย CCPA</p>
  </div>
  <br/>

  <div class="content">
    <label><b>สิทธิเลือกความยินยอมในการไม่จำหน่ายข้อมูลส่วนบุคคล</b></label>
    <p>คุณมีสิทธิเลือกให้ความยินยอมหรือไม่ให้ความยินยอมในการจำหน่ายข้อมูลส่วนบุคคล ทั้งนี้ เราไม่ได้จำหน่ายข้อมูลส่วนบุคคลของคุณ รวมถึงข้อมูลส่วนบุคคลของผู้พักอาศัยแคลิฟอร์เนีย</p>
  </div>
  <br/>

  <div class="content">
    <label><b>รายละเอียดการติดต่อ</b></label>
    <p>หากคุณต้องการสอบถามข้อมูลเกี่ยวกับนโยบายความเป็นส่วนตัวฉบับนี้ รวมถึงการขอใช้สิทธิต่าง ๆ คุณสามารถติดต่อเราได้ ดังนี้</p>
    <p>ทัวร์ริ่ง เซนเตอร์ (บริษัท เดย์ทริป จำกัด)</p>
    <p>31/1 ถนนมูลเมือง ซอย 5  ศรีภูมิ  เมืองเชียงใหม่  เชียงใหม่  50200</p>
    <p>อีเมล reservations@touringcnx.com</p>
    <p>เว็บไซต์ www.touringcenter.com</p>
    <p>หมายเลขโทรศัพท์ 0882585817</p>
  </div>
  <br/>
</div>
<hr>